import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { MdArrowRight } from 'react-icons/md'
import http from '../../services/http'
import NLLoader from '../common/NLLoader'
import ChangePassword from './Settings/ChangePassword'
import ChatbotSettings from './Settings/ChatbotSettings'
import Profile from './Settings/Profile'
import SettingsLeft from './Settings/SettingsLeft'

function Settings() {
  const [profile, setProfile] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [activeComponent, setActiveComponent] = useState(0)

  useEffect(() => {
    document.title = 'Settings | HeyBrain'
    loadProfile()
  }, [])

  async function loadProfile() {
    setIsLoading(true)

    const result = await http.get('/user/profile')
    if (result) {
      setProfile(result.data.response)
    }
    setIsLoading(false)
  }

  if (isLoading) {
    return <NLLoader size="3xl" elClassName="mx-auto" />
  }

  return (
    <>
      <div className="flex gap-1 text-xs items-center text-[#718096] ">
        <a className="cursor-pointer" href="https://heybrain.ai/">
          Home
        </a>
        <MdArrowRight />
        <p className="text-[#2B007B]"> Settings </p>
      </div>

      <div className="flex gap-6 md:flex-row sm:flex-col my-4 w-full ">
        <SettingsLeft
          className="justify-self-start"
          profile={profile}
          setActiveComponent={setActiveComponent}
        />

        <div
          className={clsx('md:w-7/12 sm:w-full', {
            'md:w-9/12': activeComponent === 3
          })}>
          {activeComponent === 0 && <Profile profile={profile} />}
          {activeComponent === 1 && <ChangePassword profile={profile} />}
          {activeComponent === 2 && <p> currently unavailable 🐌 </p>}
          {activeComponent === 3 && <ChatbotSettings profile={profile} />}
        </div>
      </div>
    </>
  )
}

export default Settings
