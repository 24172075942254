import React, { useEffect, useState, useContext } from 'react'
import http from '../../services/http'
import NLLoader from '../common/NLLoader'
import { groupBy, repoType } from '../../services/helpers'
import { AuthContext } from '../../context/AuthContext'
import RepositoryGroup from './Repositories/RepositoryGroup'
import RepositoryImport from './Repositories/RepositoryImport'
import { useLocation } from 'react-router-dom'

function Repositories() {
  const [repos, setRepos] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isOpened, setIsOpened] = useState(false)

  const auth = useContext(AuthContext)
  const location = useLocation()

  useEffect(() => {
    document.title = 'Repositories | HeyBrain'
    if (location.state !== null) {
      setIsOpened(true)
    }

    async function loadRepos() {
      setIsLoading(true)
      const result = await http.get('/repositories/list')
      if (result.data.response) {
        const r = groupBy(result.data.response, 'repo_type')
        setRepos(r)
        localStorage.setItem('repos', JSON.stringify(r))
      }
      setIsLoading(false)
    }
    loadRepos()
  }, [])

  if (isLoading) {
    return <NLLoader size="3xl" elClassName="mx-auto" />
  }

  return (
    <div className="flex flex-col space-y-2">
      <RepositoryImport isOpened={isOpened} />
      {Object.keys(repos).map((key, i) => {
        const repo = repoType(key)
        return (
          <RepositoryGroup
            key={'repo-group-' + key}
            auth={auth}
            repo={repo}
            items={repos[key]}
          />
        )
      })}
    </div>
  )
}

export default Repositories
