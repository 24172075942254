import React, { useEffect } from 'react'
import classNames from 'classnames'
import { RiLoader4Fill } from 'react-icons/ri'
import animationBRAIN from './animationBRAIN.json'
import lottie from 'lottie-web'

// Size value is tailwind sizing extensions: xs, sm, md, lg, xl, vs..
// Type is default or brain.
function NLLoader({
  type: loaderType = 'default',
  brainSize = '120px',
  size: spinnerSize = 'md',
  className: customClasses = '',
  elClassName: customElClasses = '',
  color: customElColor = 'purple'
}) {
  useEffect(() => {
    let lottieInstance
    if (loaderType === 'brain') {
      lottieInstance = lottie.loadAnimation({
        container: document.querySelector('#l-loading-wrapper'),
        animationData: animationBRAIN
      })
      lottieInstance.play()
    }
    return () => {
      if (lottieInstance) {
        lottieInstance.destroy()
      }
    }
  }, [loaderType])

  if (loaderType === 'brain') {
    return (
      <div
        className={classNames(
          'flex flex-col justify-center items-center',
          customClasses
        )}>
        <div
          id="l-loading-wrapper"
          className={classNames(
            'flex flex-col items-center justify-center mx-auto',
            customElClasses
          )}
          style={{
            width: brainSize
          }}></div>
      </div>
    )
  }

  const className = classNames(customClasses)
  let c = 'animate-spin'
  c += ' text-' + spinnerSize
  c += ' text-' + customElColor
  const el = classNames(c, customElClasses)
  return (
    <div className={className}>
      <RiLoader4Fill className={el} />
    </div>
  )
}

export default NLLoader
