import React, { createContext, useState } from 'react'
import http from '../services/http'

export const SpaceContext = createContext({
  presented: null
})

export function SpaceProvider({ children }) {
  const [presented, setPresented] = useState(null)
  const [access_type, setAccessType] = useState(null)
  const [sharedUsers, setSharedUsers] = useState(null)
  const [space, setSpace] = useState(null)
  const [items, setItems] = useState(null)

  const [spaceItemList, setSpaceItemList] = useState([])

  const onCheckSpace = space => {
    setSpace(space)
  }

  const onPresented = id => {
    setPresented(id)
  }

  const clearPresented = () => {
    setPresented(null)
  }

  const checkAccessType = (type, users) => {
    setAccessType(type)
    setSharedUsers(users)
  }

  const clearAccessType = () => {
    setAccessType(null)
    setSharedUsers(null)
  }

  const clearSpace = () => {
    setSpace(null)
  }

  const addList = item => {
    setSpaceItemList([...spaceItemList, item])
  }

  const clearList = () => {
    setSpaceItemList([])
  }

  const onCheckItems = items => {
    setItems(items)

  }

  const onClearItems = () => {
    setItems(null)
  }

  async function getList() {
    const res = await http.get('/spaces/list')
    if (res) {
      const spaceArr = res.data.response
      const options = spaceArr.map(item => ({
        value: item.slug,
        label: item.name
      }))
      options.unshift({
        value: 'add new',
        label: '+ create new space',
        color: 'green'
      })

      return options
    }
  }

  function hasOwnerAccess(username, arr) {
    for (const obj of arr) {
      if (obj.username === username) {
        return obj.role === 'owner_access'
      }
    }
    return false
  }

  const value = {
    onPresented,
    clearPresented,
    checkAccessType,
    clearAccessType,
    onCheckSpace,
    clearSpace,
    addList,
    clearList,
    getList,
    hasOwnerAccess,
    onCheckItems,
    onClearItems,
    presented,
    access_type,
    sharedUsers,
    space,
    spaceItemList,
    items
  }

  return <SpaceContext.Provider value={value}>{children}</SpaceContext.Provider>
}
