import React, { useState, useEffect } from 'react'
import './repositorySelectorContent.css'

import {
  groupBy,
  repoType,
  repos as allRepos
} from '../../../../services/helpers'
import NLLoader from '../../../common/NLLoader'
import http from '../../../../services/http'
import { MdAddCircle } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import RepositorySelectorGroup from './RepositorySelectorGroup'

function RepositorySelectorContent(props) {
  const [repos, setRepos] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const { repoNeedsRefresh, onRefreshDone } = props

  useEffect(() => {
    async function loadRepos() {
      setIsLoading(true)
      const result = await http.get('/repositories/list')
      if (result.data.response) {
        const r = groupBy(result.data.response, 'repo_type')
        setRepos(r)
        localStorage.setItem('repos', JSON.stringify(r))
        onRefreshDone()
      }
      setIsLoading(false)
    }
    const l = allRepos()
    if (l && !repoNeedsRefresh) {
      setRepos(l)
    } else {
      loadRepos()
    }
  }, [repoNeedsRefresh, onRefreshDone])

  if (isLoading) {
    return (
      <NLLoader
        size="3xl"
        color="white"
        className="flex items-center justify-center h-full"
      />
    )
  }

  return (
    <div
      id="menu"
      style={{
        overflow: 'auto',
        height: '448px',
        marginTop: '32px',
        borderBottom: '15px solid #6416F3',
        borderRadius: '10px'
      }}>
      <div className="flex flex-col grow space-y-2 p-4">
        {Object.keys(repos).map((key, i) => {
          const repo = repoType(key)
          return (
            <RepositorySelectorGroup
              key={'repo-sel-item-' + i}
              auth={props.auth}
              repo={repo}
              items={repos[key]}
            />
          )
        })}
        <div
          className="flex flex-row items-center rounded bg-white text-gray-900 px-4 py-4 cursor-pointer hover:bg-gray-50"
          onClick={() => {
            props.onClose()
            navigate('/repositories?add=1', {
              state: { import: true },
              replace: true
            })
          }}>
          <MdAddCircle className="text-purple-lighter text-2xl" />
          <div className="grow px-4">Add</div>
        </div>
      </div>
    </div>
  )
}

export default RepositorySelectorContent
