import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import http from '../../services/http'
import NLAlert from '../common/NLAlert'
import NLButton from '../common/NLButton'
import NLInput from '../common/NLInput'
import NLLoader from '../common/NLLoader'
import OnBoardBody from './OnBoardBody'

function ForgotPassword() {
  const [email, setEmail] = useState('')
  const [showSecondStep, setShowSecondStep] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [code, setCode] = useState('')
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [showVerifyMessage, setShowVerifyMessage] = useState(true)

  const navigate = useNavigate()
  const handleForgotPassword = async () => {
    if (!email) {
      setError('Please enter a valid username/email')
      return
    }
    setIsLoading(true)

    const data = { username: email }
    http
      .post('/auth/forgot', data)
      .then(response => {
        setIsLoading(false)
        if (response.data.response) {
          return setShowSecondStep(true)
        }

        return setError('Something went wrong, please try again later.')
      })
      .catch(() => {
        setIsLoading(false)
      })
  }

  const handleConfirmForgotPassword = () => {
    if (!email) {
      setError('Please enter a valid username/email')
      return
    }
    setShowVerifyMessage(false)
    setIsLoading(true)

    const data = { token: code, new_password: password }
    http
      .post('/auth/forgot/confirm', data)
      .then(response => {
        setIsLoading(false)
        if (response.data.response) {
          setSuccess(
            'Your password has been changed successfully.\nRedirecting to Login in 3 seconds'
          )
          setTimeout(() => {
            navigate('/login', { replace: true })
          }, 3e3)
          return
        }
        setSuccess(false)
        setError('Something went wrong, please try again later.')
      })
      .catch(() => {
        setIsLoading(false)
        setError('Something went wrong.')
        setSuccess(false)
      })
  }

  const FirstStep = () => (
    <>
      <div className="flex flex-col w-2/3 my-8 space-y-4">
        <NLInput
          key="usernameInputKey"
          assistiveText="Username or e-mail"
          type="text"
          className="w-full"
          onChange={e => {
            setEmail(e.target.value)
          }}
        />
        {error && (
          <NLAlert type="error" className="text-xs">
            {error}
          </NLAlert>
        )}

        {success && (
          <NLAlert type="success" className="text-xs">
            {success}
          </NLAlert>
        )}
        <NLButton onClick={handleForgotPassword} isDisabled={isLoading}>
          {isLoading ? (
            <div className="flex flex-row justify-center items-center">
              <NLLoader color="purple" />
            </div>
          ) : (
            'SEND'
          )}
        </NLButton>
      </div>
      <div className="text-sm">
        Remember password?{' '}
        <Link to={'/login'} className="text-purple-lighter">
          Sign In
        </Link>
      </div>
    </>
  )

  const SecondStep = () => (
    <>
      <div className="flex flex-col w-2/3 my-8 space-y-4">
        {showVerifyMessage && (
          <NLAlert type="success" className="text-xs">
            Please check your email and enter the verification code below
          </NLAlert>
        )}
        <NLInput
          key="codeInputKey"
          assistiveText="Verification Code"
          type="text"
          className="w-full"
          onChange={e => {
            setCode(e.target.value)
          }}
        />
        <NLInput
          key="passwordInputKey"
          assistiveText="New Password"
          type="password"
          className="w-full"
          onChange={e => {
            setPassword(e.target.value)
          }}
        />
        {error && (
          <NLAlert type="error" className="text-xs">
            {error}
          </NLAlert>
        )}

        {success && (
          <NLAlert type="success" className="text-xs">
            {success}
          </NLAlert>
        )}
        <NLButton onClick={handleConfirmForgotPassword} isDisabled={isLoading}>
          {isLoading ? (
            <div className="flex flex-row justify-center items-center">
              <NLLoader color="purple" />
            </div>
          ) : (
            'Reset'
          )}
        </NLButton>
      </div>
      <div className="text-sm">
        <span
          className="text-purple-lighter cursor-pointer"
          onClick={() => {
            setShowSecondStep(false)
          }}>
          Go Back
        </span>
      </div>
    </>
  )

  return (
    <OnBoardBody title="Forgot your password?">
      {showSecondStep ? SecondStep() : FirstStep()}
    </OnBoardBody>
  )
}

export default ForgotPassword
