import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { BsFillCloudPlusFill } from 'react-icons/bs'
import { FaTwitter, FaWhatsapp } from 'react-icons/fa'
import { showUserNotification } from '../../../services/helpers'
import http from '../../../services/http'
import NLButton from '../../common/NLButton'
import NLInput from '../../common/NLInput'
import NLLoader from '../../common/NLLoader'

const uploadSchemes = [
  {
    name: 'social-media',
    schemes: [
      {
        id: 'twitter',
        title: 'Twitter',
        icon: <FaTwitter />,
        textClass: 'text-sky-500',
        needsUsername: true
      }
    ]
  },
  {
    name: 'messengers',
    schemes: [
      {
        id: 'whatsapp',
        title: 'WhatsApp',
        icon: <FaWhatsapp />,
        textClass: 'text-green-500'
      }
    ]
  },
  {
    name: 'communication-apps',
    schemes: [
      {
        id: 'slack',
        title: 'Slack',
        icon: <img src="/assets/slack.svg" alt="" width="28px" height="28px" />,
        textClass: ''
      },
      {
        id: 'zoom',
        title: 'Zoom',
        icon: (
          <img
            src="/assets/repo-icon-zoom.svg"
            alt=""
            width="28px"
            height="28px"
          />
        ),
        textClass: ''
      }
    ]
  },
  {
    name: 'storage-services',
    schemes: [
      {
        id: 'gdrive',
        title: 'Google Drive',
        icon: (
          <img
            src="/assets/repo-icon-gdrive.svg"
            alt=""
            width="28px"
            height="28px"
          />
        ),
        textClass: '',
        externalLogin: true
      }
    ]
  },
  {
    name: 'other-sources',
    schemes: [
      {
        id: 'web',
        title: 'Web',
        icon: (
          <img
            src="/assets/repo-icon-web.svg"
            alt=""
            width="28px"
            height="28px"
          />
        ),
        textClass: ''
      },
      {
        id: 'notes',
        title: 'Notes',
        icon: (
          <img
            src="/assets/repo-icon-notes.svg"
            alt=""
            width="28px"
            height="28px"
          />
        ),
        textClass: ''
      },
      {
        id: 'other',
        title: 'Other',
        icon: (
          <img
            src="/assets/repo-icon-other.svg"
            alt=""
            width="28px"
            height="28px"
          />
        ),
        textClass: ''
      }
    ]
  }
]

function ImportBox(props) {
  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedUsername, setSelectedUsername] = useState(null)
  const [isUploading, setIsUploading] = useState(false)
  const [infoAWS, setInfoAWS] = useState(null)
  const [infoWP, setInfoWP] = useState(null)
  const scheme = props.scheme

  const resetFields = () => {
    setSelectedFile(null)
    setSelectedUsername(null)
  }

  useEffect(() => {
    function getUpload() {
      if (scheme.id === 'other' && infoAWS === null) {
        setIsUploading(true)
        http.get('/tools/upload').then(res => {
          if (res && res.data && res.data.response) {
            setInfoAWS(res.data.response)
            setIsUploading(false)
          }
        })
      } else if (scheme.id === 'whatsapp' && infoWP === null) {
        setIsUploading(true)
        http.get('/tools/whatsapp').then(res => {
          if (res && res.data && res.data.response) {
            setInfoWP(res.data.response)
            setIsUploading(false)
          }
        })
      }
    }
    getUpload()
    resetFields()
  }, [infoAWS, infoWP, scheme.id])

  const containerClass = classNames(
    'bg-white rounded shadow p-4 my-2 flex items-center justify-center flex-col space-y-4'
  )

  const upload = () => {
    setIsUploading(true)

    if (scheme.id === 'other') {
      const formData = new FormData()
      formData.append('key', infoAWS.fields.key)
      formData.append('acl', 'private')
      formData.append('success_action_redirect', '/repositories')
      formData.append('content-type', 'text/csv')
      formData.append('x-amz-meta-uuid', '14365123651274')
      formData.append('x-amz-server-side-encryption', 'AES256')
      formData.append('x-amz-credential', infoAWS.fields['x-amz-credential'])
      formData.append('x-amz-algorithm', infoAWS.fields['x-amz-algorithm'])
      formData.append('x-amz-date', infoAWS.fields['x-amz-date'])
      formData.append('x-amz-meta-tag', '')
      formData.append('policy', infoAWS.fields.policy)
      formData.append('x-amz-signature', infoAWS.fields['x-amz-signature'])
      const filename = selectedFile.name.replace(/[^a-zA-Z0-9.]/g, '_')
      formData.append('file', selectedFile, filename)
      http
        .post('https://agraba-uploads.s3.amazonaws.com/', formData, {
          headers: { 'content-type': 'multipart/form-data' }
        })
        .then(res => {
          setIsUploading(false)
          resetFields()
          showUserNotification({
            title: 'Import Done',
            message: 'Repository uploaded successfully.',
            type: 'success'
          })
        })
    } else if (scheme.id === 'twitter') {
      http
        .post('/tools/twitter', {
          username: selectedUsername
        })
        .then(res => {
          setIsUploading(false)
          resetFields()
          showUserNotification({
            title: 'Import Done',
            message: 'Repository uploaded successfully.',
            type: 'success'
          })
        })
        .catch(() => {
          setIsUploading(false)
          showUserNotification({
            title: 'Import Error',
            message: 'Problem occured while importing, please try again.',
            type: 'error'
          })
        })
    } else if (scheme.id === 'whatsapp') {
      const formData = new FormData()
      formData.append('key', infoWP.fields.key)
      formData.append('acl', 'private')
      formData.append('success_action_redirect', '/repositories')
      formData.append('content-type', 'text/csv')
      formData.append('x-amz-meta-uuid', '14365123651274')
      formData.append('x-amz-server-side-encryption', 'AES256')
      formData.append('x-amz-credential', infoWP.fields['x-amz-credential'])
      formData.append('x-amz-algorithm', infoWP.fields['x-amz-algorithm'])
      formData.append('x-amz-date', infoWP.fields['x-amz-date'])
      formData.append('x-amz-meta-tag', '')
      formData.append('policy', infoWP.fields.policy)
      formData.append('x-amz-signature', infoWP.fields['x-amz-signature'])
      const filename = selectedFile.name.replace(/[^a-zA-Z0-9.]/g, '_')
      formData.append('file', selectedFile, filename)
      http
        .post('https://agraba-uploads.s3.amazonaws.com/', formData, {
          headers: { 'content-type': 'multipart/form-data' }
        })
        .then(res => {
          setIsUploading(false)
          resetFields()
          showUserNotification({
            title: 'Import Done',
            message: 'Repository uploaded successfully.',
            type: 'success'
          })
        })
    } else if (scheme.id === 'gdrive') {
      http.get('/auth/google').then(res => {
        setIsUploading(false)
        if (res && res.data && res.data.authorization_url) {
          window.open(res.data.authorization_url)
        }
      })
    }
  }

  if (isUploading) {
    return (
      <div className={classNames(containerClass, props.containerClassNames)}>
        <NLLoader color="purple text-6xl" />
      </div>
    )
  }

  if (scheme.id === 'web') {
    return (
      <div
        className={classNames(
          containerClass,
          'font-semibold',
          props.containerClassNames
        )}>
        You can grab our extension to use with web repositories.
      </div>
    )
  }

  if (scheme.id === 'notes') {
    return (
      <div
        className={classNames(
          containerClass,
          'font-semibold',
          props.containerClassNames
        )}>
        You can use our notes service to create or arrange your notes.
      </div>
    )
  }

  return (
    <div className={classNames(containerClass, props.containerClassNames)}>
      <p className="font-semibold">Upload your files</p>
      <div className="bg-gray-100 rounded-md p-4 flex flex-col items-center justify-center w-5/6">
        <div className={classNames(scheme.textClass, 'h-10 text-2xl')}>
          {scheme.icon}
        </div>

        {scheme.needsUsername && !scheme.externalLogin && (
          <NLInput
            type="text"
            className="w-5/6"
            elClassName="px-2 py-1 text-sm"
            placeholder="Username"
            onChange={e => setSelectedUsername(e.target.value)}
          />
        )}

        {!scheme.needsUsername && !scheme.externalLogin && (
          <form>
            <label>
              <span>
                {selectedFile ? (
                  <p
                    style={{
                      overflowWrap: 'anywhere'
                    }}>
                    {selectedFile.name}
                  </p>
                ) : (
                  <span>
                    <p className="text-purple font-semibold">Select</p>{' '}
                    <p>your file</p>
                  </span>
                )}
              </span>
              <input
                type="file"
                className="hidden"
                onChange={e => setSelectedFile(e.target.files[0])}
              />
            </label>
          </form>
        )}

        {!scheme.needsUsername &&
          scheme.externalLogin &&
          'Login directly to start using this service'}
      </div>
      <NLButton
        isDisabled={
          !scheme.externalLogin &&
          !selectedFile &&
          (!selectedUsername || selectedUsername.length === 0)
        }
        className="w-5/6"
        onClick={upload}>
        {scheme.externalLogin ? 'LOGIN' : 'SUBMIT'}
      </NLButton>
    </div>
  )
}

function SchemeUpload(props) {
  const rowTitleClass = classNames(
    'bg-white rounded shadow font-semibold py-2 px-4 mr-4 w-72'
  )

  const rowButtonItemClass =
    'w-10 h-10 rounded-full bg-white flex items-center justify-center text-2xl hover:bg-gray-50 cursor-pointer'

  return (
    <div className="col-span-3 px-4 my-2 text-left flex flex-col space-y-4">
      {uploadSchemes.map((group, index) => {
        return (
          <div className="flex flex-row space-x-4" key={'sch-g-' + index}>
            <div className={rowTitleClass}>
              from {group.name.replace('-', ' ')}
            </div>
            {group.schemes.map((scheme, index) => {
              return (
                <div
                  key={'sch-s-' + index}
                  className={classNames(scheme.textClass, rowButtonItemClass)}
                  onClick={() => {
                    props.onSelectScheme(scheme)
                  }}>
                  {scheme.icon}
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

function RepositoryImport(props) {
  const [isImportBoxOpen, setIsImportBoxOpen] = useState(props.isOpened)
  const importBoxOpenStateHeight = 'h-80'

  const [selectedScheme, setSelectedScheme] = useState(
    uploadSchemes
      .find(c => c.name === 'other-sources')
      .schemes.find(i => i.id === 'other')
  )

  useEffect(() => {
    if (props.isOpened) openImportBox()
  }, [props.isOpened])

  const openImportBox = () => {
    setIsImportBoxOpen(true)
    document.getElementById('import-box-content').classList.remove('h-0')
    document
      .getElementById('import-box-content')
      .classList.add(importBoxOpenStateHeight)
  }

  const closeImportBox = () => {
    setIsImportBoxOpen(false)
    document
      .getElementById('import-box-content')
      .classList.remove(importBoxOpenStateHeight)
    document.getElementById('import-box-content').classList.add('h-0')
  }

  return (
    <div
      className={classNames(
        'p-4 flex flex-col mb-4 text-center items-center transition-all duration-300 rounded-md',
        {
          'bg-slate-100 shadow shadow-slate-200': isImportBoxOpen
        }
      )}
      id="import-box-container">
      <div
        className="bg-purple-lighter rounded-full p-2 text-white text-4xl hover:bg-purple cursor-pointer"
        onClick={() => {
          isImportBoxOpen ? closeImportBox() : openImportBox()
        }}>
        <BsFillCloudPlusFill />
      </div>
      <div
        className="grid grid-cols-4 gap-2 w-full transition-all duration-300 overflow-hidden h-0 py-0"
        id="import-box-content">
        <ImportBox scheme={selectedScheme} />
        <SchemeUpload
          onSelectScheme={scheme => {
            setSelectedScheme(scheme)
          }}
        />
      </div>
    </div>
  )
}

export default RepositoryImport
