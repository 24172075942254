import React from 'react'
import classNames from 'classnames'

function NLInput({
  assistiveText = '',
  elClassName: customElClasses = '',
  className: customClasses = '',
  ...props
}) {
  const elClassName = classNames(
    'px-4 py-2 rounded-md border border-gray-200 focus:border-gray-400 focus:outline-none text-base font-normal',
    customElClasses
  )
  const className = classNames('flex flex-col', customClasses)
  return (
    <div className={className}>
      {assistiveText.length > 0 ? (
        <p className="cursor-default text-left text-sm font-normal">
          {assistiveText}
        </p>
      ) : (
        ''
      )}
      <input {...props} className={elClassName} />
    </div>
  )
}

export default NLInput
