import clsx from 'clsx'
import { useState } from 'react'
import {
  MdContentCopy,
  MdOutlineCameraAlt,
  MdOutlineLock,
  MdOutlineSecurity,
  MdPerson
} from 'react-icons/md'
import { ReactSVG } from 'react-svg'

function SettingsLeft(props) {
  const { username, firstname } = props.profile
  const [activeTab, setActiveTab] = useState(0)

  const handleClick = e => {
    const selected = Number(e.target.id)
    setActiveTab(selected)
    props.setActiveComponent(selected)
  }

  const tabList = [
    {
      id: 0,
      name: 'Account Settings',
      icon: <MdPerson />
    },
    {
      id: 1,
      name: 'Sign In',
      icon: <MdOutlineLock />
    },
    {
      id: 2,
      name: 'Privacy Policy',
      icon: <MdOutlineSecurity />
    },
    {
      id: 3,
      name: 'Chatbot Settings',
      icon: (
        <ReactSVG
          src="/assets/brainbot.svg"
          alt="brain bot"
          className="self-center w-6 aspect-[1.16]"
        />
      )
    }
  ]

  return (
    <div className="max-h-fit">
      <div className="flex flex-col pt-10 bg-white gap-6">
        <div className="flex flex-col gap-6 justify-between items-center">
          <div
            className="relative w-32 h-32 border bg-cover bg-center bg-no-repeat border-gray-200 shadow-lg rounded-full"
            style={{
              backgroundImage: `url(https://robohash.org/${username})`
            }}>
            <div className="absolute bottom-0 right-0 bg-[#8962F4] p-4 rounded-full border-2 border-white">
              <MdOutlineCameraAlt className="absolute right-1 bottom-1 text-white text-2xl" />
            </div>
          </div>

          <h1 className="font-medium">{username}</h1>

          <div className="flex flex-col items-center text-sm">
            <p>{`Hey! I am ${firstname || username}'s`}</p>
            <p>digital brain</p>
          </div>

          <div
            className="w-80 text-base flex justify-between items-center px-3 py-3 rounded-full border-2 border-[#F1F1F1] mx-10"
            onClick={() => {
              const currentUrl = window.location.href
              navigator.clipboard.writeText(currentUrl)
            }}>
            <p className="text-[#9875F5] truncate">
              {`http://heybrain.ai/profile/${username}`}
            </p>
            <MdContentCopy className="cursor-pointer text-[#D9D9D9] text-2xl" />
          </div>
        </div>

        <div className="flex flex-col w-full font-semibold text-[#718096] cursor-pointer">
          {tabList.map((item, index) => {
            return (
              <div
                key={'tab-' + index}
                onClick={e => handleClick(e)}
                id={item.id}
                className={clsx(
                  'w-full flex gap-3  px-7 py-4 hover:bg-[#E7E8FC] hover:text-[#2B007B]',
                  {
                    'bg-[#E7E8FC] text-[#2B007B]': activeTab === item.id
                  }
                )}>
                <div className="text-2xl">{item.icon}</div>
                {item.name}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default SettingsLeft
