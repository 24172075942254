import { useRef, useState, useEffect } from 'react'
import { closeConversationOverlay } from '../../services/helpers'
import clsx from 'clsx'

const ConversationOverlay = props => {
  const {
    heybrain_user_id,
    heybrain_username,
    org_query,
    param_repo,
    param_search,
    param_time,
    raw_json_convo,
    summary
  } = props.conversation
  const [conversation, setConversation] = useState([])
  const nodeRef = useRef(null)

  useEffect(() => {
    setConversation(props.conversation)

    function handleOutsideClick(event) {
      if (!nodeRef.current.contains(event.target)) {
        closeConversationOverlay()
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [conversation])

  const formatText = text => {
    let lines = []

    if (!text) return <p>There is no summary available. </p>

    if (text && text.includes('\n')) {
      lines = text.split('\n')
    } else {
      lines.push(text)
    }

    return lines.map((line, index) => {
      return (
        <p className="text-sm  text-justify indent-8" key={index}>
          {line}
        </p>
      )
    })
  }

  const renderMessages = data => {
    if (data.length > 0) {
      return data.map((message, index) => {
        return (
          <div
            key={index}
            className={clsx('max-h-fit p-2 mb-2 rounded w-2/3  break-words', {
              'bg-[#E1E3E1]': message.role === 'system',
              'bg-[#F3E8FF]': message.role === 'assistant',
              'bg-[#CFFAFE]': message.role === 'user',
              'self-end':
                message.role === 'assistant' || message.role === 'system'
            })}>
            <div>
              <p className="indent-8">{message.role} :</p>
              <div>{formatText(message.content)}</div>
            </div>
          </div>
        )
      })
    } else {
      return (
        <div
          key={0}
          className={clsx('max-h-fit p-2 mb-2 rounded  break-words', {
            'bg-[#E1E3E1]': data.role === 'system',
            'bg-[#F3E8FF]': data.role === 'assistant',
            'bg-[#CFFAFE]': data.role === 'user'
          })}>
          <div>
            <p>{data.role} :</p>
            <p>{data.content}</p>
          </div>
        </div>
      )
    }
  }

  return (
    <>
      <div
        className="absolute flex p-3 justify-end items-cented bg-black/50 right-0 top-0 w-screen h-screen"
        style={{ zIndex: '10010' }}>
        <div
          ref={nodeRef}
          className="flex flex-col gap-2 bg-white p-2 m-auto rounded left-0 right-0 w-2/3 h-3/4 shadow-sm shadow-gray-200 overflow-hidden">
          <div
            className="cursor-pointer hover:text-gray-500 mt-1 text-xs self-end"
            onClick={() => {
              closeConversationOverlay()
            }}>
            {' '}
            close
          </div>
          <div className="flex flex-col p-3 gap-5 w-ful h-full px-5  ">
            <div className="flex gap-5 ">
              <div className="flex flex-col gap-5 w-1/3 text-sm justify-between">
                <div className="flex flex-col h-1/2  gap-1 p-3 rounded bg-white border shadow-lg shadow-gray-200">
                  <p>username: {heybrain_username}</p>
                  <p> user id: {heybrain_user_id}</p>
                  <p> param repo: {param_repo || 'unknown'}</p>
                </div>

                <div className="flex flex-col  h-1/2 gap-1 p-3 bg-white border shadow-lg shadow-gray-200 rounded">
                  <p> org_query: {org_query || 'unknown'}</p>
                  <p> param search: {param_search || 'unknown'}</p>
                  <p> param time: {param_time || 'unknown'}</p>
                </div>
              </div>

              <div className="w-2/3 bg-white border shadow-lg shadow-gray-200 p-3  rounded  ">
                <div className="flex flex-col gap-2 break-words text-justify">
                  <p className="font-semibold text-lg drop-shadow-lg">
                    Summary
                  </p>
                  {formatText(summary)}
                </div>
              </div>
            </div>

            <div className="flex pb-6 mb-3 flex-col text-sm overflow-y-scroll">
              {Object.entries(raw_json_convo).length !== 0
                ? renderMessages(raw_json_convo)
                : 'No information available'}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ConversationOverlay
