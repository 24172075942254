import { BsFillPeopleFill } from 'react-icons/bs'
import { GiEarthAmerica } from 'react-icons/gi'
import { MdLockOutline } from 'react-icons/md'

export const PublicSpace = props => {
  return (
    <div
      className="bg-violet-100 flex gap-1 font-semibold rounded-full p-2 shadow-md items-center justify-center cursor-pointer hover:bg-violet-200 relative select-none"
      onClick={props.onClick}>
      <GiEarthAmerica className="text-violet-500 h-full" />
      <p className="text-xs font-bold">Share</p>
    </div>
  )
}

export const PrivateSpace = props => {
  return (
    <div
      className="bg-violet-100 flex gap-1 font-semibold rounded-full p-2 shadow-md items-center justify-center cursor-pointer hover:bg-violet-200 relative select-none"
      onClick={props.onClick}>
      <MdLockOutline className="text-violet-500 h-full " />
      <p className="text-xs font-bold">Share</p>
    </div>
  )
}

export const PrivateSharedSpace = props => {
  return (
    <div
      className="bg-violet-100 flex gap-1 font-semibold rounded-full p-2 shadow-md items-center justify-center cursor-pointer hover:bg-violet-200 relative select-none"
      onClick={props.onClick}>
      <BsFillPeopleFill className="text-violet-500 h-full " />
      <p className="text-xs font-bold">Share</p>
    </div>
  )
}
