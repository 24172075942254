import React from 'react'
import { ReactSVG } from 'react-svg'
import './OnBoard.css'

function ComingSoon() {
  return (
    <div
      className="w-screen h-screen text-white relative"
      style={{
        background:
          'radial-gradient(50% 50% at 50% 50%, #A88AFF 0%, #8961FE 100%)'
      }}>
      <div
        className="absolute w-screen h-screen coming-soon-bg"
        style={{
          backgroundImage: 'url(assets/coming-soon-background.png)',
          opacity: 0.1
        }}></div>
      <div
        className="absolute meditate"
        style={{
          top: '50%',
          marginTop: '-120px',
          left: '50%',
          marginLeft: '-153px'
        }}>
        <ReactSVG
          src="./assets/brain-meditating.svg"
          beforeInjection={svg => {
            svg.setAttribute('style', 'width: 306px')
            svg.setAttribute('style', 'height: 240px')
          }}
        />
      </div>
      <div
        className="absolute text-center text-3xl font-bold text-white cursor-default text-center"
        style={{
          top: '50%',
          marginTop: '170px',
          left: '50%',
          marginLeft: '-150px',
          width: '300px'
        }}>
        You are about to get much smarter real soon!
      </div>
    </div>
  )
}

export default ComingSoon
