import { useState, useEffect, useRef } from 'react'
import './style/Animate.css'
import { CSSTransition } from 'react-transition-group'
import classNames from 'classnames'

function NLBoxFadeWithScale(props) {
  const [isVisible, setIsVisible] = useState(false)
  const nodeRef = useRef(null) // This helps silencing strictMode error. https://github.com/reactjs/react-transition-group/issues/668

  const classes = props.customCss ? props.customCss : ''
  const clickable = props.onClick ? 'cursor-pointer' : ''
  const css = classNames(classes, clickable, 'animFadeWithScale')

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true)
    }, props.delay)
  }, [props.delay])

  return (
    <CSSTransition
      in={isVisible}
      timeout={300}
      classNames={css}
      unmountOnExit
      nodeRef={nodeRef}
      onClick={() => {
        if (props.onClick) {
          props.onClick()
        }
      }}>
      <div ref={nodeRef}>{props.children}</div>
    </CSSTransition>
  )
}

export default NLBoxFadeWithScale
