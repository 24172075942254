import { useContext } from 'react'
import { GiHamburgerMenu } from 'react-icons/gi'
import { useLocation } from 'react-router-dom'
import { NavigationContext } from '../../context/NavigationContext'
import { SpaceContext } from '../../context/SpaceContext'
import { openSpaceOverlay } from '../../services/helpers'
import {
  PrivateSharedSpace,
  PrivateSpace,
  PublicSpace
} from '../svg/AccessType'
import RepositorySelector from './Header/RepositorySelector'
import UserNotification from './Header/UserNotification'

function Header() {
  const nav = useContext(NavigationContext)
  const sidebarOpened = nav.sidebarOpened
  const location = useLocation()
  const isSpaces = location.pathname.includes('/spaces/')
  const context = useContext(SpaceContext)
  const { access_type, sharedUsers } = context

  return (
    <div
      className="flex gap-3 flex-row h-16 shadow-sm w-full items-center px-6 relative bg-white shrink-0"
      id="app-header">
      <UserNotification />
      <div className="relative md:hidden cursor-pointer">
        <GiHamburgerMenu className="text-2xl "
          onClick={() => {nav.handleSideBar(sidebarOpened)}} 

         />
      </div>
      <div className="grow text-2xl ml-2">{nav.current.name}</div>
      {isSpaces &&
        (access_type === 'public' ? (
          <PublicSpace
            onClick={() => {
              openSpaceOverlay()
            }}
          />
        ) : access_type === 'private' && sharedUsers.length < 2 ? (
          <PrivateSpace
            onClick={() => {
              openSpaceOverlay()
            }}
          />
        ) : access_type === 'private' && sharedUsers.length >= 2 ? (
          <PrivateSharedSpace
            onClick={() => {
              openSpaceOverlay()
            }}
          />
        ) : (
          ''
        ))}
      <RepositorySelector />
    </div>
  )
}

export default Header
