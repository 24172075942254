import axios from 'axios'
import classNames from 'classnames'
import clsx from 'clsx'
import React, { useContext, useEffect, useState } from 'react'
import { BiLineChart } from 'react-icons/bi'
import { FaTimes } from 'react-icons/fa'
import { GiRingedPlanet } from 'react-icons/gi'
import { MdFilterAlt, MdSort } from 'react-icons/md'
import { useParams, useSearchParams } from 'react-router-dom'
import Select from 'react-select'
import { AuthContext } from '../../context/AuthContext'
import { SpaceContext } from '../../context/SpaceContext'
import {
  isLocalhost,
  isValidString,
  openDocumentOverlay,
  showUserNotification
} from '../../services/helpers'
import http from '../../services/http'
import NLChip from '../common/NLChip'
import NLDropDown from '../common/NLDropDown'
import NLInput from '../common/NLInput'
import NLItemDocument from '../common/NLItemDocument'
import NLLoader from '../common/NLLoader'
import NLSwitch from '../common/NLSwitch'

function SearchResults(props) {
  if (props.isSearching) {
    return <NLLoader type="brain" />
  }

  if (props.results === undefined || props.results.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center">
        <p className="text-3xl">🐌</p>
        <p className="text-md">No results, unfortunately.</p>
        <p className="text-xs">You can try widening your search criteria.</p>
      </div>
    )
  }

  // after tag's search
  return (
    <div className="flex flex-wrap">
      {props.results.map((doc, index) => {
        return (
          <NLItemDocument
            key={'nl-doc-result-' + index}
            addSpaceMode={props.addSpaceMode}
            doc={doc}
            onClick={doc => {
              openDocumentOverlay(doc, props.results)
            }}
          />
        )
      })}
    </div>
  )
}

function Suggestions(props) {
  if (props.isLoading) {
    return (
      <div>
        <NLLoader type="brain" />
        <div className="mx-auto text-xs mt-4 text-center">
          Loading suggestions..
        </div>
      </div>
    )
  }

  return (
    // by default
    <div className="flex flex-wrap">
      {props.results &&
        props.results.map((doc, index) => {
          return (
            <NLItemDocument
              key={'nl-doc-result-' + index}
              addSpaceMode={props.addSpaceMode}
              doc={doc}
              onClick={doc => {
                openDocumentOverlay(doc, props.results)
              }}
            />
          )
        })}
    </div>
  )
}

function SearchBox(props) {
  const [searchValue, setSearchValue] = useState('')
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false)
  const [suggestions, setSuggestions] = useState([])
  const [cancelToken, setCancelToken] = useState(null)

  const handleSuggestions = async val => {
    if (val.trim().length === 0) return
    setIsLoadingSuggestions(true)
    setSuggestions([])
    if (cancelToken !== null) {
      cancelToken.cancel(
        'Populating of previous suggestion canceled due to new request.'
      )
    }

    const ct = axios.CancelToken.source()
    setCancelToken(ct)
    const result = await http.get('/magic/autocomplete?query=' + val.trim(), {
      cancelToken: ct.token
    })
    setCancelToken(null)
    setIsLoadingSuggestions(false)
    const data = [
      {
        id: val.trim(),
        score: 1,
        type: 'free'
      },
      ...result.data.response.matches
    ]
    setSuggestions(data)
  }

  useEffect(() => {
    if (searchValue.length === 0) {
      setSuggestions([])
    }
  }, [searchValue])

  const suggestionsContainerCss = classNames(
    'flex flex-row space-x-2 text-sm flex-wrap overflow-y-auto max-h-64 px-4 py-2'
  )

  return (
    <div
      className="flex flex-col transition-all duration-200 ease-in-out"
      style={{
        boxShadow: '0px 4px 22px rgba(43, 12, 121, 0.04)',
        borderRadius: '25px'
      }}>
      <div
        className="bg-white px-4 py-1 pt-0 flex flex-row items-start justify-start"
        style={{
          border: '3px solid #E7E8FC',
          boxShadow: '0px 4px 22px rgba(43, 12, 121, 0.04)',
          borderRadius: '25px'
        }}>
        <div className="flex flex-row items-center flex-wrap flex-grow">
          {props.currentSearch.map((item, index) => {
            return (
              <NLChip
                className="mt-1 mr-2"
                key={index}
                item={item}
                onPurge={e => {
                  props.onPurge(e)
                }}
              />
            )
          })}
          <NLInput
            className="flex-grow"
            elClassName="py-1 mt-1 mx-4 px-0 text-sm border-0 flex-grow"
            placeholder="Search typing here.."
            onChange={e => {
              setSearchValue(e.target.value)
              handleSuggestions(e.target.value)
            }}
            id="search-input"
          />
        </div>
        <div className="pt-2.5">
          <div
            className={classNames(
              'text-purple px-2 text-lg cursor-pointer hover:text-purple-lighter',
              {
                invisible:
                  searchValue.length === 0 && props.currentSearch.length === 0
              }
            )}
            onClick={() => {
              setSearchValue('')
              props.onPurgeAll()
              document.getElementById('search-input').value = ''
            }}>
            <FaTimes />
          </div>
        </div>
      </div>
      {isLoadingSuggestions && (
        <div className="px-4 py-2 flex flex-row space-x-1 text-sm">
          <NLLoader size="xl" color="purple" />{' '}
          <p className="text-gray-600">Loading suggestions...</p>
        </div>
      )}
      {!isLoadingSuggestions &&
        suggestions.length === 0 &&
        searchValue.length > 0 &&
        props.contextSuggestions.length === 0 && (
          <div className="px-4 py-2 flex flex-row space-x-1 text-sm">
            <p className="text-gray-600">
              No suggestions. Try widening your search.
            </p>
          </div>
        )}
      {!isLoadingSuggestions && suggestions.length > 0 && (
        <div className={suggestionsContainerCss}>
          {suggestions.map((item, index) => {
            return (
              <NLChip
                key={index}
                item={item}
                className="mb-2"
                onClick={e => {
                  props.onSearch(e)
                  setSearchValue('')
                  document.getElementById('search-input').value = ''
                }}
              />
            )
          })}
        </div>
      )}
      {!isLoadingSuggestions &&
        suggestions.length === 0 &&
        props.contextSuggestions.length > 0 && (
          <div className={suggestionsContainerCss}>
            {props.contextSuggestions.map((item, index) => {
              return (
                <NLChip
                  key={index}
                  item={item}
                  className="mb-2"
                  onClick={e => {
                    props.onSearch(e)
                    setSearchValue('')
                    document.getElementById('search-input').value = ''
                  }}
                />
              )
            })}
          </div>
        )}
    </div>
  )
}

function Search() {
  const auth = useContext(AuthContext)
  const { spaceItemList, clearList, getList } = useContext(SpaceContext)

  const [selectedSort, setSelectedSort] = useState(1)
  const sortList = ['Newest First', 'Most Relevant']

  const [selectedFilter, setSelectedFilter] = useState(0)
  const filterList = ['All', 'Modified']

  const [isShowingAsGraph, setIsShowingAsGraph] = useState(false)

  const [currentSearch, setCurrentSearch] = useState([])
  const [isSearching, setIsSearching] = useState(true)
  const [searchResults, setSearchResults] = useState([])

  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false)
  const [suggestions, setSuggestions] = useState([])

  const params = useParams()
  const [searchParams] = useSearchParams()

  const [options, setOptions] = useState(null)
  const [addSpaceMode, setAddSpaceMode] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    document.title = 'Search | HeyBrain'
    clearList()
  }, [])

  useEffect(() => {
    if (
      searchParams.get('filter') !== null &&
      searchParams.get('filter') === 'modified_urls'
    ) {
      setSelectedFilter(1)
    } else {
      setSelectedFilter(0)
    }
  }, [searchParams])

  useEffect(() => {
    if (params.q) {
      const decoded = decodeURIComponent(params.q)
      const search = decoded.split(',')
      const s = []
      search.forEach(item => {
        if (item.trim().length > 0) {
          s.push({
            type: 'tag',
            id: item.trim(),
            score: 1
          })
        }
      })
      setCurrentSearch(v => {
        return [...v, ...s].filter(
          (thing, index, self) =>
            index ===
            self.findIndex(
              t => t.id.trim() === thing.id.trim() && t.type === thing.type
            )
        )
      })
    } else {
      setCurrentSearch([])
    }
  }, [params.q])

  useEffect(() => {
    const ct = axios.CancelToken.source()

    async function handleFreeSearch() {
      setIsSearching(true)

      const freeSearch = currentSearch
        .filter(item => item.type === 'free')
        .map(item => item.id)
        .join(', ')

      const result = await http.post(
        `/brain/embeddings/related`,
        {
          text: freeSearch,
          limit: 100
        },
        {
          cancelToken: ct.token
        }
      )

      if (result && result.data && result.data.response) {
        setSearchResults({
          documents: result.data.response
        })
      }

      setIsSearching(false)
    }

    async function handleSearch() {
      if (currentSearch.length > 0) {
        const ids = currentSearch.map(obj => obj.id).join(', ')
        document.title = `${ids} | Search`
        setIsSearching(true)
        const mockup = isLocalhost() ? '?mockup=1' : ''
        const result = await http.post(
          '/magic/search' + mockup,
          {
            keywords: currentSearch
              .filter(item => {
                return item.type === 'keyword'
              })
              .map(e => e.id),
            tags: currentSearch
              .filter(item => {
                return item.type === 'tag'
              })
              .map(e => e.id),
            sorting: selectedSort === 0 ? 'recency' : 'relevancy',
            is_modified: selectedFilter === 1 ? 1 : 0
          },
          {
            cancelToken: ct.token
          }
        )
        setIsSearching(false)
        if (result && result.data && result.data.response) {
          setSearchResults(result.data.response)
        }
      } else {
        document.title = 'Search | Heybrain'
        setSearchResults([])
      }
    }

    // if free search
    if (currentSearch.filter(item => item.type === 'free').length > 0) {
      handleFreeSearch()
    } else {
      handleSearch()
    }

    return () => {
      ct.cancel()
    }
  }, [currentSearch, selectedSort, selectedFilter])

  useEffect(() => {
    const ct = axios.CancelToken.source()
    async function handleSuggestions() {
      if (suggestions.length === 0) {
        setIsLoadingSuggestions(true)
        const mockup = isLocalhost() ? '?mockup=1' : ''
        const result = await http.post(
          '/magic/search' + mockup,
          {
            sorting: 'recency',
            is_modified: selectedFilter === 1 ? 1 : 0
          },
          {
            cancelToken: ct.token
          }
        )
        setIsLoadingSuggestions(false)
        if (result && result.data && result.data.response) {
          setSuggestions(result.data.response)
        }
      }
    }
    handleSuggestions()
    return () => {
      ct.cancel()
    }
  }, [suggestions, selectedFilter])

  useEffect(() => {
    setSuggestions([])
  }, [auth.user])

  const customStyles = {
    // to customize select for choosing space
    menu: (provided, state) => ({
      ...provided,
      marginTop: 0,
      width: '200px'
    }),
    control: (provided, state) => ({
      ...provided,
      width: '200px'
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.data.value === 'add new' ? '#A855F7' : 'inherit',
      fontWeight: state.data.value === 'add new' ? '600' : 'inherit'
    })
  }

  async function addInSpace(selectedOption) {
    setLoading(true)
    const items = spaceItemList.map((item, index) => ({
      repository: item.__repository,
      username: item.__username,
      id: item.__id,
      order: index + 1
    }))
    if (selectedOption.value === 'add new') {
      // if add into new space
      const name = prompt('input name of new space')
      if (!name) {
        setLoading(false)
        return
      }
      if (isValidString(name)) {
        // name validation
        const res = await http.post(`/space/create?repositories%5B%5D=web`, {
          access_type: 'public',
          items,
          name
        })
        showUserNotification({
          title: res.data.error
            ? res.data.error
            : `Items were added into ${name}.`,
          type: res.data.error ? 'error' : 'success',
          dismissible: false
        })
      } else
        showUserNotification({
          title: `Name should contain at least one letter or number`,
          type: 'error',
          duration: 4000,
          dismissible: false
        })
    } else {
      // if add into existing space
      const res = await http.post(
        `/space/add_items?space_slug=${selectedOption.value}&username=${auth.user.username}&repositories%5B%5D=web`,
        {
          items
        }
      )

      if (res.data.error) {
        showUserNotification({
          title: `${res.data.error}`,
          type: 'error',
          dismissible: false
        })
      } else {
        showUserNotification({
          title: `Items were added into ${selectedOption.value}.`,
          type: 'success',
          dismissible: false
        })
      }
    }
    clearList()
    setAddSpaceMode(false)
    setLoading(false)
  }

  let contextSuggestions = []
  if (
    searchResults &&
    searchResults.keywords &&
    searchResults.keywords.length > 0
  ) {
    contextSuggestions = [
      ...searchResults.keywords.map(k => {
        k.type = 'keyword'
        return k
      })
    ]
  }
  if (searchResults && searchResults.tags && searchResults.tags.length > 0) {
    contextSuggestions = [
      ...contextSuggestions,
      ...searchResults.tags
        .filter(t => t.id)
        .map(t => {
          t.type = 'tag'
          return t
        })
    ]
  }

  if (!isShowingAsGraph && currentSearch.length === 0) {
    if (
      suggestions &&
      suggestions.keywords &&
      suggestions.keywords.length > 0
    ) {
      contextSuggestions = [
        ...contextSuggestions,
        ...suggestions.keywords
          .filter(k => k.id)
          .map(k => {
            k.type = 'keyword'
            return k
          })
      ]
    }

    if (suggestions && suggestions.tags && suggestions.tags.length > 0) {
      contextSuggestions = [
        ...contextSuggestions,
        ...suggestions.tags
          .filter(t => t.id)
          .map(t => {
            t.type = 'tag'
            return t
          })
      ]
    }
  }

  return (
    <div className="flex flex-col">
      <SearchBox
        onSearch={p => {
          setCurrentSearch(old => [...old, p])
        }}
        contextSuggestions={contextSuggestions}
        currentSearch={currentSearch}
        onPurge={e => {
          const array = [...currentSearch]
          const i = array.indexOf(e)
          if (i !== -1) {
            array.splice(i, 1)
            setCurrentSearch(array)
          }
        }}
        onPurgeAll={() => {
          setCurrentSearch([])
        }}
      />
      <div className="mt-4">
        <div className="flex flex-row justify-end space-x-2">
          <div className="flex flex-grow md:text-sm sm:text-2xl items-center">
            {currentSearch.length > 0 &&
              `Search Results` +
                (searchResults?.documents?.length
                  ? ` (${searchResults?.documents?.length || 0})`
                  : '')}
            {currentSearch.length === 0 &&
              `Suggestions` +
                (suggestions?.documents?.length
                  ? ` (${suggestions?.documents?.length || 0})`
                  : '')}
          </div>
          {loading && <p className="text-xs  self-center">Adding...</p>}
          {addSpaceMode && spaceItemList.length > 0 && (
            <Select
              options={options}
              styles={customStyles}
              onChange={selectedOption => addInSpace(selectedOption)}
            />
          )}
          <NLSwitch
            onClick={() => {
              if (addSpaceMode) {
                clearList()
                setOptions(null)
                setAddSpaceMode(false)
                return
              }
              setAddSpaceMode(true)
              getList().then(data => {
                setOptions(data)
              })
            }}
            className={clsx('relative w-12 h-12', {
              'bg-slate-300': addSpaceMode
            })}>
            <GiRingedPlanet
              className={clsx('text-2xl ', {
                'text-violet-500': addSpaceMode,
                'text-gray-500': !addSpaceMode
              })}
            />
            <div className="flex justify-center items-center absolute top-0 right-0 w-4 h-4 mt-1 mr-1 bg-[#6416F3] rounded-full text-white">
              +
            </div>
          </NLSwitch>
          <NLSwitch
            className="w-12 h-12"
            isSelected={isShowingAsGraph}
            onClick={() => {
              setIsShowingAsGraph(!isShowingAsGraph)
            }}>
            <BiLineChart
              className={classNames('text-2xl', {
                'text-gray-800': !isShowingAsGraph,
                'text-white': isShowingAsGraph
              })}
            />
          </NLSwitch>
          <NLDropDown
            className="w-12 h-12"
            list={sortList}
            activeIndex={selectedSort}
            dismissOnSelection={true}
            didSelect={index => {
              setSelectedSort(index)
            }}
            popPosition="right-to-left">
            <MdSort className="text-2xl text-gray-500" />
          </NLDropDown>
          <NLDropDown
            className="w-12 h-12"
            list={filterList}
            activeIndex={selectedFilter}
            dismissOnSelection={true}
            didSelect={index => {
              setSuggestions([])
              setSelectedFilter(index)
            }}
            popPosition="right-to-left">
            <MdFilterAlt
              className={classNames('text-2xl', {
                'text-gray-500': selectedFilter === 0,
                'text-purple-600': selectedFilter === 1
              })}
            />
          </NLDropDown>
        </div>
        {!isShowingAsGraph && currentSearch.length === 0 && (
          <Suggestions
            isLoading={isLoadingSuggestions}
            results={suggestions.documents}
            addSpaceMode={addSpaceMode}
          />
        )}
        {!isShowingAsGraph && currentSearch.length > 0 && (
          <SearchResults
            isSearching={isSearching}
            results={searchResults.documents}
            addSpaceMode={addSpaceMode}
          />
        )}
        {isShowingAsGraph && (
          <p>
            Stuff will be here, <strong>as a graph.</strong>
          </p>
        )}
      </div>
    </div>
  )
}

export default Search
