import React from 'react'
import NLBoxFadeWithScale from './NLBoxFadeWithScale'
import classNames from 'classnames'
import { randomElement } from '../../services/helpers'

function NLItemKeyword(props) {
  const kw = props.kw
  const color = randomElement([
    'text-red-600',
    'text-orange-500',
    'text-yellow-400',
    'text-green-500',
    'text-cyan-400',
    'text-sky-400',
    'text-blue-500',
    'text-purple-500',
    'text-fushia-400',
    'text-rose-600'
  ])
  return (
    <NLBoxFadeWithScale delay={props.delay}>
      <div
        className={classNames('keyword-box font-semibold', color)}
        style={{
          fontSize: kw.ui.fontSize
        }}>
        {kw.id}
      </div>
    </NLBoxFadeWithScale>
  )
}

export default NLItemKeyword
