import clsx from 'clsx'
import {
  useContext,
  useDeferredValue,
  useEffect,
  useRef,
  useState
} from 'react'
import { MdContentCopy } from 'react-icons/md'
import { useAuthContext } from '../../context/AuthContext'
import { SpaceContext } from '../../context/SpaceContext'
import { closeSpaceOverlay, showUserNotification } from '../../services/helpers'
import http from '../../services/http'
import NLButton from '../common/NLButton'
import NLInput from '../common/NLInput'
import spinner from '../svg/spinner.svg'

const SpaceItemOverlay = () => {
  const context = useContext(SpaceContext)
  const { hasOwnerAccess, sharedUsers } = context
  const { user } = useAuthContext()
  const isOwner = hasOwnerAccess(user.username, sharedUsers)
  const nodeRef = useRef(null)
  const [usersList, setUsersList] = useState([])
  const [space, setSpace] = useState([])
  const [input, setInput] = useState('')
  const [isAccessChanging, setIsAccessChanging] = useState(false)

  const deferredValue = useDeferredValue(input)

  useEffect(() => {
    http
      .get(
        `/user/search/autocomplete?q=${deferredValue}&repositories%5B%5D=web`
      )
      .then(response => {
        if (response.data.response !== null) {
          setUsersList(response.data.response)
        } else {
          setUsersList(['no results'])
        }
      })
  }, [deferredValue])

  useEffect(() => {
    setSpace(context.space)

    function handleOutsideClick(event) {
      if (!nodeRef.current.contains(event.target)) {
        closeSpaceOverlay()
      }
    }
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [context])

  async function getSpace() {
    const res = await http.get(
      `/space/get?username=${space.__username}&space_slug=${space.slug}`
    )
    if (res) {
      setSpace(res.data.response.space)
      context.checkAccessType(
        context.access_type,
        res.data.response.space.shared_users
      )
    }
  }

  function SharedUser({ item }) {
    const { username, role } = item
    const [isSelected, setIsSelected] = useState(false)

    function revokeAccess(username, slug) {
      setIsSelected(true)
      http
        .post(`/space/revoke_access?repositories%5D=web`, {
          revoked_username: username,
          space_slug: slug
        })
        .then(() => {
          getSpace()
        })
    }

    return (
      <>
        <div className="grid grid-cols-2 w-full p-2 gap-3 justify-between items-center shadow overflow-hidden  hover:bg-violet-100">
          <div className="flex gap-4 items-center ">
            <div
              className="rounded-full p-5 w-100 bg-cover border border-gray-200 shadow-lg"
              style={{
                backgroundImage: `url(https://robohash.org/${username})`
              }}></div>
            <div>{username}</div>
          </div>

          <div className="flex gap-4 items-center justify-between ">
            <div>{role.split('_')[0]}</div>
            {!isSelected && isOwner && (
              <NLButton
                type="button"
                className="justify-self-end px-3 py-2 text-xs uppercase hover:font-bold hover:text-red-700"
                onClick={() => revokeAccess(username, space.slug)}>
                x
              </NLButton>
            )}
            {isSelected && (
              <div
                className={clsx('p-0 m-0', {
                  visible: isSelected,
                  invisible: !isSelected
                })}>
                <img src={spinner} alt="spinner" />
              </div>
            )}
          </div>
        </div>
      </>
    )
  }

  function renderSharedUsers(arr) {
    const users = arr.map((item, index) => {
      return <SharedUser item={item} key={index} />
    })
    return <ul className="flex flex-col py-1 w-full">{users}</ul>
  }

  const User = ({ item }) => {
    const [isHovered, setIsHovered] = useState(false)
    const [isSelected, setIsSelected] = useState(false)

    if (sharedUsers.some(obj => obj.username === item)) return // return if user has access

    async function shareAccess(e, item) {
      http
        .post('/space/share_space?repositories%5B%5D=web', {
          role: e.target.value,
          shared_with: item,
          space_slug: space.slug
        })
        .then(() => {
          getSpace().then(() => {
            setInput('')
          })
        })
      setIsSelected(true)
    }

    return (
      <div
        className="flex w-full p-2 gap-3 items-center justify-between shadow overflow-hidden  hover:bg-violet-100"
        onMouseEnter={() => {
          setIsHovered(true)
        }}
        onMouseLeave={() => {
          setIsHovered(false)
        }}>
        <div className="flex items-center gap-4">
          <div
            className="rounded-full p-5 bg-cover w-100 border border-gray-200 shadow-lg"
            style={{
              backgroundImage: `url(https://robohash.org/${item})`
            }}></div>
          <div>{item}</div>
        </div>

        {isSelected && (
          <div
            className={clsx('rounded-full', {
              visible: isSelected,
              invisible: !isSelected
            })}>
            <img src={spinner} alt="spinner" />
          </div>
        )}
        {!isSelected && (
          <select
            className={clsx('py-2 justify-self-end ', {
              visible: isHovered,
              invisible: !isHovered
            })}
            onChange={e => {
              shareAccess(e, item)
            }}>
            <option value="" className="px-4 py-2 cursor-pointer">
              --choose--
            </option>
            <option value="editor_access" className="px-4 py-2 cursor-pointer">
              editor
            </option>
            <option value="owner_access" className="px-4 py-2 cursor-pointer">
              owner
            </option>
            <option value="viewer_access" className="px-4 py-2  cursor-pointer">
              viewer
            </option>
          </select>
        )}
      </div>
    )
  } // end of User

  function renderUsersList(arr) {
    const users = arr.map((item, index) => {
      return <User item={item} key={'username-' + index} />
    })

    return <ul className="flex flex-col gap-1  py-1 w-full">{users}</ul>
  }

  const onValueChange = e => {
    setInput(e.target.value)
  }

  const changeAccessType = () => {
    setIsAccessChanging(true)
    const newAccessType =
      context.access_type === 'public' ? 'private' : 'public'
    http
      .post(
        `/space/update?space_slug=${space.slug}&username=${space.__username}&repositories=web`,
        {
          access_type: newAccessType,
          name: space.name,
          space_slug: space.slug
        }
      )
      .then(result => {
        setSpace(result.data.response)
        context.checkAccessType(
          result.data.response.access_type,
          context.sharedUsers
        )
        setIsAccessChanging(false)
        showUserNotification({
          title: `Access was changed to ${newAccessType}`,
          type: 'success',
          dismissible: false
        })
      })
  }

  const content =
    usersList.length > 0 &&
    !usersList.includes('no results') &&
    deferredValue?.length > 0 ? (
      renderUsersList(usersList)
    ) : usersList.includes('no results') ? (
      <p className="text-xs text-orange-800">no results</p>
    ) : null

  return (
    <>
      <div
        className="absolute flex p-6 justify-end bg-black/50 right-0 top-0 w-screen h-screen"
        style={{ zIndex: '10010' }}>
        <div
          ref={nodeRef}
          className="flex flex-col gap-2 bg-white p-2 rounded lg:w-1/3 min-w-min shadow overflow-y-auto">
          <div
            className="cursor-pointer hover:text-gray-500 mt-1 text-xs self-end"
            onClick={() => {
              closeSpaceOverlay()
            }}>
            {' '}
            close
          </div>
          {isOwner && (
            <div className="flex flex-col gap-3">
              <div className="flex gap-4 items-center">
                <NLButton onClick={changeAccessType}>
                  {context.access_type === 'public'
                    ? 'make private'
                    : 'make public'}
                </NLButton>
                <p
                  className={clsx('text-xs', {
                    visible: isAccessChanging,
                    invisible: !isAccessChanging
                  })}>
                  Please wait...
                </p>
              </div>
              <div className="relative">
                <NLInput
                  type="text"
                  value={input}
                  className="text-base relative  rounded-md border border-gray-200 focus:border-gray-400 focus:outline-none font-normal w-full"
                  autoFocus
                  placeholder="Add people"
                  onChange={onValueChange}
                />
                <label
                  htmlFor="input"
                  className="text-sm absolute right-3 bottom-3 text-neutral-500 cursor-pointer"
                  onClick={() => {
                    setInput('')
                  }}>
                  x
                </label>
              </div>
              <div>{content}</div>
            </div>
          )}
          <p className="mt-3 text-sm">People with access:</p>
          <div>{renderSharedUsers(context.sharedUsers)}</div>

          <div className="flex flex-col  w-full">
            <div className="flex relative">
              <div className="flex justify-between w-full bg-gray-100 p-3  rounded-md border border-gray-200 text-base font-normal">
                <p className="text-sm truncate overflow-ellipsis">
                  {window.location.href}
                </p>
                <MdContentCopy
                  className="w-6 h-6 text-gray-400 cursor-pointer"
                  onClick={() => {
                    navigator.clipboard.writeText(window.location.href)
                    showUserNotification({
                      title: `Link copied`,
                      type: 'success',
                      dismissible: false
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SpaceItemOverlay
