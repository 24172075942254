import React, { useState } from 'react'
import { HiOutlineCursorClick } from 'react-icons/hi'
import NLButton from '../../common/NLButton'
import { ReactSVG } from 'react-svg'
import classNames from 'classnames'
import {
  MdOutlineArrowDropDown,
  MdOutlineArrowDropUp,
  MdOutlineCancel
} from 'react-icons/md'
import RepositoryItem from './RepositoryItem'

function RepositoryGroup(props) {
  const [isOpen, setIsOpen] = useState(false)
  const [inUseAction, setInUseAction] = useState(false)

  const isAllInUse = () => {
    let found = 0
    props.items.forEach(element => {
      if (props.auth.user.repositories.indexOf(element.name) > -1) {
        found++
      }
    })
    return found === props.items.length
  }

  const onToggleUse = () => {
    if (inUseAction) return
    setInUseAction(true)
    let r = []
    if (isAllInUse()) {
      // Discard all action. Do not toggle currently used repos.
      const used = props.items.filter(e => {
        return props.auth.user.repositories.indexOf(e.name) > -1
      })
      r = used
    } else {
      // Use all. Do not toggle currently not used repos.
      const noUsed = props.items.filter(e => {
        return props.auth.user.repositories.indexOf(e.name) === -1
      })
      r = noUsed
    }
    props.auth.toggleRepository(
      r.map(i => i.name),
      () => {
        setInUseAction(false)
      }
    )
  }
  if (props.repo === undefined) {
    return
  }
  return (
    <div className="flex flex-col">
      <div className="bg-white p-4 flex flex-row items-center rounded shadow-sm">
        <ReactSVG
          src={props.repo.icon}
          beforeInjection={svg => {
            svg.setAttribute('style', 'width: 48px')
            svg.setAttribute('style', 'height: 48px')
          }}
        />
        <div className="grow ml-6">{props.repo.name}</div>
        <NLButton
          className={classNames('flex flex-row', {
            'bg-pink-400': isAllInUse() && !inUseAction
          })}
          onClick={() => {
            onToggleUse()
          }}
          isDisabled={inUseAction}>
          {inUseAction ? (
            'SAVING'
          ) : (
            <>
              {isAllInUse() ? (
                <div className="flex flex-row">
                  <MdOutlineCancel className="text-xl mr-1" /> DISCARD ALL
                </div>
              ) : (
                <div className="flex flex-row">
                  <HiOutlineCursorClick className="text-xl mr-1" /> USE ALL
                </div>
              )}
            </>
          )}
        </NLButton>
        <div
          className="text-2xl ml-10 text-gray-500 hover:bg-gray-200 cursor-pointer p-4 rounded"
          onClick={() => {
            setIsOpen(!isOpen)
          }}>
          {isOpen ? <MdOutlineArrowDropUp /> : <MdOutlineArrowDropDown />}
        </div>
      </div>
      {isOpen &&
        props.items.map((item, i) => {
          return (
            <RepositoryItem
              key={'repo-item-' + i}
              auth={props.auth}
              repo={props.repo}
              isParentInUseAction={inUseAction}
              item={item}
            />
          )
        })}
    </div>
  )
}

export default RepositoryGroup
