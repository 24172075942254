import React, { useState } from 'react'
import { ReactSVG } from 'react-svg'
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from 'react-icons/md'
import classNames from 'classnames'
import RepositorySelectorItem from './RepositorySelectorItem'

function RepositorySelectorGroup(props) {
  const [isOpen, setIsOpen] = useState(false)

  const activeRepoCountOfGroup = props.items.filter(
    item => props.auth.user.repositories.indexOf(item.name) > -1
  ).length

  const className = classNames(
    'flex flex-col rounded bg-white text-gray-900 px-4 py-2',
    {
      'hover:bg-gray-100': !isOpen
    }
  )

  // TEMPORARY FIX FOR repo=undefined
  if (!props.repo) {
    return
  }

  return (
    <div className={className}>
      <div
        className="flex flex-row items-center cursor-pointer"
        onClick={() => {
          setIsOpen(!isOpen)
        }}>
        <ReactSVG
          src={props.repo.icon}
          beforeInjection={svg => {
            svg.setAttribute('style', 'width: 24px')
            svg.setAttribute('style', 'height: 24px')
          }}
        />
        <div className="grow px-4">{props.repo.name}</div>
        <div className="ml-10 text-xs bg-purple-100 py-0.5 px-2 rounded">
          {activeRepoCountOfGroup}
        </div>
        <div className="text-2xl text-gray-500 p-2 rounded">
          {isOpen ? <MdOutlineArrowDropUp /> : <MdOutlineArrowDropDown />}
        </div>
      </div>
      {isOpen && (
        <div
          className="flex flex-col overflow-auto"
          style={{ maxHeight: '300px' }}>
          {props.items.map((item, i) => {
            return (
              <RepositorySelectorItem
                key={'repo-item-' + i}
                auth={props.auth}
                repo={props.repo}
                item={item}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}

export default RepositorySelectorGroup
