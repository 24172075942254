import React, { useState, useEffect } from 'react'
import NLItemKeyword from './NLItemKeyword'
import NLItemDocument from './NLItemDocument'
import {
  calculateTextHeight,
  calculateTextWidth,
  hasIntersection,
  getRandomNumberBetween,
  shuffle
} from '../../services/helpers'

function NLCloud(props) {
  const [list, setList] = useState([])
  const totalElements = props.totalElements ? props.totalElements : 25
  useEffect(() => {
    const r =
      props.list.length > totalElements
        ? shuffle(props.list).slice(0, totalElements)
        : shuffle(props.list)

    let maxScore = Math.max(...r.map(o => o.score))
    maxScore =
      isNaN(maxScore) || maxScore === undefined || maxScore === 0 ? 1 : maxScore
    const maxFontSize = 120
    const nFontSize = 48
    const minFontSize = 30
    const maxSize = {
      width: 325,
      height: 228
    }
    const minSize = {
      width: 275,
      height: 192
    }

    r.forEach(e => {
      const sizeFactor = e.score ? e.score / maxScore : maxScore
      e.ui = {
        frame: {
          left: 0,
          top: 0,
          right: 0,
          bottom: 0
        }
      }
      if (e.type === 'keyword') {
        let fontSize = nFontSize * sizeFactor
        fontSize = fontSize < minFontSize ? minFontSize : fontSize
        fontSize = fontSize > maxFontSize ? maxFontSize : fontSize
        const width = calculateTextWidth(e.id, fontSize, 'Montserrat')
        const height = calculateTextHeight(fontSize)
        e.ui = {
          ...e.ui,
          fontSize,
          width,
          height
        }
      } else {
        const width = maxSize.width * sizeFactor
        const height = maxSize.height * sizeFactor
        e.ui = {
          ...e.ui,
          width: width < minSize.width ? minSize.width : width,
          height: height < minSize.height ? minSize.height : height
        }
      }
    })

    // Get width of the div with id app-content
    let appContentWidth =
      document.getElementById('app-content').offsetWidth / 20
    let appContentHeight =
      document.getElementById('app-content').offsetHeight / 20

    appContentWidth =
      appContentWidth < maxSize.width ? maxSize.width : appContentWidth
    appContentHeight =
      appContentHeight < maxSize.height ? maxSize.height : appContentHeight

    let boxScaleFactor = 1

    // Check if element frame is intersecting with item frames in r array.
    const isIntersecting = (frame, items) => {
      return items.some(item => {
        return hasIntersection(frame, item.ui.frame)
      })
    }

    const spacing = 10
    r.forEach(e => {
      let left = getRandomNumberBetween(
        spacing,
        appContentWidth * boxScaleFactor - e.ui.width - spacing * 2
      )
      let top = getRandomNumberBetween(
        spacing,
        appContentHeight * boxScaleFactor - e.ui.height - spacing * 2
      )
      let frame = {
        left,
        top,
        right: left + e.ui.width + spacing,
        bottom: top + e.ui.height + spacing
      }
      let positionTry = 0
      while (isIntersecting(frame, r)) {
        if (positionTry > 100) {
          boxScaleFactor *= 1.1
          positionTry = 0
        }
        left = getRandomNumberBetween(
          spacing,
          appContentWidth * boxScaleFactor - e.ui.width - spacing * 2
        )
        top = getRandomNumberBetween(
          spacing,
          appContentHeight * boxScaleFactor - e.ui.height - spacing * 2
        )
        frame = {
          left,
          top,
          right: left + e.ui.width + spacing,
          bottom: top + e.ui.height + spacing
        }
        positionTry += 1
      }
      e.ui.frame = frame
    })

    setList(r)
  }, [props.list, totalElements])

  if (!props.list) {
    return ''
  }

  return (
    <div className="w-full h-full overflow-auto relative">
      {list.map((e, i) => {
        const style = {
          width: e.ui.width + 'px',
          height: e.ui.height + 'px',
          marginLeft: e.ui.frame.left + 'px',
          marginTop: e.ui.frame.top + 'px'
        }
        let delay = i * 200
        if (delay > 600) {
          delay = 600 + (delay - 600) * 0.15
        }
        const maxDelay = 2500
        delay = delay > maxDelay ? maxDelay : delay
        return (
          <div key={i} style={style} className="absolute">
            {e.type === 'keyword' && <NLItemKeyword kw={e} delay={delay} />}
            {e.type === 'document' && <NLItemDocument doc={e} delay={delay} />}
          </div>
        )
      })}
    </div>
  )
}

export default NLCloud
