export const UserAuthTokenKey = 'kAccKeyTknNLPG'
export const ChatbotUrl = 'https://dty0xa7tzq24v.cloudfront.net'

/**
 * @description Returns true if url is localhost
 */
export const isLocalhost = () => {
  return false
  // return (
  //   window.location.hostname.includes("localhost") ||
  //   window.location.hostname.includes("127.0.0.1")
  // );
}

/**
 *
 * @returns All available repo types in local storage.
 */
export const repoTypes = () => {
  return JSON.parse(localStorage.getItem('repo_types')) || []
}

/**
 * @description Returns the whole repo type object for given repo type id.
 * @param {string} repoTypeId
 * @returns {object} repo
 */
export const repoType = repoTypeId => {
  const r = JSON.parse(localStorage.getItem('repo_types')) || []
  if (r) {
    return r.find(e => e.id === repoTypeId)
  }
  return null
}

/**
 *
 * @returns  All repos in the local storage.
 */
export const repos = () => {
  return JSON.parse(localStorage.getItem('repos')) || {}
}

/**
 *
 * @returns Grouped given array by given key.
 */
export const groupBy = (xs, key) => {
  return xs.reduce((rv, x) => {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

/**
 *
 * @returns Random element from given array.
 */
export function randomElement(array) {
  return array[Math.floor(Math.random() * array.length)]
}

/**
 *
 * @returns Random number between min max values.
 */
export function getRandomNumberBetween(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

/**
 * @description Converts given string to title case.
 * @returns Title cased string.
 */
export const titleCase = str => {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join(' ')
}

/**
 * @description Shuffles given array in place.
 * @returns Shuffled array.
 */
export function shuffle(array) {
  if (!Array.isArray(array)) return []
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

/**
 * @description Function to calculate text width in pixels.
 * @returns Text width in pixels.
 */
export function calculateTextWidth(text, fontSize, fontFamily) {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  context.font = fontSize + 'px ' + fontFamily
  const metrics = context.measureText(text)
  return metrics.width
}

/**
 * @description Function to calculate text height in pixels.
 * @returns Text height in pixels.
 */
export function calculateTextHeight(fontSize) {
  return fontSize * 1.5
}

/**
 * @description Function that checks if two rectangles intersect.
 * @returns True if rectangles intersect.
 */
export function hasIntersection(a, b) {
  return !(
    a.left > b.right ||
    a.right < b.left ||
    a.top > b.bottom ||
    a.bottom < b.top
  )
}

/**
 * @description Function that dispatches CustomEvent.
 * @param {string} eventName - Name of the event to dispatch.
 * @param {object} detail - Detail to pass to the event.
 */
export function dispatchCustomEvent(eventName, detail) {
  const event = new CustomEvent(eventName, detail)
  document.dispatchEvent(event)
}

/**
 * @description Creates user notification event and shows it with given info.
 * @param {string} title - Title of notification.
 * @param {string} message - Message of notification.
 * @param {string} type - Type of notification. Expected values: info (default), success, error, warning.
 * @param {number} duration - Duration of notification in milliseconds, send -1 to make it sticky, default 3000.
 * @param {boolean} isDismissible - Shows a dismiss button, default true.
 * @param {string} dismissString - Text of the dismiss button, default "Dismiss"
 * @returns Nothing.
 */
export function showUserNotification({
  title = '',
  message = '',
  type = 'info',
  duration = 3000,
  dismissible = true,
  dismissString = 'Dismiss'
}) {
  const event = new CustomEvent('user-notification', {
    detail: {
      title,
      message,
      type,
      duration,
      dismissible,
      dismissString
    }
  })
  document.dispatchEvent(event)
}

/**
 * @description Opens document in overlay.
 * @param {string} document - Document object to open.
 */
export function openDocumentOverlay(doc, list = [], slug = null) {
  // added slug in arguments 13.06: if slug is not initialized by invoking, slug = null

  const event = new CustomEvent('show-document-overlay', {
    detail: {
      document: doc,
      list,
      slug // added slug 13.06
    }
  })
  document.dispatchEvent(event)
}

/**
 * @description Closes document in overlay.
 * @param {string} document - Document object to open.
 */
export function closeDocumentOverlay() {
  const event = new CustomEvent('show-document-overlay', {
    detail: {
      doc: null,
      list: [],
      slug: null // added slug 13.06
    }
  })
  document.dispatchEvent(event)
}

export function openSpaceOverlay(repositories = 'hello', slug = 'react') {
  const event = new CustomEvent('show-space-overlay', {
    detail: {
      repositories,
      slug
    }
  })
  document.dispatchEvent(event)
}

export function closeSpaceOverlay() {
  const event = new CustomEvent('show-space-overlay', {
    detail: {
      repositories: null,
      slug: null
    }
  })
  document.dispatchEvent(event)
}

export function openConversationOverlay(conversation) {
  // open overlay in /conversations
  const event = new CustomEvent('show-conversation-overlay', {
    detail: {
      conversation
    }
  })
  document.dispatchEvent(event)
}

export function closeConversationOverlay() {
  // close overlay in /conversations

  const event = new CustomEvent('show-conversation-overlay', {
    detail: {
      conversation: null
    }
  })
  document.dispatchEvent(event)
}

export function openUserOverlay(user) {
  // open overlay in /admin
  const event = new CustomEvent('show-user-overlay', {
    detail: {
      user
    }
  })
  document.dispatchEvent(event)
}

export function closeUserOverlay() {
  // close overlay in /admin
  const event = new CustomEvent('show-user-overlay', {
    detail: {
      user: null
    }
  })
  document.dispatchEvent(event)
}

export function getPathPart(path) {
  const segments = path.split('/')
  if (segments.length > 1) {
    return segments.slice(1).join('/')
  }
  return path
}

export function isValidString(inputString) {
  if (inputString.length > 70) {
    return false
  }
  if (!/[a-zA-Z]/.test(inputString) && !/\d/.test(inputString)) {
    return false
  }
  const allowedCharacters = /^[a-zA-Z0-9\s\S]+$/
  if (!allowedCharacters.test(inputString)) {
    return false
  }
  return true
}

export function copyToClipboard(text, notificationText = '') {
  const el = document.createElement('textarea')
  el.value = text
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)

  if (notificationText) {
    showUserNotification({
      title: 'Copied to clipboard',
      message: notificationText,
      type: 'info',
      duration: 1500
    })
  }
}

export function copyChatUrlToClipboard({ user, fileId }) {
  const apiKey = user.api_key
  let url = `${ChatbotUrl}?api_key=${apiKey}`

  if (fileId) {
    url += `&file_id=${fileId}`
  }

  copyToClipboard(url)

  showUserNotification({
    title: 'Copied to clipboard',
    message: `Chat URL copied to clipboard${
      fileId ? ' for the uploaded file' : ''
    }.`,
    type: 'info'
  })
}
