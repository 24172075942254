import React from 'react'
import classNames from 'classnames'
import {
  BsCircle,
  BsCheckCircleFill,
  BsSquare,
  BsCheckSquare
} from 'react-icons/bs'

function NLCheckBox({
  isChecked,
  isDisabled = false,
  type = 'circle',
  size = 20,
  className: customClasses = '',
  ...props
}) {
  const className = classNames(
    'flex flex-row items-center py-2 focus:outline-none',
    customClasses,
    {
      'cursor-pointer': !isDisabled,
      'cursor-default': isDisabled,
      'opacity-50': isDisabled
    }
  )
  return (
    <div {...props} className={className}>
      {isChecked ? (
        <>
          {type === 'square' ? (
            <BsCheckSquare size={size} className="text-purple" />
          ) : (
            <BsCheckCircleFill size={size} className="text-purple" />
          )}
        </>
      ) : (
        <>
          {type === 'square' ? (
            <BsSquare size={size} />
          ) : (
            <BsCircle size={size} />
          )}
        </>
      )}
      <span className="mx-1">{props.children}</span>
    </div>
  )
}

export default NLCheckBox
