import Cookies from 'js-cookie'
import React, { createContext, useContext, useState } from 'react'
import { Navigate, useLocation, useOutlet } from 'react-router-dom'
import NLLoader from '../components/common/NLLoader'
import { UserAuthTokenKey } from './../services/helpers'
import http from './../services/http'

export const AuthContext = createContext({
  user: null
})

export const useAuthContext = () => {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null)

  const clearUser = () => {
    setUser(null)
    Cookies.remove(UserAuthTokenKey, '')
    localStorage.clear()
  }

  const signIn = (user, callback) => {
    if (user) {
      setUser(user)
      if (callback instanceof Function) callback()
    } else {
      http
        .get('/user/profile')
        .then(res => {
          if (res && res.data.response) {
            setUser(res.data.response)
          } else {
            clearUser()
          }
          if (callback instanceof Function) callback()
        })
        .catch(error => {
          if (error) {
            clearUser()
            if (callback instanceof Function) callback()
          }
        })
    }
  }

  const signOut = callback => {
    clearUser()
    if (callback instanceof Function) callback()
  }

  const toggleRepository = (name, callback) => {
    const r = user.repositories
    if (name instanceof Array) {
      name.forEach(n => {
        const index = r.indexOf(n)
        if (index === -1) {
          r.push(n)
        } else {
          r.splice(index, 1)
        }
      })
    } else {
      const index = r.indexOf(name)
      if (index === -1) {
        r.push(name)
      } else {
        r.splice(index, 1)
      }
    }

    http
      .post('/user/save_repository', {
        repositories: r
      })
      .then(res => {
        if (res && res.data.status) {
          setUser(current => {
            return {
              ...current,
              repositories: r
            }
          })
          if (callback instanceof Function) callback()
        }
      })
  }

  const value = { user, signIn, signOut, toggleRepository }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export function RequireAuth() {
  const auth = useContext(AuthContext)
  const location = useLocation()
  const outlet = useOutlet()
  const [isLoading, setIsLoading] = useState(false)

  if (
    !auth.user &&
    !isLoading &&
    Cookies.get(UserAuthTokenKey) &&
    Cookies.get(UserAuthTokenKey).length > 0
  ) {
    setIsLoading(true)
    auth.signIn(null, () => {
      setIsLoading(false)
    })
  }

  if (isLoading) {
    return (
      <NLLoader
        size="6xl"
        className="flex items-center justify-center w-screen h-screen"
      />
    )
  }
  if (!auth.user) {
    const requestedUrl = location.pathname
    localStorage.setItem('requestedUrl', JSON.stringify(requestedUrl))
    const r = location.pathname === '/' ? 'welcome' : 'login'
    return <Navigate to={'/' + r} state={{ from: location }} replace />
  }
  return outlet
}
