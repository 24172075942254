import { useState, useEffect } from 'react'
import http from '../../services/http'
import { showUserNotification, openUserOverlay } from '../../services/helpers'
import clsx from 'clsx'
import NLLoader from '../common/NLLoader'
import { useNavigate } from 'react-router-dom'
import NLButton from '../common/NLButton'

const Admin = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [users, setUsers] = useState([])

  useEffect(() => {
    document.title = 'Users | HeyBrain'
    loadUsers()
  }, [])

  async function loadUsers() {
    setIsLoading(true)
    http.get('/admin/users?repositories%5D=web').then(response => {
      setUsers(response.data.response)
      setIsLoading(false)
    })
  }

  const User = ({ user, index }) => {
    const {
      username,
      firstname,
      lastname,
      email,
      api_key,
      created_at,
      updated_at,
      id
    } = user.user
    const { repo_count, doc_count } = user
    const [isQueuedForDelete, setQueuedForDelete] = useState(false)

    function deleteUser(user) {
      // function for deleting user
      const { username } = user.user
      if (!window.confirm(`Are you sure you want to delete ${username}? `))
        return

      setQueuedForDelete(true) // for hovering user if he is queued for deletion
      showUserNotification({
        title: `user ${username} is queued for deletion`,
        type: 'success',
        dismissible: false
      })
      http
        .delete(`/admin/user?username=${username}&repositories%5B%5D=web`)
        .then(response => loadUsers())
        .catch(error => {
          console.log(error)
          showUserNotification({
            title: `something went wrong`,
            type: 'error',
            dismissible: false
          })
        })
    }

    return (
      <>
        <tr
          key={index}
          onClick={() => {
            openUserOverlay(user)
          }}
          className={clsx(
            'border-b transition duration-300 ease-in-out hover:bg-neutral-100 cursor-pointer',
            {
              'bg-red-100': isQueuedForDelete
            }
          )}>
          <td className="whitespace-nowrap px-6 py-4 font-medium">
            {username}
          </td>
          <td className="whitespace-nowrap px-6 py-4 font-medium">
            {firstname}
          </td>
          <td className="whitespace-nowrap px-6 py-4 font-medium">
            {lastname}
          </td>
          <td className="whitespace-nowrap px-6 py-4 min-w-fit">
            {repo_count}
          </td>
          <td className="whitespace-nowrap px-6 py-4 min-w-fit">{doc_count}</td>
          <td className="whitespace-nowrap px-6 py-4">{email}</td>
          <td className="whitespace-nowrap px-6 py-4">{api_key}</td>
          <td className="whitespace-nowrap px-6 py-4">{id}</td>
          <td className="whitespace-nowrap px-6 py-4">{created_at}</td>
          <td className="whitespace-nowrap px-6 py-4">{updated_at}</td>
          <td className="whitespace-nowrap px-6 py-4">
            {!isQueuedForDelete && (
              <button
                type="button"
                className="inline-block rounded bg-red-500 px-4 pb-2 pt-2.5 text-xs uppercase text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
                onClick={() => deleteUser(user)}>
                delete
              </button>
            )}
            {isQueuedForDelete && <p>queued for delete</p>}
          </td>
        </tr>
      </>
    )
  } // end of User

  function renderUserTable(arr) {
    const users = arr.map((user, index) => {
      return <User user={user} key={'nl-user-' + index} />
    })

    return (
      <section className="w-full px-4  ">
        <div className="flex px-6 gap-3 ">
          <NLButton
            onClick={() => {
              navigate('/conversations')
            }}>
            Latest telegram
          </NLButton>
        </div>
        <table className="min-w-full text-left text-sm font-light ">
          <thead className="border-b font-medium dark:border-neutral-500">
            <tr>
              <th scope="col" className="px-6 py-4">
                Username
              </th>
              <th scope="col" className="px-6 py-4">
                First name
              </th>
              <th scope="col" className="px-6 py-4">
                Last name
              </th>
              <th scope="col" className="px-6 py-4">
                # Repos
              </th>
              <th scope="col" className="px-6 py-4">
                Doc count
              </th>
              <th scope="col" className="px-6 py-4">
                E-mail
              </th>
              <th scope="col" className="px-6 py-4">
                API Key
              </th>
              <th scope="col" className="px-6 py-4">
                User ID
              </th>
              <th scope="col" className="px-6 py-4">
                Registered At
              </th>
              <th scope="col" className="px-6 py-4">
                Last Login
              </th>
              <th scope="col" className="px-6 py-4">
                Delete
              </th>
            </tr>
          </thead>
          <tbody>{users}</tbody>
        </table>
      </section>
    )
  }

  const content = isLoading ? (
    <NLLoader size="3xl" elClassName="mx-auto" />
  ) : (
    renderUserTable(users)
  )

  return <>{content}</>
}

export default Admin
