import React from 'react'
import { useNavigate } from 'react-router-dom'
import NLButton from '../common/NLButton'

function Welcome() {
  const navigate = useNavigate()

  return (
    <>
      <div
        className="h-screen flex flex-col justify-center px-6 pt-6 gap-6 md:px-24  lg:px-48 xl:px-64 2xl:px-96 hey-brain-main font-bold text-white justify-items-center
       bg-gradient-to-t from-violet-500 to-violet-400 ">
        <h1 className="text-center font-['Fredoka'] font-bold text-2xl sm:text-3xl md:text-5xl ">
          Hi there! Meet Brain!
        </h1>

        <div className=" h-0 relative overflow-hidden pt-[35px] pb-[56.25%] ">
          <iframe
            className="absolute top-0 left-0 w-full h-full"
            src="https://player.vimeo.com/video/800837853?h=b60101d591"
            allowFullScreen
            title="instruction">
          </iframe>
        </div>

        <div className="flex flex-col justify-center items-center ">
          <div className="flex gap-2 justify-center">
            <NLButton
              className="py-3 md:py-6"
              style={{
                backgroundImage: 'linear-gradient(180deg, #ff90b2, #f65888)',
              }}
              onClick={() => {
                navigate('/register')
              }}>
              <p
                className="decoration-solid text-sm font-semibold font-['Fredoka']"
                style={{ letterSpacing: '2px' }}>
                GET A DIGITAL BRAIN NOW!
              </p>
            </NLButton>
          </div>
        </div>

        <div className="flex bottom font-medium gap-1 justify-center ">
          <p>Already have an account? </p>
          <p className="underline">
            <a className=" hover:text-violet-200" href="/login">
              login
            </a>
          </p>
        </div>
      </div>
    </>
  )
}

export default Welcome
