import Cookies from 'js-cookie'
import { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { UserAuthTokenKey } from '../../services/helpers'
import http from '../../services/http'
import NLAlert from '../common/NLAlert'
import NLButton from '../common/NLButton'
import NLCheckBox from '../common/NLCheckBox'
import NLInput from '../common/NLInput'
import { AuthContext } from './../../context/AuthContext'
import OnBoardBody from './OnBoardBody'

function Login() {
  const [email, setEmail] = useState('')
  const [pwd, setPwd] = useState('')
  const [willRemember, setWillRemember] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [loginError, setLoginError] = useState('')

  const auth = useContext(AuthContext)
  const navigate = useNavigate()

  function onLogin() {
    if (email.length === 0 || pwd.length === 0) {
      return
    }
    setIsLoading(true)
    const data = {
      username: email,
      password: pwd
    }
    http.post('/auth/login', data).then(response => {
      if (willRemember) {
        Cookies.set(UserAuthTokenKey, response.data.response.user.api_key, {
          expires: 365
        })
      } else {
        Cookies.set(UserAuthTokenKey, response.data.response.user.api_key)
      }
      auth.signIn(response.data.response.user, () => {
        setIsLoading(false)
        const requestedUrl = localStorage.getItem('requestedUrl')
        if (requestedUrl) {
          localStorage.removeItem('requestedUrl')
          const formattedUrl = requestedUrl.replace(/"/g, '')
          navigate(formattedUrl, { replace: true })
        } else navigate('/', { replace: true })
      })
    })
  }

  function handleEnter(event) {
    if (event.code === 'Enter') {
      onLogin()
    }
  }

  return (
    <div className='flex flex-col items-center gap-0  w-full'>
      <OnBoardBody title="Sign In">
      
        <div className="flex flex-col w-full space-y-4 md:w-2/3">
          <NLInput
            assistiveText="Username or e-mail"
            type="text"
            className="w-full"
            onChange={e => {
              setEmail(e.target.value)
            }}
            disabled={isLoading}
            onKeyPress={handleEnter}
          />
          <NLInput
            assistiveText="Password"
            type="password"
            className="w-full"
            disabled={isLoading}
            onChange={e => {
              setPwd(e.target.value)
            }}
            onKeyPress={handleEnter}
          />
          <div className="flex flex-row w-full text-xs items-center">
            <NLCheckBox
              type="square"
              isChecked={willRemember}
              isDisabled={isLoading}
              onClick={() => {
                setWillRemember(!willRemember)
              }}>
              Remember me
            </NLCheckBox>
            <div className="grow"></div>
            <Link to={'/forgot-password'} className="text-purple-lighter">
              Forgot password?
            </Link>
          </div>
          {loginError.length > 0 ? (
            <NLAlert type="error" className="text-xs">
              {loginError}
            </NLAlert>
          ) : (
            ''
          )}
          <NLButton
            title={isLoading ? 'LOADING...' : 'SIGN IN'}
            isDisabled={isLoading || email.length === 0 || pwd.length === 0}
            onClick={() => {
              onLogin()
            }}
          />
        </div>
        <div className="text-sm mt-6">
          Are you new here?{' '}
          <Link to={'/register'} className="text-purple-lighter">
            Create account
          </Link>
        </div>
      </OnBoardBody>
    </div>
  )
}

export default Login
