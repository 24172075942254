import clsx from 'clsx'
import React, { useContext, useEffect, useState } from 'react'
import {
  MdArrowRight,
  MdFormatAlignCenter,
  MdFormatAlignLeft,
  MdLockOpen,
  MdLockOutline,
  MdPlayCircle,
  MdRemoveCircleOutline
} from 'react-icons/md'
import { Link } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'
import { SpaceContext } from '../../context/SpaceContext'
import { showUserNotification } from '../../services/helpers'
import http from '../../services/http'
import NLLoader from '../common/NLLoader'
import Planet from '../svg/Planet'

function Spaces() {
  const [spaces, setSpaces] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const context = useContext(AuthContext)
  const { username } = context.user
  const spacesContext = useContext(SpaceContext)

  useEffect(() => {
    document.title = username + ' Spaces | HeyBrain'
    loadSpaces()
    spacesContext.clearAccessType()
  }, [])

  async function loadSpaces() {
    setIsLoading(true)

    const result = await http.get(
      'https://api.nlpgraph.com/stage/api/spaces/list'
    )
    if (result) {
      setSpaces(result.data.response)
    }
    setIsLoading(false)
  }

  async function removeSpace(slug, username) {
    if (!window.confirm('Are you sure you want to delete this item?')) return

    const result = await http.post(
      `https://api.nlpgraph.com/stage/api/space/remove?space_slug=${slug}&username=${username}`
    )

    if (!result.data.error) {
      showUserNotification({
        title: 'Space was removed successfully',
        type: 'success',
        dismissible: false
      })
      loadSpaces()
    } else {
      showUserNotification({
        title: 'Error occured while deleting space',
        type: 'error',
        dismissible: false
      })
    }
  }

  if (isLoading) {
    return <NLLoader size="3xl" elClassName="mx-auto" />
  }

  const SpaceItem = item => {
    const [isHovered, setIsHovered] = useState(false)

    const {
      id,
      slug,
      name,
      cover_image,
      created_at,
      access_type,
      items_count,
      display_now
    } = item.item

    return (
      <li
        key={'nl-space-' + id}
        className="min-w-[300px] p-3 w-full md:w-1/2 lg:w-1/4 overflow-hidden"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
        <div className="relative w-full h-[180px] md:h-[274px]">
          <div
            className={clsx(
              'absolute p-3 right-0 text-3xl text-red-600 cursor-pointer',
              { visible: isHovered, invisible: !isHovered }
            )}
            onClick={() => removeSpace(slug, username)}>
            <MdRemoveCircleOutline />
          </div>

          <Link to={`${slug}`}>
            <img
              className="rounded-3xl object-cover h-[180px] md:h-[274px] w-full min-w-full"
              src={cover_image}
              alt={name}
            />

            <div className="absolute h-28 md:h-40 py-3 px-6 shadow-md description rounded-3xl bottom-0 left-0 w-full bg-white">
              <div
                className="font-bold overflow-hidden break-words"
                style={{
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2
                }}>
                <h1 className="text-base font-semibold https://heybrain.ai/cloud text-center text-[#2B007B]">
                  {name}
                </h1>
              </div>

              <div className="text-xs tracking-wider mt-3 text-[#718096] text-center">
                {created_at}
              </div>

              <div className="flex absolute text-2xl text-[#718096] bottom-0 left-0 w-full p-3 justify-between items-center font-bold ">
                <div className=" font-semibold text-sm">
                  {items_count} items
                </div>
                <div className="flex ">
                  {display_now !== -1 ? <MdPlayCircle /> : null}
                  {access_type === 'public' ? (
                    <MdLockOpen />
                  ) : (
                    <MdLockOutline />
                  )}
                </div>
              </div>
            </div>
          </Link>
        </div>
      </li>
    )
  }

  function renderItems(arr) {
    const items = arr.map((item, index) => {
      return <SpaceItem key={'nl-space-' + index} item={item} />
    })

    return <ul className="flex flex-wrap">{items}</ul>
  }

  const content =
    spaces.length > 0 ? renderItems(spaces) : 'You do not have any spaces yet..'

  return (
    <>
      <div className="flex text-xs items-center">
        <a
          className="p-1.5 text-[#718096] cursor-pointer"
          href="https://heybrain.ai">
          Home
        </a>
        <MdArrowRight />
        <p className="p-1.5 text-[#2B007B]"> My Spaces </p>
      </div>

      <div className="w-full relative my-0 mx-auto ">
        <div className="flex items-center justify-end gap-2 mt-2 md:mt-7 flex-row ">
          <div className="absolute  flex w-full justify-center ">
            <Planet/>
          </div>

          <div className="bg-white rounded p-3 cursor-pointer text-[#718096] text-xl md:text-2xl border-1 border-slate-300 shadow-sm">
            <MdFormatAlignCenter />
          </div>

          <div className="bg-white rounded p-3 mr-3 bold cursor-pointer text-[#718096] text-xl md:text-2xl border-1 border-slate-300 shadow-sm">
            <MdFormatAlignLeft />
          </div>
        </div>

        <div className="mt-2 md:mt-7">{content}</div>
      </div>
    </>
  )
}

export default Spaces
