import clsx from 'clsx'
import { useContext, useEffect, useState } from 'react'
import { MdNotes, MdTextSnippet } from 'react-icons/md'
import Moment from 'react-moment'
import { SpaceContext } from '../../context/SpaceContext'
import { openDocumentOverlay, repoType } from '../../services/helpers'
import NLBoxFadeWithScale from './NLBoxFadeWithScale'

const deleteButtonStyle =
  'w-fit font-bold bg-white hover:bg-red-500 text-white py-1 px-2 cursor-pointer text-xs text-center rounded'

function NLItemDocumentBaseRectangle(props) {
  const [width, setWidth] = useState(null)
  const { spaceItemList } = useContext(SpaceContext)
  const isChecked = spaceItemList.some(obj => obj.__id === props.doc.__id)

  function handleWindowResize() {
    const windowWidth = window.innerWidth
    const cardWidth =
      windowWidth >= 1280
        ? (windowWidth - 222) / 4
        : windowWidth >= 1024
        ? (windowWidth - 222) / 3
        : windowWidth >= 640
        ? (windowWidth - 222) / 2
        : props.doc.ui.width
    setWidth(cardWidth)
  }

  useEffect(() => {
    handleWindowResize()
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  return (
    <div
      className={clsx(
        'flex flex-col box-border p-3 sm:p-6 my-2 sm:m-2 relative ',
        {
          'border-8 border-violet-600': props.isPresented,
          'border border-slate-200': !props.isPresented
        }
      )}
      style={{
        width,
        height: props.doc.ui.height,
        transition: 'all 0.5s',
        background: !(props.addSpaceMode && isChecked) ? 'white' : '#ECEFF4',
        borderRadius: '8px'
      }}>
      {props.children}
    </div>
  )
}

function NLItemDocumentGoogleDrive(props) {
  const doc = props.doc
  const repo = props.repo
  const isPresented = props.isPresented
  return (
    <NLItemDocumentBaseRectangle doc={doc} isPresented={isPresented}>
      <img
        src={repo.icon}
        alt=""
        className="absolute"
        width="24px"
        height="24px"
        style={{
          marginTop: '0px',
          marginLeft: parseInt(doc.ui.width) - 48 - 24 + 'px'
        }}
      />
      <div
        className="font-semibold text-sm my-2 overflow-hidden"
        style={{
          overflowWrap: 'anywhere'
        }}>
        <img
          src={doc.iconLink}
          alt=""
          width="16px"
          height="16px"
          className="inline mr-1 overflow-hidden"
        />
        {doc.__title || doc.title}
      </div>
      <div className="text-xs text-gray-400 mb-2">
        Date: <Moment format="DD.MM.YYYY">{doc.__timestamp}</Moment>
      </div>
      <div className="flex-grow bg-gray-50 rounded p-2 overflow-auto text-sm">
        {doc.__text}
      </div>
    </NLItemDocumentBaseRectangle>
  )
}

function NLItemDocumentWhatsApp(props) {
  const doc = props.doc
  const repo = props.repo
  const isPresented = props.isPresented
  const button =
    props.order !== undefined ? (
      <button className={deleteButtonStyle} onClick={props.onDelete}>
        X
      </button>
    ) : null
  return (
    <NLItemDocumentBaseRectangle doc={doc} isPresented={isPresented}>
      <div className="flex flex-row mb-2 items-center">
        <div
          className="font-semibold text-sm flex-grow"
          style={{
            overflowWrap: 'anywhere'
          }}>
          {doc.contact}
        </div>
        <img src={repo.icon} alt="" width="24px" height="24px" />
        {button}
      </div>
      <div className="text-xs text-gray-400 mb-2">
        Date: <Moment format="DD.MM.YYYY">{doc.__timestamp}</Moment>
      </div>
      <div className="flex-grow bg-gray-50 rounded p-2 overflow-auto text-sm">
        {doc.message}
      </div>
    </NLItemDocumentBaseRectangle>
  )
}

function NLItemDocumentTwitter(props) {
  const doc = props.doc
  const repo = props.repo
  const { isPresented, onDelete } = props
  const button = props.onDelete ? (
    <button className={deleteButtonStyle} onClick={props.onDelete}>
      X
    </button>
  ) : null
  return (
    <NLItemDocumentBaseRectangle
      doc={doc}
      isPresented={isPresented}
      onDelete={onDelete}>
      <div className="flex gap-3 flex-row mb-2 items-center">
        <img src={repo.icon} alt="" width="24px" height="24px" />
        <div
          className="font-semibold text-sm flex-grow"
          style={{
            overflowWrap: 'anywhere'
          }}>
          {doc.__repository}
        </div>
        {button}
      </div>
      <div className="flex-grow bg-gray-50 rounded p-2 overflow-auto text-sm">
        {doc.__text}
      </div>
      <div className="flex justify-between text-xs text-gray-400 mt-4">
        <p>twitter.com</p>
        <Moment format="D MMM YYYY">{doc.__timestamp}</Moment>
      </div>
    </NLItemDocumentBaseRectangle>
  )
}

function NLItemDocumentZoom(props) {
  const doc = props.doc
  const repo = props.repo
  const { isPresented, onDelete } = props
  const videoURL = repo.video_url ? repo.video_url : ''
  const button = props.onDelete ? (
    <button className={deleteButtonStyle} onClick={props.onDelete}>
      X
    </button>
  ) : null
  return (
    <NLItemDocumentBaseRectangle
      doc={doc}
      isPresented={isPresented}
      onDelete={onDelete}>
      <div className="">
        <video
          width="100%"
          controls
          src={videoURL}
          style={{
            height: parseInt(doc.ui.height) / 2 - 20 + 'px',
            objectFit: 'cover',
            borderRadius: '7px'
          }}
        />
      </div>
      <div className="flex flex-row my-2 items-center">
        <div
          className="font-semibold text-sm flex-grow"
          style={{
            overflowWrap: 'anywhere'
          }}>
          {repo.display_name}
        </div>
        <img src={repo.icon} alt="" width="24px" height="24px" />
        {button}
      </div>
      <div className="text-xs text-gray-400 mb-2">
        Date: <Moment format="DD.MM.YYYY">{doc.__timestamp}</Moment>
      </div>
    </NLItemDocumentBaseRectangle>
  )
}

function NLItemDocumentWeb({ onClickTag = () => {}, ...props }) {
  const doc = props.doc
  if (typeof doc.tags === 'string') {
    doc.tags = doc.tags.split(',')
  }

  const repo = props.repo
  const { isPresented, onDelete, addSpaceMode } = props

  let favicon = repo.icon
  if (doc.favicon_url && doc.favicon_url.length > 0) {
    favicon = doc.favicon_url
  } else {
    favicon =
      'https://www.google.com/s2/favicons?domain=' + doc.domain + '&sz=16'
  }

  const title =
    doc.title.length > 75 ? doc.title.substring(0, 75) + '...' : doc.title

  const button =
    props.onDelete !== undefined ? (
      <button className={deleteButtonStyle} onClick={props.onDelete}>
        X
      </button>
    ) : null

  const readableURL = doc.url
    .replace(/(^\w+:|^)\/\//, '')
    .replace('www.', '')
    .split('/')[0]

  // const text =
  //   doc.notes && doc.notes.length > 0
  //     ? doc.notes
  //     : doc.description > 0
  //     ? doc.description
  //     : doc.__text ? doc.__text
  const text = doc.notes || doc.description || doc.__text || ''

  // if (text.length === 0) {
  //   text = doc.__text
  // }
  const textFormatted = text.length > 155 ? text.slice(0, 155) + '...' : text

  return (
    <NLItemDocumentBaseRectangle
      doc={doc}
      isPresented={isPresented}
      onDelete={onDelete}
      addSpaceMode={addSpaceMode}>
      <div className="w-full flex flex-row mb-2 items-start gap-2">
        <img src={favicon} alt="" width="24px" height="24px" />
        <div
          className="w-full overflow-hidden sm:overflow-auto font-semibold text-sm flex-grow "
          style={{
            overflowWrap: 'anywhere'
          }}>
          <p className=" truncate text-overflow-ellipsis sm:break-words sm:whitespace-normal	">
            {title}
          </p>
        </div>
        {button}
      </div>
      {doc.screenshot_url && doc.screenshot_url.length > 0 && (
        <div className="hidden sm:flex flex-grow mb-2 items-center my-2 overflow-hidden">
          <img
            className="w-full object-contain rounded-md"
            src={doc.screenshot_url}
            alt="screenshot"
          />
        </div>
      )}
      {!doc.screenshot_url && (
        <div className="hidden sm:flex flex-grow items-center overflow-hidden">
          <img
            className="w-full h-5/6 object-contain rounded-md blur-sm"
            src={favicon}
            alt="screenshot"
            width="100%"
          />
        </div>
      )}
      <div className="flex flex-col xs:h-full sm:h-auto justify-between sm:justify-start gap-2">
        <div
          className="flex flex-grow items-start gap-1 overflow-hidden"
          style={{ maxHeight: '80px' }}>
          {doc.notes ? (
            <MdTextSnippet className="text-[#E4E4E4] w-4 h-4 sm:w-5 sm:h-5 min-w-fit max-w-fit" />
          ) : (
            <MdNotes className="text-[#E4E4E4] w-4 h-4 sm:w-5 sm:h-5 min-w-fit max-w-fit" />
          )}
          <div className=" text-sm">{textFormatted}</div>
        </div>

        <div className="flex flex-row text-xs text-gray-400">
          <div className="flex items-center w-full  overflow-hidden">
            <img
              src={repo.icon}
              alt=""
              className="mr-1 w-4 h-4 sm:w-5 sm:h-5"
            />
            <p className="truncate">{readableURL}</p>
          </div>
          <Moment className="w-40 text-right" format="MMM DD, YYYY">
            {doc.__timestamp}
          </Moment>
        </div>
        {/* show tags if exists, scrollable */}
        {doc.tags && doc.tags.length > 0 && (
          <div
            className="flex flex-row text-xs text-gray-400 mt-2 overflow-x-auto"
            style={{ maxWidth: '100%' }}>
            <div className="flex flex-row gap-1">
              {doc.tags.map((tag, index) => (
                <span
                  onClick={() => onClickTag(tag)}
                  key={index}
                  className="truncate bg-green-500 text-white px-2 rounded-lg mr-1">
                  {tag}
                </span>
              ))}
            </div>
          </div>
        )}
      </div>
    </NLItemDocumentBaseRectangle>
  )
}

function NLItemDocumentOther(props) {
  const doc = props.doc
  const repo = props.repo
  const title = doc.__title || doc.title

  const { isPresented, onDelete } = props
  const button =
    props.onDelete !== undefined ? (
      <button className={deleteButtonStyle} onClick={props.onDelete}>
        X
      </button>
    ) : null
  return (
    <NLItemDocumentBaseRectangle
      doc={doc}
      isPresented={isPresented}
      onDelete={onDelete}>
      <div className="flex flex-row mb-2 items-center">
        <div
          className="font-semibold text-sm flex-grow"
          style={{
            overflowWrap: 'anywhere'
          }}>
          {repo.name}
        </div>
        <img src={repo.icon} alt="" width="24px" height="24px" />
        {button}
      </div>
      <div className="text-xs text-gray-400 mb-2">
        Date: <Moment format="DD.MM.YYYY">{doc.__timestamp}</Moment>
      </div>
      <div className="flex-grow bg-gray-50 rounded p-2 overflow-auto text-sm">
        {doc.__text}
      </div>
    </NLItemDocumentBaseRectangle>
  )
}

function NLItemDocument(props) {
  const { doc, slug, isPresented, addSpaceMode } = props // 25.06 isPresented to check if item is presented then hover it
  const repo = doc.__repo_type ? repoType(doc.__repo_type) : repoType('other')
  const { spaceItemList, addList } = useContext(SpaceContext)
  // console.log(doc.__id)
  if (!doc.ui) {
    doc.ui = {
      width: '20em',
      height: window.innerWidth < 640 ? '180px' : '376px'
    }
  }

  return (
    <NLBoxFadeWithScale
      delay={props.delay ? props.delay : 0}
      isPresented={isPresented} // 25.06 to check if item is presented then hover it
      onClick={() => {
        if (addSpaceMode) {
          const index = spaceItemList.findIndex(item => item.__id === doc.__id)
          if (index !== -1) return

          addList(doc)
          return
        }
        if (props.onClick) {
          props.onClick(doc, slug)
        } else {
          openDocumentOverlay(doc, slug)
        }
      }}>
      {repo.id === 'gdrive' && (
        <NLItemDocumentGoogleDrive
          repo={repo}
          doc={doc}
          isPresented={isPresented}
        />
      )}
      {repo.id === 'whatsapp' && (
        <NLItemDocumentWhatsApp
          repo={repo}
          doc={doc}
          onDelete={props.onDelete}
          isPresented={isPresented}
        />
      )}
      {repo.id === 'twitter' && (
        <NLItemDocumentTwitter
          repo={repo}
          doc={doc}
          onDelete={props.onDelete}
          isPresented={isPresented}
        />
      )}
      {repo.id === 'zoom' && (
        <NLItemDocumentZoom
          repo={repo}
          doc={doc}
          onDelete={props.onDelete}
          isPresented={isPresented}
        />
      )}
      {repo.id === 'other' && (
        <NLItemDocumentOther
          repo={repo}
          doc={doc}
          onDelete={props.onDelete}
          isPresented={isPresented}
          {...props}
        />
      )}
      {repo.id === 'web' && (
        <NLItemDocumentWeb
          repo={repo}
          doc={doc}
          onDelete={props.onDelete}
          isPresented={isPresented}
          addSpaceMode={addSpaceMode}
        />
      )}
    </NLBoxFadeWithScale>
  )
}

export default NLItemDocument
