import React from 'react'
import classNames from 'classnames'

function NLSwitch({ isSelected, className: customClasses = '', ...props }) {
  const className = classNames(
    'flex p-2 rounded shadow-sm justify-center items-center cursor-pointer',
    customClasses,
    {
      'bg-white hover:bg-gray-100 text-gray-800 ': !isSelected,
      'bg-purple-700 hover:bg-purple-800 text-white': isSelected
    }
  )
  return (
    <div
      className={className}
      onClick={() => {
        props.onClick()
      }}>
      {props.children}
    </div>
  )
}

export default NLSwitch
