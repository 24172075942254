import classNames from 'classnames'
import { useContext } from 'react'
import { MdClose, MdLogout } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { NavigationContext } from '../../context/NavigationContext'

function Sidebar({ auth }) {
  const nav = useContext(NavigationContext)
  const sidebarOpened = nav.sidebarOpened

  return (
    <div
      className={`xs:absolute w-14 md:w-24 left-0 md:static z-50 flex md:flex flex-col space-y-4 h-full border-r border-slate-100 shadow-md bg-white transition-all duration-1000 ease-in-out overflow-hidden
      ${sidebarOpened ? 'left-0' : '-left-full'}`}
      id="app-sidebar">
      <Link to="/" className="mx-auto xs:hidden md:block">
        <img
          src="/assets/logo.svg"
          alt="NLP Graph"
          className="self-center mt-3 w-14"
        />
      </Link>
      <MdClose
        className="xs:block md:hidden text-2xl font-bold my-2 w-full items-center flex justify-center cursor-pointer"
        onClick={() => {
          nav.handleSideBar(sidebarOpened)
        }}
      />

      <div className="flex flex-col grow">
        {nav.sidebarList.map((item, index) => {
          return (
            <Link to={item.route} key={index}>
              <div
                onClick={() => {
                  nav.handleSideBar(sidebarOpened)
                }}
                className={classNames(
                  'text-2xl my-2 w-full h-12 items-center flex justify-center border-l-2 hover:bg-gray-100',
                  {
                    'text-purple border-purple': nav.current.id === item.id,
                    'border-white': nav.current.id !== item.id
                  }
                )}>
                {item.icon}
              </div>
            </Link>
          )
        })}
        <div
          className={classNames(
            'text-2xl my-2 w-full h-12 items-center flex justify-center border-l-2 hover:bg-gray-100 border-white cursor-pointer'
          )}
          onClick={() => {
            auth.signOut()
          }}>
          <MdLogout />
        </div>
      </div>
    </div>
  )
}

export default Sidebar
