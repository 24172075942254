import Login from './Login'
import './OnBoard.css'
import { useLocation, Link } from 'react-router-dom'
import Register from './Register'
import ForgotPassword from './ForgotPassword'

function OnBoard() {
  const location = useLocation()
  return (
    <div className="grid md:grid-cols-2 onboard-container h-screen">
      <div
        className="hidden md:flex items-center justify-center relative "
        style={{
          background:
            'radial-gradient(83.2% 83.2% at 50% 50%, #FFFFFF 2.71%, #8962F4 56.09%, #6416F3 100%)',
          boxShadow: '6px 0px 28px rgba(43, 12, 121, 0.05)',
          borderRadius: '0px',
          zIndex: 1001,
          overflow: 'hidden'
        }}>
        <img
          src="assets/onboarding-vector-group-1.svg"
          className="absolute delay-1000 h-screen rotating"
          alt=""
          style={{
            animationDelay: '500ms'
          }}
        />
        <img
          src="assets/onboarding-vector-group-2.svg"
          className="absolute delay-500 h-screen rotating-md"
          alt=""
          style={{
            animationDelay: '1500ms'
          }}
        />
        <img
          src="assets/onboarding-vector-group-3.svg"
          className="absolute h-screen rotating"
          alt=""
        />
        <Link to={'/'} className="cursor-pointer z-50">
          <img src="assets/logo.svg" height="58px" width="67px" alt="" />
        </Link>
      </div>
      <div
        style={{
          zIndex: 1000
        }}
        className="flex px-4 items-center justify-center w-full">
        {location.pathname === '/login' && <Login />}
        {location.pathname === '/register' && <Register />}
        {location.pathname === '/forgot-password' && <ForgotPassword />}
      </div>
    </div>
  )
}

export default OnBoard
