import clsx from 'clsx'
import { useContext, useEffect, useState } from 'react'
import { MdCreate, MdDoneOutline } from 'react-icons/md'
import { useLocation } from 'react-router-dom'
import { useAuthContext } from '../../context/AuthContext'
import { SpaceContext } from '../../context/SpaceContext'
import {
  openDocumentOverlay,
  showUserNotification
} from '../../services/helpers'
import http from '../../services/http'
import NLButton from '../common/NLButton'
import NLItemDocument from '../common/NLItemDocument'
import NLLoader from '../common/NLLoader'
import './spaceItem.css'

function SpaceItem() {
  const [isLoading, setIsLoading] = useState(false)
  const [spaceItem, setSpaceItem] = useState([])
  const [isEditable, setIsEditable] = useState(false)
  const [name, setName] = useState('')
  const [error, setError] = useState('')
  const [sharedUsers, setSharedUsers] = useState([])
  const location = useLocation()
  const username = location.pathname.split('/')[1]
  const slug = location.pathname.split('/').pop()
  const context = useContext(SpaceContext)
  const {
    clearAccessType,
    checkAccessType,
    onPresented,
    clearPresented,
    clearSpace,
    onCheckSpace,
    presented,
    space,
    onCheckItems,
    items,
  } = context
  const [access_type, setAccess_type] = useState('')

  useEffect(() => {
    if (name.length > 0)
      document.title =
        name.slice(0, 70) + ' - ' + username + ' Space | HeyBrain'
  }, [name, username])

  useEffect(() => {
    clearAccessType()
    clearPresented()
    clearSpace()
    loadSpaceBySlug(slug, username)
  }, [])

  async function loadSpaceBySlug(slug, username) {
    setIsLoading(true)
    const result = await http.get(
      `https://api.nlpgraph.com/stage/api/space/get?username=${username}&space_slug=${slug}`
    )
    if (result && !result.data.error) {
      const { items, space } = result.data.response
      const { access_type, display_now, name, shared_users } = space
      onCheckSpace(space)
      setAccess_type(access_type)
      setSharedUsers(shared_users)
      onPresented(display_now)
      setName(name)
      setSpaceItem(items)
      onCheckItems(items)
      checkAccessType(access_type, shared_users)
    } else {
      setError(result.data.error)
    }
    setIsLoading(false)
  }

  const Background = ({
    slug,
    name,
    access_type,
    description,
    sharedUsers
  }) => {
    const [slugText, setSlugText] = useState(name)
    const [isHovered, setIsHovered] = useState(false)
    const [idPresented, setIdPresented] = useState(null)
    const list = items
    const presentedItem = getObjectById(spaceItem, presented)
    const context = useContext(SpaceContext)
    const { hasOwnerAccess } = context
    const { user } = useAuthContext()
    const isOwner = hasOwnerAccess(user.username, sharedUsers)

    function findElementById(array, id) {
      return array.find(item => item.__id === id)
    }

    function getObjectById(array, id) {
      return array.find(obj => obj.__id === id)
    }

    useEffect(() => {
      const selectedItem = findElementById(spaceItem, presented)
      if (selectedItem) {
        setIdPresented(selectedItem.text)
      }
    }, [])

    const PresentingNow = () => {
      const [isVisible, setIsVisible] = useState(true)

      useEffect(() => {
        const interval = setInterval(() => {
          setIsVisible(prevVisible => !prevVisible)
        }, 700)
        return () => {
          clearInterval(interval)
        }
      }, [])

      return (
        <div className={isVisible && idPresented ? 'visible' : 'invisible'}>
          {' '}
          Presenting now:{' '}
        </div>
      )
    }

    const coverImage = space?.cover_image
    const containerStyle = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundImage: `url(${coverImage})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      width: '100%',
      margin: '0 auto'
    }

    const onSaveNewSpaceName = newName => {
      if (newName && newName.length > 0 && newName !== name) {
        http
          .post(
            `https://api.nlpgraph.com/stage/api/space/update?space_slug=${slug}&username=${username}`,
            {
              access_type,
              name: newName,
              space_slug: slug
            }
          )
          .then(response => {
            if (!response.data.error) {
              setName(newName)
              setIsEditable(!isEditable)
              showUserNotification({
                title: 'The name of space was changed',
                type: 'success',
                dismissible: false
              })
            } else {
              showUserNotification({
                title: 'Something went wrong...',
                type: 'error',
                dismissible: false
              })
            }
          })
      }
    }

    const handleMouseEnter = () => {
      setIsHovered(true)
    }

    const handleMouseLeave = () => {
      setIsHovered(false)
    }

    const getInputWidth = () => {
      const maxWidth = 570 // max width in pixels
      const charWidth = 9 // approximate width of 1 character in pixels
      const inputWidth = Math.min(
        (slugText.length + 2 * charWidth) * charWidth,
        maxWidth
      )
      return inputWidth
    }

    return (
      <div className="bg-center" style={containerStyle}>
        <div
          className="pt-5 relative container flex flex-col justify-end gap-8 items-center bg-white max-w-[260px] md:max-w-[60%] md:w-3/5  rounded-t-3xl mt-6 overflow-hidden"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
          <div className="flex flex-col  justify-between mx-12 gap-1 items-center">
            <div className="flex flex-wrap w-1/3 md:w-full justify-center align-center gap-2">
              <input
                type="text"
                onChange={e => {
                  setSlugText(e.target.value)
                }}
                value={slugText}
                className="relative font-semibold text-ellipsis text-center text-black bold bg-white rounded-full  m-auto"
                placeholder="name of the space"
                disabled={!isEditable}
                autoFocus
                style={{ width: `${getInputWidth()}px` }}
              />
              <NLButton
                className={clsx('bg-green-300', {
                  block: isEditable,
                  hidden: !isEditable
                })}
                onClick={() => {
                  onSaveNewSpaceName(slugText)
                }}>
                <MdDoneOutline />
              </NLButton>
              {isOwner && (
                <button
                  className="absolute  top-2 right-2 text-sm ease-in duration-200"
                  onClick={() => {
                    setIsEditable(!isEditable)
                  }}
                  onMouseEnter={() => {}}>
                  <MdCreate
                    className={clsx({
                      'text-gray-400': isHovered,
                      'text-white': !isHovered,
                      'text-violet-600': isEditable
                    })}
                  />
                </button>
              )}
            </div>
            <div className="overflow-hidden w-1/3 md:w-full">
              <p className="truncate text-overflow-ellipsis text-xs text-center text-gray-600 bold">{`@${slug}`}</p>
            </div>
            <p className="text-description text-sm text-center text-gray-400 ">
              {description || 'There is no description yet'}
            </p>
          </div>
          <div className=" text-sm font-medium text-center py-1  w-full">
            <div
              className={clsx(
                'flex',
                'justify-center',
                'text-violet-700',
                'bg-violet-100',
                'w-full',
                'overflow-hidden',
                'whitespace-nowrap',
                {
                  invisible: !idPresented,
                  visible: idPresented
                }
              )}>
              <div
                className="flex justify-center gap-1 overflow-hidden truncate text-overflow-ellipsis w-3/4 cursor-pointer"
                onClick={() => {
                  openDocumentOverlay(presentedItem, list, slug)
                }}>
                <PresentingNow />
                <span className="text-black truncate text-overflow-ellipsis">
                  {idPresented}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } // end of Background component

  function onDeleteItem(item) {
    return function handleEvent(e) {
      e.stopPropagation()

      http
        .post(
          `https://api.nlpgraph.com/stage/api/space/remove_items?space_slug=${slug}&username=${item.__username}&repositories=${item.__repository}`,
          {
            items: [
              {
                repository: item.__repository,
                username: item.__username,
                id: item.__id
              }
            ]
          }
        )
        .then(response => {
          showUserNotification({
            title: 'Space item was removed successfully',
            type: 'success',
            dismissible: false
          })
          loadSpaceBySlug(slug, item.__username)
        })
    }
  }

  function renderItems(arr) {
    const items = arr.map((item, index) => {
      const isPresented = item.__id === presented // true or false
      const list = context.items
      return (
        <NLItemDocument
          className="relative"
          key={'nl-item-space-' + index}
          slug={slug}
          isPresented={isPresented} // 25.06 to check if item is presented then hover it
          onDelete={onDeleteItem(item)}
          doc={item}
          onClick={() => {
            openDocumentOverlay(item, list, slug)
          }}
        />
      )
    })
    return <ul className="flex flex-wrap w-fit">{items}</ul>
  }

  if (isLoading) {
    return <NLLoader size="3xl" elClassName="mx-auto" />
  }

  const description = space?.description

  const content =
    spaceItem.length > 0
      ? renderItems(spaceItem)
      : error || 'This space is empty..'
  return (
    <div className="flex flex-col  items-left gap-10px w-full mx-auto">
      <Background
        slug={slug}
        name={name}
        access_type={access_type}
        description={description}
        sharedUsers={sharedUsers}
      />
      {content}
    </div>
  )
}

export default SpaceItem
