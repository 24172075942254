import { useContext, useEffect, useState } from 'react'
import { useOutlet } from 'react-router-dom'
import NLItemDocumentOverlay from './components/common/NLItemDocumentOverlay'
import Header from './components/layout/Header'
import Sidebar from './components/layout/Sidebar'
import ConversationOverlay from './components/onboarding/ConversationOverlay'
import UserOverlay from './components/onboarding/UserOverlay'
import SpaceItemOverlay from './components/route/SpaceItemOverlay'
import { AuthContext } from './context/AuthContext'
import { NavigationProvider } from './context/NavigationContext'

function App() {
  const auth = useContext(AuthContext)
  const outlet = useOutlet()
  const isMobile = window.innerWidth < 640
  const [showingDocument, setShowingDocument] = useState(null)
  const [showingSpaceOverlay, setShowingSpaceOverlay] = useState(null)
  const [showingConversationOverlay, setShowingConversationOverlay] =
    useState(null)
  const [showingUserOverlay, setShowingUserOverlay] = useState(null)
  const [documentList, setDocumentList] = useState([])
  const [slug, setSlug] = useState(null)
  const [conversation, setConversation] = useState(null)
  const [user, setUser] = useState(null)

  const handleDocumentOverlay = e => {
    setSlug(e.detail.slug) // 13.06 set slug after document overlay open
    setShowingDocument(e.detail.document)
    setDocumentList(e.detail.list)
  }

  const handleSpaceOverlay = e => {
    // 29.06
    setShowingSpaceOverlay(e.detail.slug)
  }

  const handleConversationOverlay = e => {
    // 18.07
    setShowingConversationOverlay(e.detail.conversation)
    setConversation(e.detail.conversation)
  }

  const handleUserOverlay = e => {
    setShowingUserOverlay(e.detail.user)
    setUser(e.detail.user)
  }

  useEffect(() => {
    async function fetchRepoTypes() {
      let result = await fetch('/repo_types.json', {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      })
      result = await result.json()
      const d = []
      result.list.forEach(element => {
        element.icon = '/assets/repo-icon-' + element.id + '.svg'
        d.push(element)
      })
      localStorage.setItem('repo_types', JSON.stringify(d))
    }
    fetchRepoTypes()
    document.addEventListener('show-document-overlay', handleDocumentOverlay)
    document.addEventListener('show-space-overlay', handleSpaceOverlay)
    document.addEventListener(
      'show-conversation-overlay',
      handleConversationOverlay
    )
    document.addEventListener('show-user-overlay', handleUserOverlay)
    return () => {
      document.removeEventListener(
        'show-document-overlay',
        handleDocumentOverlay
      )
      document.removeEventListener('show-space-overlay', handleSpaceOverlay)
      document.removeEventListener('show-user-overlay', handleUserOverlay)
      document.removeEventListener(
        'show-conversation-overlay',
        handleConversationOverlay
      )
      setShowingSpaceOverlay(null)
      setShowingConversationOverlay(null)
      setShowingUserOverlay(null)
      setConversation(null)
      setUser(null)
      setShowingDocument(null)
      setDocumentList([])
    }
  }, [])

  return (
    <NavigationProvider>
      {showingDocument && (
        <NLItemDocumentOverlay
          doc={showingDocument}
          list={documentList}
          slug={slug}
          isMobile={isMobile}
        />
      )}
      {showingSpaceOverlay && <SpaceItemOverlay />}
      {showingConversationOverlay && (
        <ConversationOverlay conversation={conversation} />
      )}
      {showingUserOverlay && <UserOverlay user={user} />}
      <div className="flex h-screen bg-gray-50">
        <Sidebar auth={auth} />
        <div className="flex flex-col w-full">
          <Header auth={auth} />
          <div className="grow p-6 overflow-auto" id="app-content">
            {outlet}
          </div>
        </div>
      </div>
    </NavigationProvider>
  )
}

export default App
