import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { RiCheckboxBlankCircleLine, RiCheckboxCircleFill } from 'react-icons/ri'

function NLDropDown({
  list = [],
  activeIndex = -1,
  dismissOnSelection = false,
  className: customClasses = '',
  popPosition = 'left-to-right',
  ...props
}) {
  const [isOpened, setIsOpened] = useState(false)
  const containerRef = useRef(null)
  const popRef = useRef(null)

  const className = classNames(
    'flex bg-white p-2 rounded shadow-sm text-gray-800 justify-center items-center',
    customClasses,
    {
      'hover:bg-gray-100 cursor-pointer': !isOpened,
      'bg-gray-100 cursor-default': isOpened
    }
  )

  useEffect(() => {
    function handleOutsideClick(event) {
      if (isOpened && !containerRef.current.contains(event.target)) {
        setIsOpened(false)
      }
    }
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [isOpened, containerRef])

  const popStyle = {
    top: containerRef.current
      ? containerRef.current.clientHeight + 4 + 'px'
      : '4px',
    zIndex: 10009
  }
  if (popPosition === 'right-to-left') {
    popStyle.right = '0px'
  } else {
    popStyle.left = '0px'
  }

  return (
    <div className="relative" ref={containerRef}>
      <div
        className={className}
        onClick={() => {
          setIsOpened(!isOpened)
        }}>
        {props.children}
      </div>
      {isOpened && (
        <div
          className="absolute bg-white rounded shadow-md flex flex-col"
          ref={popRef}
          style={popStyle}>
          {list &&
            list.map((item, index) => {
              return (
                <div
                  className="hover:bg-purple-100 cursor-pointer px-4 py-2 flex flex-row items-center"
                  key={index}
                  style={{
                    minWidth: '225px',
                    zIndex: '9999'
                  }}
                  onClick={() => {
                    if (dismissOnSelection) {
                      setTimeout(() => {
                        setIsOpened(false)
                      }, 100)
                    }
                    props.didSelect(index)
                  }}>
                  {activeIndex > -1 && activeIndex === index && (
                    <RiCheckboxCircleFill className="text-lg text-purple-600 -mt-0.5 mr-2" />
                  )}
                  {activeIndex > -1 && activeIndex !== index && (
                    <RiCheckboxBlankCircleLine className="text-lg text-purple-600 -mt-0.5 mr-2" />
                  )}
                  {item}
                </div>
              )
            })}
        </div>
      )}
    </div>
  )
}

export default NLDropDown
