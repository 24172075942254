import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import http from '../../services/http'
import NLLoader from '../common/NLLoader'
import { openConversationOverlay } from '../../services/helpers'
import NLButton from '../common/NLButton'

const Conversations = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [conversations, setConversations] = useState([])

  useEffect(() => {
    document.title = 'Conversations | HeyBrain'
    loadTelegram()
  }, [])

  async function loadTelegram() {
    setIsLoading(true)
    const result = await http.get('/admin/telegram/conversations')
    if (result) {
      setConversations(result.data.response)
    }
    setIsLoading(false)
  }

  const Conversation = ({ conversation, index }) => {
    const {
      heybrain_user_id,
      heybrain_username,
      org_query,
      param_repo,
      param_search,
      param_time,
      session_date
    } = conversation

    return (
      <>
        <tr
          key={index}
          className={
            'border-b transition duration-300 ease-in-out hover:bg-neutral-100 cursor-pointer'
          }
          onClick={() => {
            openConversationOverlay(conversation)
          }}>
          <td className="whitespace-nowrap px-6 py-4">{session_date}</td>
          <td className="whitespace-nowrap px-6 py-4">{heybrain_username}</td>
          <td className="whitespace-nowrap px-6 py-4">{heybrain_user_id}</td>
          <td className="whitespace-nowrap px-6 py-4">{org_query}</td>
          <td className="whitespace-nowrap px-6 py-4">{param_repo}</td>
          <td className="whitespace-nowrap px-6 py-4">{param_search}</td>
          <td className="whitespace-nowrap px-6 py-4">{param_time}</td>
        </tr>
      </>
    )
  } // end of Conversation

  function renderConversationTable(arr) {
    const conversations = arr.map((conversation, index) => {
      return <Conversation conversation={conversation} key={'nl-cv-' + index} />
    })

    return (
      <section className="w-full p-4 ">
        <div className="flex px-6 gap-3 ">
          <NLButton
            onClick={() => {
              navigate('/admin')
            }}>
            Users
          </NLButton>
        </div>
        <table className="min-w-full text-left text-sm font-light">
          <thead className="border-b font-medium dark:border-neutral-500">
            <tr>
              <th scope="col" className="px-6 py-4">
                session_date
              </th>
              <th scope="col" className="px-6 py-4">
                username
              </th>
              <th scope="col" className="px-6 py-4">
                user_id
              </th>
              <th scope="col" className="px-6 py-4">
                org_query
              </th>
              <th scope="col" className="px-6 py-4">
                param_repo
              </th>
              <th scope="col" className="px-6 py-4">
                param_search
              </th>
              <th scope="col" className="px-6 py-4">
                param_time
              </th>
            </tr>
          </thead>
          <tbody>{conversations}</tbody>
        </table>
      </section>
    )
  }

  const content = isLoading ? (
    <NLLoader size="3xl" elClassName="mx-auto" />
  ) : (
    renderConversationTable(conversations)
  )

  return <> {content} </>
}

export default Conversations
