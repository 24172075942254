import { useState } from 'react'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import NLButton from '../../common/NLButton'
import NLInput from '../../common/NLInput'
import { MdContentCopy } from 'react-icons/md'
import clsx from 'clsx'

function ChangePassword(props) {
  const { api_key } = props.profile
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [showOldPassword, setShowOldPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showRepeatPassword, setShowRepeatPassword] = useState(false)
  const [copied, setCopied] = useState(false)

  const toggleOldPasswordVisibility = () => {
    setShowOldPassword(prevShowOldPassword => !prevShowOldPassword)
  }

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(prevShowNewPassword => !prevShowNewPassword)
  }

  const toggleRepeatPasswordVisibility = () => {
    setShowRepeatPassword(prevShowRepeatPassword => !prevShowRepeatPassword)
  }

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col bg-white gap-10 p-6">
        <div className="flex flex-col gap-6 rounded bg-white  w-full">
          <h1 className="font-semibold">Change Password</h1>

          <div className="flex flex-col gap-1">
            <label
              htmlFor="grid-text-1"
              className="uppercase text-gray-700 text-xs font-bold">
              old password
            </label>

            <div className="flex relative">
              <NLInput
                type={showOldPassword ? 'text' : 'password'}
                value={oldPassword}
                onChange={e => setOldPassword(e.target.value)}
                className="w-full"
              />
              {oldPassword && (
                <div
                  onClick={toggleOldPasswordVisibility}
                  className="absolute top-3 right-0 flex text-[#E4E4E4] items-center mr-4 outline-none focus:outline-none cursor-pointer">
                  {showOldPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-col gap-1">
            <label
              htmlFor="grid-text-1"
              className="uppercase text-gray-700 text-xs font-bold">
              new password
            </label>

            <div className="flex relative">
              <NLInput
                type={showNewPassword ? 'text' : 'password'}
                value={newPassword}
                onChange={e => setNewPassword(e.target.value)}
                className="w-full"
              />
              {newPassword && (
                <div
                  onClick={toggleNewPasswordVisibility}
                  className="absolute top-3 right-0 flex items-center text-[#E4E4E4] mr-4 outline-none focus:outline-none cursor-pointer">
                  {showNewPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-col gap-1">
            <label
              htmlFor="grid-text-1"
              className="uppercase text-gray-700 text-xs font-bold">
              repeat new password
            </label>

            <div className="flex relative">
              <NLInput
                type={showRepeatPassword ? 'text' : 'password'}
                value={repeatPassword}
                onChange={e => setRepeatPassword(e.target.value)}
                className="w-full"
              />
              {repeatPassword && (
                <div
                  onClick={toggleRepeatPasswordVisibility}
                  className="absolute top-3 right-0 text-[#E4E4E4] flex items-center mr-4 outline-none focus:outline-none cursor-pointer">
                  {showRepeatPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                </div>
              )}
            </div>
          </div>
        </div>

        <NLButton className="uppercase max-w-fit">set new password</NLButton>

        <p className="font-semibold text-[#6416F3]">Forgot Password?</p>
      </div>

      <div className="flex flex-col gap-6">
        <div className="p-6  bg-white">
          <div className="flex flex-col  gap-6">
            <h1 className="font-semibold header">Developer Tools</h1>

            <div className="flex flex-col rounded w-full gap-1">
              <label
                htmlFor="grid-text-1"
                className="uppercase text-gray-700 text-xs font-bold">
                api key
              </label>

              <div className="flex relative gap-6 w-full px-4 py-2 rounded-md border border-gray-200 focus:border-gray-400 focus:outline-none text-base font-normal">
                <p>{api_key}</p>

                <div className="flex justify-end w-full ">
                  <MdContentCopy
                    className="text-[#D9D9D9]  text-2xl cursor-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText(api_key)
                      setCopied(true)
                      const timer = setTimeout(() => {
                        setCopied(false)
                        clearTimeout(timer)
                      }, 1000)
                    }}
                  />
                </div>

                <p
                  className={clsx(
                    'absolute text-green-700 -top-8 right-0  px-2',
                    {
                      visible: copied,
                      invisible: !copied
                    }
                  )}>
                  copied to clipboard
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col bg-white gap-6 p-6 ">
          <h1 className="font-semibold header">Sign In Settings?</h1>

          <NLButton
            className="uppercase header text-[#FF110A] max-w-fit"
            style={{ backgroundColor: 'white', border: '1px solid red' }}>
            log out
          </NLButton>
        </div>
      </div>
    </div>
  )
}

export default ChangePassword
