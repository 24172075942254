import React, { useEffect, useState } from 'react'
import { HiOutlineCursorClick, HiOutlineRefresh } from 'react-icons/hi'
import NLButton from '../../common/NLButton'
import { MdOutlineCancel } from 'react-icons/md'
import { ReactSVG } from 'react-svg'
import classNames from 'classnames'

function RepositoryItem(props) {
  const [inUseAction, setInUseAction] = useState(false)

  useEffect(() => {
    setInUseAction(props.isParentInUseAction)
  }, [props.isParentInUseAction])

  const onToggleUse = () => {
    if (inUseAction) return
    setInUseAction(true)
    props.auth.toggleRepository(props.item.name, () => {
      setInUseAction(false)
    })
  }

  const isInUse = props.auth.user.repositories.indexOf(props.item.name) > -1

  const stateCss = classNames('uppercase font-semibold mx-20', {
    'text-green-600': props.item.state === 'PUBLISHED',
    'text-blue-600': props.item.state === 'PUBLISHING',
    'text-orange-600': props.item.state === 'UNPUBLISHED'
  })
  return (
    <div className="bg-purple-50 border-b border-purple-100 px-4 py-8 flex flex-row items-center text-sm">
      <ReactSVG
        src={props.repo.icon}
        beforeInjection={svg => {
          svg.setAttribute('style', 'width: 24px')
          svg.setAttribute('style', 'height: 24px')
        }}
        className="ml-3"
      />
      <div className="grow ml-4">
        {props.item.display_name ? props.item.display_name : props.item.name}
      </div>
      <div className={stateCss}>{props.item.state}</div>
      <NLButton
        className={classNames('flex flex-row w-32 text-center justify-center', {
          'bg-pink-400': isInUse && !inUseAction
        })}
        onClick={() => {
          onToggleUse()
        }}
        isDisabled={inUseAction}>
        {inUseAction ? (
          'SAVING'
        ) : (
          <>
            {isInUse ? (
              <div className="flex flex-row justify-center">
                <MdOutlineCancel className="text-xl mr-1" /> DISCARD
              </div>
            ) : (
              <div className="flex flex-row justify-center">
                <HiOutlineCursorClick className="text-xl mr-1" /> USE
              </div>
            )}
          </>
        )}
      </NLButton>
      {props.repo.isRefreshable && (
        <NLButton
          className="px-2 mx-6"
          onClick={() => {
            alert('Refresh repo with id ' + props.item.name)
          }}>
          <HiOutlineRefresh />
        </NLButton>
      )}
    </div>
  )
}

export default RepositoryItem
