// import clsx from 'clsx'
// import { useEffect, useMemo, useRef, useState } from 'react'
// import ReactMarkdown from 'react-markdown'
// import { useLocation } from 'react-router-dom'
// import { ReactSVG } from 'react-svg'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuthContext } from '../../context/AuthContext'
// import http from '../../services/http'
// import SendingIndicator from './ChatBot/SendingIndicator'

// const useQuery = () => {
//   const { search } = useLocation()

//   return useMemo(() => {
//     return new URLSearchParams(search)
//   }, [search])
// }

// function ChatMessage({ message, from, error, innerRef, ...props }) {
//   const { user } = useAuthContext()
//   const username = user?.username

//   const [showFullMessage, setShowFullMessage] = useState(false)

//   const isUser = from === 'user'

//   return (
//     <div
//       className="text-base font-medium leading-7 text-zinc-800 max-md:flex-wrap p-2 border-b-2 border-solid border-gray-100
//       flex
//       "
//       {...props}
//       ref={innerRef}>
//       <div className="flex-shrink-0 w-8 h-8 mr-3">
//         {isUser ? (
//           <img
//             loading="lazy"
//             srcSet={`https://robohash.org/${username})`}
//             alt="User Profile"
//             className="w-8 rounded-full aspect-square"
//           />
//         ) : (
//           <ReactSVG
//             src="./assets/brainbot.svg"
//             className="w-8 rounded-full aspect-square"
//             alt="Brain Icon"
//           />
//         )}
//       </div>
//       {/* wrap text */}
//       <div className="break-all overflow-wrap break-words whitespace-pre-wrap">
//         <ReactMarkdown
//           components={{
//             a: ({ node, ...props }) => (
//               <a
//                 onClick={e => {
//                   e.stopPropagation()
//                   if (!props.href.startsWith('http')) {
//                     e.preventDefault()
//                     alert('Coming soon. ' + props.href)
//                   }
//                 }}
//                 {...props}
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 className="text-purple-500 hover:underline text-sm"
//               />
//             ),
//             ol: ({ node, ...props }) => (
//               <ol
//                 {...props}
//                 style={{
//                   lineHeight: '1.2rem'
//                 }}
//               />
//             )
//           }}
//           className={clsx({
//             'text-red-500': error
//           })}>
//           {message.length > 200 && !showFullMessage && isUser
//             ? message.slice(0, 200) + '...'
//             : parseTextUrl(message)}
//         </ReactMarkdown>
//         {message.length > 200 && isUser && (
//           <button
//             className="text-xs font-medium text-purple-500"
//             onClick={() => setShowFullMessage(prev => !prev)}>
//             Read {showFullMessage ? 'Less' : 'More'}
//           </button>
//         )}
//       </div>
//     </div>
//   )
// }

// export default function ChatBot() {
//   const [message, setMessage] = useState('')
//   const [history, setHistory] = useState([])
//   const [isSending, setIsSending] = useState(false)
//   const [userMessages, setUserMessages] = useState(0)
//   const [sessionId, setSessionId] = useState(null)
//   const [fileId, setFileId] = useState(null)
//   const query = useQuery()

//   useEffect(() => {
//     setFileId(query.get('fileId'))
//   }, [])

//   const messageBoxRef = useRef(null)

//   useEffect(() => {
//     // Scroll to the bottom of the message box when a new message is added
//     messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight
//   }, [history])

//   const handleCardClick = message => {
//     setMessage(message)
//   }

//   const handleMessageChange = e => {
//     if (userMessages >= 5) return

//     setMessage(e.target.value)
//   }

//   const handleMessageSubmit = async () => {
//     if (!message?.trim() || isSending || userMessages >= 5) return

//     setMessage('')
//     setIsSending(true)
//     setHistory(prev => [
//       ...prev,
//       {
//         message,
//         from: 'user'
//       }
//     ])

//     setUserMessages(prev => prev + 1)

//     try {
//       const { data } = await http.post('/brain/chat', {
//         message,
//         sessionId,
//         fileId
//       })

//       const { session_id, response } = data.response
//       const error = data.error

//       setSessionId(session_id)

//       setHistory(prev => [
//         ...prev,
//         {
//           message: response || error,
//           from: 'bot',
//           error: !!error
//         }
//       ])
//     } catch (error) {}

//     setIsSending(false)
//   }

//   return (
//     <main className="relative h-full w-full flex-1 overflow-auto transition-width">
//       <section className="flex h-full flex-col">
//         {history.length === 0 && (
//           <>
//             <ReactSVG
//               className="self-center w-12 aspect-[1.16] text-purple-600"
//               alt="brain bot icon"
//               src="./assets/brainbot.svg"
//               beforeInjection={svg => {
//                 svg.setAttribute('style', 'width: 48px')
//                 svg.setAttribute('style', 'height: 48px')
//               }}
//             />
//             <h2 className="self-center mt-10 text-2xl font-medium tracking-wide leading-10 text-center whitespace-nowrap text-zinc-800">
//               How can I help You today?
//             </h2>
//           </>
//         )}
//         <div className="flex flex-col overflow-y-auto" ref={messageBoxRef}>
//           {history.map((chat, index) => (
//             <ChatMessage key={index} {...chat} />
//           ))}
//           {isSending && <SendingIndicator key="sending-indicator" />}
//         </div>
//         <div
//           className="flex flex-col gap-2 justify-between px-6 py-6 mt-auto w-full bg-white rounded-xl border-solid border-[3px] border-[color:var(--grey,#F2F3F3)] text-slate-500 max-md:px-5 max-md:max-w-full
//       ">
//           <div className="flex items-center gap-3">
//             <input
//               disabled={userMessages >= 5}
//               type="text"
//               placeholder={
//                 history.length
//                   ? 'Ask a follow-up question'
//                   : 'Type your message here...'
//               }
//               className={clsx(
//                 'flex-grow p-2 rounded-md border-transparent focus:border-transparent focus:ring-0 py-3 focus:outline-none',
//                 {
//                   'cursor-not-allowed border-none bg-transparent':
//                     userMessages >= 5
//                 }
//               )}
//               value={message}
//               onChange={handleMessageChange}
//               onKeyUp={e => e.key === 'Enter' && handleMessageSubmit()}
//             />
//             <ReactSVG
//               onClick={handleMessageSubmit}
//               className="w-6 h-6 cursor-pointer"
//               alt="send message icon"
//               src="./assets/send-icon.svg"
//               beforeInjection={svg => {
//                 svg.setAttribute('style', 'width: 24px')
//                 svg.setAttribute('style', 'height: 24px')
//               }}
//             />
//           </div>
//         </div>
//         <div className="flex flex-row justify-between items-baseline mt-1">
//           <span
//             className="text-xs text-slate-500"
//             dangerouslySetInnerHTML={{
//               __html:
//                 userMessages >= 5
//                   ? 'You have reached the maximum number of follow-ups for this session. Please start a new chat.'
//                   : `You have <span class="text-purple-500 text-sm font-bold">${
//                       5 - userMessages
//                     }</span> follow-ups left for this session`
//             }}
//           />

//           {sessionId && (
//             <span className="text-xs text-slate-400">
//               Session ID: {sessionId}
//             </span>
//           )}
//         </div>
//       </section>
//     </main>
//   )
// }

// const parseTextUrl = text => {
//   // get all the urls in the text, http, https, www that are not in a markdown link
//   const urls = text.match(
//     /((https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*))/g
//   )
//   try {
//     // replace the urls with anchor tags
//     urls.forEach(url => {
//       if (url.startsWith('www')) {
//         url = `https://${url}`
//       }

//       const origin = new URL(url).origin

//       text = text.replace(url, `[${origin}](${url})`)
//     })
//   } catch (error) {
//     console.error(error)
//   }

//   return text
// }

export default function ChatBot() {
  const { user } = useAuthContext()
  const [fileId, setFileId] = useState(null)

  const useQuery = () => {
    const { search } = useLocation()

    return useMemo(() => {
      return new URLSearchParams(search)
    }, [search])
  }

  const query = useQuery()

  useEffect(() => {
    setFileId(query.get('fileId'))
  }, [])

  return (
    <iframe
      title="Heybrain Chatbot"
      src={`https://dty0xa7tzq24v.cloudfront.net?api_key=${user?.api_key}&fileId=${fileId}`}
      style={{
        width: '100%',
        height: '100%',
        border: 'none'
      }}
    />
  )
}
