import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import App from './App'
import Admin from './components/onboarding/Admin'
import Application from './components/onboarding/Application'
import ComingSoon from './components/onboarding/ComingSoon'
import Conversations from './components/onboarding/Conversations'
import Instruction from './components/onboarding/Instruction'
import OnBoard from './components/onboarding/OnBoard'
import Welcome from './components/onboarding/Welcome'
import ChatBot from './components/route/ChatBot'
import Home from './components/route/Home'
import Repositories from './components/route/Repositories'
import Search from './components/route/Search'
import Settings from './components/route/Settings'
import SpaceItem from './components/route/SpaceItem'
import Spaces from './components/route/Spaces'
import { AuthProvider, RequireAuth } from './context/AuthContext'
import { SpaceProvider } from './context/SpaceContext'
import './index.css'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <SpaceProvider>
        <AuthProvider>
          <Routes>
            {['login', 'register', 'forgot-password'].map((path, index) => (
              <Route path={path} element={<OnBoard />} key={index} />
            ))}
            <Route path="/welcome" element={<Welcome />} />
            <Route path="/onboarding" element={<Instruction />} />
            <Route element={<RequireAuth />}>
              <Route path="/app" element={<Application />} />

              <Route element={<App />}>
                <Route path="/">
                  <Route index element={<Search />} />
                  <Route path=":q" element={<Search />} />
                </Route>
                <Route path="/chat" element={<ChatBot />} />
                <Route path="/admin" element={<Admin />} />
                <Route path="/conversations" element={<Conversations />} />
                <Route path="/cloud" element={<Home />} />
                <Route path="/repositories" element={<Repositories />} />
                <Route path="/profile/:username" element={<Settings />} />
                <Route path=":username/spaces">
                  <Route index element={<Spaces />} />
                  <Route path=":slug" element={<SpaceItem />} />
                </Route>
              </Route>
            </Route>
            <Route path="/coming-soon" element={<ComingSoon />} />
            <Route
              path="*"
              element={<p className="text-center text-3xl my-4">😔</p>}
            />
          </Routes>
        </AuthProvider>
      </SpaceProvider>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
