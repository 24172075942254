import React, { useEffect, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import './OnBoard.css'

function OnBoardBody(props) {
  const [isVisible, setIsVisible] = useState(false)
  const nodeRef = useRef(null) // This helps silencing strictMode error. https://github.com/reactjs/react-transition-group/issues/668
  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true)
    }, 100)
  }, [])

  return (
    <CSSTransition
      in={isVisible}
      timeout={300}
      classNames="onboarding-body"
      unmountOnExit
      nodeRef={nodeRef}>
      <div className="flex flex-col w-full py-2 md:py-8 justify-center items-center" ref={nodeRef}>
        <img
          className="w-14 block md:hidden"
          src="../../../assets/logo.svg"
          alt="logo"
        />
        <p className="text-2xl font-semibold text-center md:text-left text-purple-darker mb-4">
          {props.title}
        </p>
        {props.children}
      </div>
    </CSSTransition>
  )
}

export default OnBoardBody
