import axios from 'axios'
import classNames from 'classnames'
import clsx from 'clsx'
import JoditEditor from 'jodit-react'
import { useContext, useEffect, useRef, useState } from 'react'
import {
  FaBrain,
  FaCheck,
  FaPenAlt,
  FaRegCopy,
  FaRocketchat,
  FaTimes
} from 'react-icons/fa'
import { GiRingedPlanet } from 'react-icons/gi'
import { ImSafari } from 'react-icons/im'
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdPlayCircle,
  MdStopCircle
} from 'react-icons/md'
import Moment from 'react-moment'
import { useLocation, useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { CSSTransition } from 'react-transition-group'
import { useAuthContext } from '../../context/AuthContext'
import { SpaceContext } from '../../context/SpaceContext'
import {
  closeDocumentOverlay,
  copyChatUrlToClipboard,
  isValidString,
  openDocumentOverlay,
  repoType,
  repos,
  showUserNotification
} from '../../services/helpers'
import http from '../../services/http'
import NLChip from './NLChip'
import NLLoader from './NLLoader'
import './style/Animate.css'

function Content({ doc, repo }) {
  const [isPreviewVisible, setIsPreviewVisible] = useState(false)
  let favicon = repo.icon
  if (doc.favicon_url && doc.favicon_url.length > 0) {
    favicon = doc.favicon_url
  } else {
    favicon =
      'https://www.google.com/s2/favicons?domain=' + doc.domain + '&sz=16'
  }

  if (repo.id === 'zoom') {
    const zr = repos().zoom
    if (!zr) {
      return 'No video.'
    }
    const r = zr.find(r => r.name === doc.__repository)
    if (r === undefined) {
      return 'No video.'
    }
    const videoURL = r.video_url ? r.video_url : ''
    return (
      <video
        width="100%"
        controls
        src={videoURL}
        style={{
          height: '480px',
          objectFit: 'cover',
          borderRadius: '7px'
        }}
      />
    )
  } else if (repo.id === 'whatsapp') {
    return (
      <div className="flex flex-col bg-orange-50 rounded border-gray-200 w-full py-8 px-4 shadow">
        {doc.message}
      </div>
    )
  } else if (repo.id === 'twitter') {
    return (
      <div className="flex flex-col bg-white rounded border-gray-200 w-full py-8 px-4">
        <div className="flex flex-row items-center">
          <div className="bg-gray-200 rounded-full h-12 w-12 flex items-center mr-2">
            <img
              src={repo.icon}
              alt=""
              width="32px"
              height="32px"
              className="mx-auto"
            />
          </div>
          <p className="font-bold text-xl">{doc.__repository}</p>
        </div>
        <div className="flex grow mt-4 text-2xl">{doc.__text}</div>
      </div>
    )
  } else if (
    repo.id === 'web' &&
    // doc.screenshot_url &&
    // doc.screenshot_url.length > 0 &&
    !isPreviewVisible
  ) {
    return (
      <img
        src={doc.screenshot_url || favicon}
        alt=""
        width="100%"
        height="auto"
        className={`mx-auto rounded-lg border border-gray-200 cursor-pointer bg-cover	${
          !doc.screenshot_url ? 'blur-lg' : ''
        }`}
        onClick={() => {
          setIsPreviewVisible(true)
        }}
      />
    )
  } else if (repo.id === 'other') {
    return (
      <div className="flex flex-col bg-white rounded border-gray-200 w-full px-4">
        <div
          className="flex flex-col items-center"
          style={{ width: '100%', height: '30em' }}>
          <img
            src={doc.cover_image}
            alt={doc.name}
            width="100%"
            className="rounded-lg  py-8 px-4"
            style={{ objectFit: 'contain', maxHeight: '100%', width: '100%' }}
            onClick={() => {
              setIsPreviewVisible(true)
            }}
          />
          <p className="font-bold">{doc.name}</p>
        </div>
        <p className="my-8">{`Date: ${doc.created_at}`}</p>
        <p>{doc.description || 'There is no description yet'}</p>
      </div>
    )
  } else {
    if (doc && doc.url) {
      return (
        <iframe
          title={doc.__text}
          width="100%"
          height="100%"
          src={doc.url}></iframe>
      )
    } else {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: doc.__html ? doc.__html : doc.__text
          }}
          style={{
            height: '100%',
            width: '100%'
          }}
          className={classNames({
            'bg-slate-100 p-4 rounded':
              doc.__html === undefined || doc.__html.length === 0
          })}></div>
      )
    }
  }
}

function Detail({
  doc,
  repo,
  slug = null,
  onTagSaved,
  onTagRemoved,
  onToggleInput,
  onToggleNotes,
  isMobile
}) {
  const { user } = useAuthContext()

  const { onPresented, getList, presented, space } = useContext(SpaceContext) // 22.06 natalia
  const [isRemovingTag, setIsRemovingTag] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const isRequired =
    !location.pathname.includes('/spaces/') || space.__role === 'owner_access'

  const notesEditor = useRef(null)
  const tagsInput = useRef(null)
  const [notesContent, setNotesContent] = useState(
    doc.notes_html ? doc.notes_html : ''
  )
  const [isNotesEditorVisible, setIsNotesEditorVisible] = useState(false)

  const [tagsSuggestions, setTagsSuggestions] = useState([])
  const [autocompleteSuggestions, setAutocompleteSuggestions] = useState([])
  const [newTag, setNewTag] = useState('')

  const [presentedLoading, setPresentedLoading] = useState(false) // 26.06 to change condition of presented button
  const [idPresented, setIdPresented] = useState(presented) // 26.06 to change condition of presented button

  const [options, setOptions] = useState(null)
  const [loading, setLoading] = useState(false)

  const fileId = doc.__file_id

  useEffect(() => {
    const ct = axios.CancelToken.source()
    http
      .get('/tag/suggested?id=' + doc.id, {
        cancelToken: ct.token
      })
      .then(response => {
        if (response && response.data && response.data.response) {
          let l = response.data.response
          l = l.filter(t => {
            return doc.__tags.indexOf(t.id) === -1
          })
          setTagsSuggestions(l)
        }
      })
    return () => {
      ct.cancel()
    }
  }, [doc])

  useEffect(() => {
    if (newTag.length === 0) {
      setAutocompleteSuggestions([])
      return
    }
    const ct = axios.CancelToken.source()
    http
      .get('/tag/search/autocomplete?q=' + newTag + '&limit=10', {
        cancelToken: ct.token
      })
      .then(response => {
        if (response && response.data && response.data.response) {
          const l = response.data.response.map(t => {
            return { id: t, kind: 'autocomplete' }
          })
          setAutocompleteSuggestions(l)
        }
      })
    return () => {
      ct.cancel()
    }
  }, [newTag])

  let name = ''
  if (repo.id === 'gdrive') {
    name = doc.__title
  } else if (repo.id === 'whatsapp') {
    name = doc.contact
  } else {
    if (doc.__title) {
      name = doc.__title
    } else if (doc.title) {
      name = doc.title
    } else if (doc.__text) {
      const l = doc.__text.substring(0, 50)
      name = l.length < doc.__text.length ? l + '...' : l
    } else {
      name = repo.name
    }
  }

  const onSaveNewTag = tag => {
    if (tag && tag.length > 0) {
      http
        .post('/document/add_tags', {
          id: doc.__repo_type === 'web' ? doc.id : doc.__id,
          tags: [tag]
        })
        .then(response => {
          if (response && response.data && response.data.status) {
            showUserNotification({
              title: 'Tag added.',
              type: 'success',
              dismissible: false
            })
            onTagSaved(tag)
          } else {
            showUserNotification({
              title: 'Tag could not be added.',
              type: 'error',
              dismissible: false
            })
          }
        })
    }
  }

  const onPurgeTag = (tag, index) => {
    if (isRemovingTag) {
      return
    }
    if (tag && tag.length > 0) {
      const el = document.getElementsByClassName('tag-chip-' + index)[0]
      if (el) {
        el.style.opacity = 0.5
      }
      setIsRemovingTag(true)
      http
        .post('/document/remove_tags', {
          id: doc.__repo_type === 'web' ? doc.id : doc.__id,
          tags: [tag]
        })
        .then(response => {
          if (response && response.data && response.data.status) {
            showUserNotification({
              title: 'Tag removed successfully.',
              type: 'success',
              dismissible: false
            })
            onTagRemoved(tag)
          } else {
            showUserNotification({
              title: 'Tag could not be removed.',
              type: 'error',
              dismissible: false
            })
          }
          setIsRemovingTag(false)
          if (el) {
            el.style.opacity = 1
          }
        })
    }
  }

  const onSaveNote = () => {
    // remove html from the notesContent variable.
    const notesCleared = notesContent.replace(/(<([^>]+)>)/gi, '')
    http
      .post('/brain/update_url_document', {
        id: doc.__repo_type === 'web' ? doc.id : doc.__id,
        notes: notesCleared,
        notes_html: notesContent
      })
      .then(response => {
        if (response && response.data && response.data.status) {
          setIsNotesEditorVisible(false)
          onToggleNotes()
          showUserNotification({
            title: 'Note changes has been saved.',
            type: 'success',
            dismissible: false
          })
        } else {
          showUserNotification({
            title: 'Could not save the note.',
            type: 'error',
            dismissible: false
          })
        }
      })
  }

  async function addInSpace(selectedOption, doc) {
    setLoading(true)
    if (selectedOption.value === 'add new') {
      // if add into new space
      const name = prompt('input name of new space')
      if (!name) {
        setLoading(false)
        return
      }
      if (isValidString(name)) {
        // name validation
        const res = await http.post(`/space/create?repositories%5B%5D=web`, {
          items: [
            {
              repository: doc.__repository,
              username: doc.__username,
              id: doc.__id,
              order: 1
            }
          ],
          name
        })
        showUserNotification({
          title: res.data.error
            ? res.data.error
            : `Item "${doc.title}" was added into ${name}.`,
          type: res.data.error ? 'error' : 'success',
          dismissible: false
        })
      } else
        showUserNotification({
          title: 'Name should contain at least one letter or number',
          type: 'error',
          dismissible: false
        })
    } else {
      // if add into existing space
      const res = await http.post(
        `/space/add_items?space_slug=${selectedOption.value}&username=${doc.__username}&repositories%5B%5D=web`,
        {
          items: [
            {
              repository: doc.__repository,
              username: doc.__username,
              id: doc.__id
            }
          ]
        }
      )
      showUserNotification({
        title: res.data.error
          ? `${res.data.error}`
          : `Item "${doc.title}" was added into ${selectedOption.value}.`,
        type: res.data.error ? 'error' : 'success',
        dismissible: false
      })
    }
    setLoading(false)
  }

  const customStyles = {
    // to customize select for choosing space
    menu: (provided, state) => ({
      ...provided,
      top: 'auto',
      bottom: '100%',
      marginTop: 0,
      width: isMobile ? '150px' : '200px',
      fontSize: isMobile ? '0.8rem' : '1rem'
    }),
    control: (provided, state) => ({
      ...provided,
      width: isMobile ? '150px' : '200px'
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.data.value === 'add new' ? '#A855F7' : 'inherit',
      fontWeight: state.data.value === 'add new' ? '600' : 'inherit'
    })
  }

  // const speaker = doc.speaker; Zoom.
  return (
    <div className="flex flex-col h-full space-y-2 sm:space-y-4">
      <div className="flex flex-row justify-between items-start space-x-2">
        <img src={repo.icon} className="w-10 h-10 sm:w-12 sm:h-12 " />
        <div className="flex flex-col flex-grow">
          <p className="font-semibold text-sm sm:text-base">{name}</p>
          <div className="text-xs text-gray-400 flex flex-row">
            {doc.iconLink ? (
              <img
                src={doc.iconLink}
                alt=""
                width="16px"
                height="16px"
                className="mr-1"
              />
            ) : (
              ''
            )}
            <Moment format="DD.MM.YYYY">{doc.__timestamp}</Moment>
          </div>
        </div>
        <div
          className="static sm:absolute sm:top-4 sm:right-4 cursor-pointer hover:text-gray-500  text-xs mt-1 sm:mt-0"
          onClick={() => {
            closeDocumentOverlay()
          }}>
          Close
        </div>
      </div>
      {/* start  tags */}
      <div className="flex flex-wrap ml-2 mr-2 sm:ml-0 sm:mr-0">
        {doc.__tags &&
          doc.__tags.map((tag, index) => {
            return (
              <NLChip
                className={'mt-1 mr-2 tag-chip-' + index}
                key={index}
                item={tag}
                onPurge={
                  isRequired
                    ? i => {
                        onPurgeTag(i, index)
                      }
                    : null
                }
                onClick={
                  isRequired
                    ? i => {
                        const t = encodeURIComponent(i)
                        closeDocumentOverlay()
                        navigate(`/${t}`)
                      }
                    : null
                }
                editable={isRequired}
              />
            )
          })}
      </div>
      {/* end  tags */}
      {isRequired && (
        <div className="text-xs font-semibold border rounded-full px-4 py-2 relative ml-2 sm:ml-0 mr-2 sm:mr-0">
          <input
            ref={tagsInput}
            type="text"
            className="focus:outline-none w-full"
            placeholder="Add new tag.."
            onFocus={onToggleInput}
            onBlur={onToggleInput}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                // disable input
                e.target.disabled = true
                onSaveNewTag(e.target.value.trim())
                setTimeout(() => {
                  e.target.disabled = false
                  e.target.value = ''
                  tagsInput.current.blur()
                }, 200)
              }
            }}
            onChange={e => {
              const value = e.target.value.trim()
              if (value.length > 0) {
                setNewTag(value)
              } else {
                setNewTag('')
              }
            }}
          />
        </div>
      )}

      {/* start of tag suggestions */}
      <p
        className={`font-semibold text-sm sm:text-base  ${
          isRequired ? '' : 'hidden'
        }`}>
        Suggestions
      </p>
      <div className="flex flex-wrap ml-2 mr-2 sm:ml-0 sm:mr-0">
        {autocompleteSuggestions.length === 0 &&
          tagsSuggestions.length > 0 &&
          tagsSuggestions
            .filter(
              (thing, index, self) =>
                index === self.findIndex(t => t.id === thing.id)
            )
            .map((tag, index) => {
              const item = tag
              item.type = tag.kind
              return (
                <NLChip
                  className={
                    'bg-blue-200 mt-1 mr-2 suggested-tag-chip-' + index
                  }
                  key={index}
                  item={item}
                  onClick={i => {
                    onSaveNewTag(i.id)
                    // remove the tag from the suggestions.
                    const newTagsSuggestions = tagsSuggestions.filter(
                      t => t.id !== i.id
                    )
                    setTagsSuggestions(newTagsSuggestions)
                  }}
                />
              )
            })}
        {autocompleteSuggestions.length > 0 &&
          autocompleteSuggestions.map((tag, index) => {
            const item = tag
            item.type = tag.kind
            return (
              <NLChip
                className={'bg-blue-200 mt-1 mr-2 suggested-tag-chip-' + index}
                key={index}
                item={item}
                onClick={i => {
                  onSaveNewTag(i.id)
                  setTimeout(() => {
                    tagsInput.current.blur()
                    tagsInput.current.value = ''
                    setAutocompleteSuggestions([])
                  }, 100)
                }}
              />
            )
          })}
      </div>
      {/* end of tag suggestions */}
      <div className="flex-grow flex flex-col overflow-y-auto">
        <div className="flex flex-row font-semibold">
          <div className="flex-grow">Note</div>
          <div>
            {isNotesEditorVisible && isRequired ? (
              <div>
                <button
                  className="text-red-700 px-2"
                  onClick={() => {
                    setIsNotesEditorVisible(false)
                    onToggleNotes()
                  }}>
                  <FaTimes />
                </button>
                <button
                  className="text-green-700 px-2"
                  onClick={() => {
                    onSaveNote()
                  }}>
                  <FaCheck />
                </button>
              </div>
            ) : (
              <button
                className={`text-purple-500 px-2 ${isRequired ? '' : 'hidden'}`}
                onClick={() => {
                  setIsNotesEditorVisible(true)
                  onToggleNotes()
                }}>
                <FaPenAlt />
              </button>
            )}
          </div>
        </div>
        <div className="flex-grow overflow-y-auto ">
          <JoditEditor
            ref={notesEditor}
            value={notesContent}
            config={{
              language: 'en',
              readonly: !isNotesEditorVisible,
              showCharsCounter: false,
              showWordsCounter: false,
              showXPathInStatusbar: false,
              buttonsXS: ['bold', 'italic', 'underline'],
              activeButtonsInReadOnly: [],
              buttons: [],
              toolbarButtonSize: 'small',
              toolbar: true
            }}
            onBlur={newContent => setNotesContent(newContent)} // preferred to use only this option to update the content for performance reasons
            onChange={newContent => {}}
          />
        </div>
      </div>
      <div className="flex flex-row space-x-2 items-center justify-end">
        {loading && <p className="text-xs">Adding...</p>}
        {options && (
          <Select
            options={options}
            styles={customStyles}
            onChange={selectedOption => addInSpace(selectedOption, doc)}
          />
        )}
        <div
          className={classNames(
            'p-2 rounded-full bg-purple-500 text-white text-xl',
            {
              'opacity-50': doc.__tags?.length === 0,
              'hover:bg-purple-400 cursor-pointer': doc.__tags?.length > 0
            }
          )}
          onClick={() => {
            if (doc.__tags?.length > 0) {
              let t = ''
              doc.__tags.forEach(tag => {
                if (t.length === 0) {
                  t = tag
                } else {
                  t += ', ' + tag
                }
              })
              t = encodeURIComponent(t)
              closeDocumentOverlay()
              navigate(`/${t}`)
            } else {
              showUserNotification({
                title: 'This document has no tags.',
                type: 'warning',
                dismissible: false
              })
            }
          }}>
          <FaBrain />
        </div>
        {/* go to chatbot button */}
        {fileId && (
          <>
            <div
              className="p-2 rounded-full bg-purple-500 text-white text-xl hover:bg-purple-400 cursor-pointer"
              onClick={() => {
                closeDocumentOverlay()
                navigate(`/chat?fileId=${fileId}`)
              }}>
              <FaRocketchat />
            </div>
            <div
              className="p-2 rounded-full bg-purple-500 text-white text-xl hover:bg-purple-400 cursor-pointer"
              onClick={() => {
                copyChatUrlToClipboard({
                  user,
                  fileId
                })
              }}>
              <FaRegCopy />
            </div>
          </>
        )}
        <div
          className="p-2 rounded-full bg-purple-500 text-white text-xl hover:bg-purple-400 cursor-pointer"
          onClick={() => {
            let l = ''
            if (doc.webViewLink) {
              l = doc.webViewLink
            } else if (doc.url) {
              l = doc.url
            }
            if (l.length > 0) {
              window.open(l, '_blank')
            } else {
              showUserNotification({
                title: 'The document has not any link.',
                type: 'warning',
                dismissible: false
              })
            }
          }}>
          <ImSafari />
        </div>
        {slug && !presentedLoading && space.__role === 'owner_access' && (
          <div
            className={clsx(
              'p-2 rounded-full  text-white text-xl cursor-pointer',
              {
                'bg-purple-500': idPresented !== doc.__id,
                'bg-green-500': idPresented === doc.__id
              }
            )}>
            <MdPlayCircle
              onClick={() => {
                setPresentedLoading(true)
                showUserNotification({
                  title: `Now presented: ${name}`,
                  type: 'success',
                  dismissible: false
                })
                http
                  .post(
                    `https://api.nlpgraph.com/stage/api/space/update?username=${doc.__username}&space_slug=${slug}`,
                    {
                      display_now: doc.__id
                    }
                  )
                  .then(response => {
                    setPresentedLoading(false)
                    onPresented(response.data.response.display_now)
                    setIdPresented(response.data.response.display_now)
                  })
              }}
            />
          </div>
        )}

        {slug && space.__role === 'owner_access' && (
          <div
            className={clsx('p-2 bg-gray-400 rounded-full text-xl', {
              invisible: idPresented !== doc.__id,
              'visible, cursor-pointer': idPresented === doc.__id
            })}>
            <MdStopCircle
              disabled={idPresented !== doc.__id}
              onClick={() => {
                http
                  .post(
                    `https://api.nlpgraph.com/stage/api/space/update?username=${doc.__username}&space_slug=${slug}`,
                    {
                      display_now: -1
                    }
                  )
                  .then(response => {
                    onPresented(response.data.response.display_now)
                    setIdPresented(response.data.response.display_now)
                  })
              }}
            />
          </div>
        )}

        {!slug && (
          <GiRingedPlanet
            className="p-2 rounded-full bg-purple-500 text-white text-4xl hover:bg-purple-400 cursor-pointer"
            onClick={() => {
              getList().then(data => {
                setOptions(data)
              })
            }}
          />
        )}
      </div>
    </div>
  )
}

function NLItemDocumentOverlay(props) {
  const [isVisible, setIsVisible] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const nodeRef = useRef(null) // This helps silencing strictMode error. https://github.com/reactjs/react-transition-group/issues/668

  const [didExpand, setDidExpand] = useState(false)

  const [isContentVisible, setIsContentVisible] = useState(false)
  const nodeRefContent = useRef(null) // This helps silencing strictMode error. https://github.com/reactjs/react-transition-group/issues/668

  const [doc, setDoc] = useState(props.doc || null)
  const [slug, setSlug] = useState(props.slug || null)
  const [isLoading, setIsLoading] = useState(false)
  const context = useContext(SpaceContext)
  const [isInputEditing, setIsInputEditing] = useState(false)
  const [isNotesEditing, setIsNotesEditing] = useState(false)

  const handleInputEditing = () => {
    setIsInputEditing(isInputEditing => !isInputEditing)
  }

  const handleNotesEditing = () => {
    setIsNotesEditing(isNotesEditing => !isNotesEditing)
  }

  const moveRight = () => {
    const docIndex = props.list.findIndex(d => d.__id === props.doc.__id)
    if (docIndex < props.list.length - 1) {
      openDocumentOverlay(props.list[docIndex + 1], props.list)
    }
  }

  const moveLeft = () => {
    const docIndex = props.list.findIndex(d => d.__id === props.doc.__id)
    if (docIndex > 0) {
      openDocumentOverlay(props.list[docIndex - 1], props.list)
    }
  }

  useEffect(() => {
    function handleKeywordTap(event) {
      if (isInputEditing || isNotesEditing) return
      if (event.code === 'Escape') {
        closeDocumentOverlay()
      } else if (event.code === 'ArrowLeft') {
        moveLeft()
      } else if (event.code === 'ArrowRight') {
        moveRight()
      }
    }
    function handleOutsideClick(event) {
      if (isInputEditing || isNotesEditing) return
      if (!nodeRef.current.contains(event.target)) {
        closeDocumentOverlay()
      }
    }
    document.addEventListener('keydown', handleKeywordTap)
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('keydown', handleKeywordTap)
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [isInputEditing, isNotesEditing, props.doc.__id, props.list])

  useEffect(() => {
    setIsLoading(true)
    setDidExpand(false)
    setIsContentVisible(false)
    setTimeout(() => {
      setIsVisible(true)
    }, 100)

    http.get('/document/?__id=' + props.doc.__id).then(response => {
      if (response && response.data && response.data.response) {
        setDoc(response.data.response)
      } else {
        const id = props.doc.__id
        const foundObject = context.items.find(item => item.__id === id)
        setDoc(foundObject)
      }
      setTimeout(
        () => {
          setDidExpand(true)
        },
        props.isMobile ? 0 : 300
      )
      setTimeout(
        () => {
          setIsContentVisible(true)
        },
        props.isMobile ? 0 : 700
      )
      setIsLoading(false)
    })
  }, [props.doc.__id, props.list])

  const repo = props.doc.__repo_type
    ? repoType(props.doc.__repo_type)
    : repoType('other')

  return (
    <div
      className="absolute bg-black/50 left-0 top-0 w-screen h-screen"
      style={{
        zIndex: '10010'
      }}>
      <CSSTransition
        in={isVisible}
        timeout={300}
        classNames="animFadeWithScale"
        unmountOnExit
        nodeRef={nodeRef}>
        <div
          className={clsx(
            'absolute w-full top-2/4 h-3/4 left-0 right-0 mx-auto my-0 bg-white overflow-y-hidden rounded transition-all duration-300 ease-in-out flex flex-row shadow -translate-y-2/4',
            {
              'sm:w-[1224px]': didExpand,
              'sm:w-[768px]': !didExpand
            }
          )}
          ref={nodeRef}>
          <div
            className="xs:hidden sm:flex sm:w-[768px] items-center justify-center p-28 overflow-y-hidden h-full bg-[#F9FAFE]"
            style={{
              boxShadow: '0px 0px 28px rgba(43, 12, 121, 0.34)'
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            <MdKeyboardArrowLeft
              className={clsx(
                'absolute left-2 top-1/2 text-2xl xs:text-black cursor-pointer',
                {
                  'sm:text-black': isHovered && !isLoading,
                  'sm:text-white': !isHovered || isLoading
                }
              )}
              onClick={() => {
                moveLeft()
              }}
            />
            {isLoading && !props.isMobile ? (
              <NLLoader size="4xl" />
            ) : (
              <Content doc={doc} repo={repo} />
            )}
          </div>
          <CSSTransition
            in={isContentVisible && !isLoading}
            timeout={50}
            classNames="animFade"
            unmountOnExit
            nodeRef={nodeRefContent}>
            <div
              ref={nodeRefContent}
              className={classNames(
                'relative flex-grow py-2 px-4 sm:py-4 sm:px-10 overflow-y-auto'
              )}
              style={{
                width: '456px'
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}>
              {isLoading && props.isMobile ? (
                <NLLoader size="4xl" className="absolute top-1/2 right-1/2" />
              ) : (
                <>
                  <MdKeyboardArrowLeft
                    className="absolute left-0 sm:left-2 top-14 sm:hidden text-2xl cursor-pointer text-black"
                    onClick={() => {
                      moveLeft()
                    }}
                  />
                  <MdKeyboardArrowRight
                    className={clsx(
                      'absolute right-0 sm:right-2 top-14 sm:top-1/2 text-2xl xs:text-black cursor-pointer',
                      {
                        'sm:text-black': isHovered,
                        'sm:text-white': !isHovered
                      }
                    )}
                    onClick={() => {
                      moveRight()
                    }}
                  />
                  <Detail
                    doc={doc}
                    repo={repo}
                    slug={slug}
                    isMobile={props.isMobile}
                    onTagSaved={tag => {
                      const t = doc.__tags
                      t.push(tag)
                      setDoc({ ...doc, __tags: t })
                    }}
                    onTagRemoved={tag => {
                      let t = doc.__tags
                      t = t.filter(t => t !== tag)
                      setDoc({ ...doc, __tags: t })
                    }}
                    isInputEditing={isInputEditing}
                    onToggleInput={handleInputEditing}
                    onToggleNotes={handleNotesEditing}
                  />
                </>
              )}
            </div>
          </CSSTransition>
        </div>
      </CSSTransition>
      {props.list && props.list.length > 0 ? (
        <div
          className="absolute text-center bg-white text-purple-700 text-xs font-semibold rounded-full py-1 px-4"
          style={{
            bottom: '5%',
            transform: 'translateX(-50%)',
            left: '50%'
          }}>
          {props.list.findIndex(d => d.__id === props.doc.__id) + 1}/
          {props.list.length}
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default NLItemDocumentOverlay
