import clsx from 'clsx'
import React, { useEffect, useRef, useState } from 'react'
import { FaCopy } from 'react-icons/fa'
import { useAuthContext } from '../../../context/AuthContext'
import {
  ChatbotUrl,
  copyToClipboard,
  showUserNotification
} from '../../../services/helpers'
import http from '../../../services/http'
import NLButton from '../../common/NLButton'
import NLInput from '../../common/NLInput'
import NLLoader from '../../common/NLLoader'

const ToggleSwitch = ({ onChange, value, leftLabel, rightLabel }) => {
  const [isActive, setIsActive] = useState(
    rightLabel.toLowerCase() === value.toLowerCase()
  )

  useEffect(() => {
    setIsActive(value.toLowerCase() === rightLabel.toLowerCase())
  }, [value])

  return (
    <div className="flex items-center space-x-2">
      <span
        className={`text-sm font-medium ${
          isActive ? 'text-gray-400' : 'text-blue-700'
        }`}>
        {leftLabel}
      </span>
      <div
        onClick={() => {
          setIsActive(!isActive)
          onChange(
            isActive ? leftLabel.toLowerCase() : rightLabel.toLowerCase()
          )
        }}
        className={`relative rounded-full w-14 h-8 transition duration-200 ease-linear ${
          isActive ? 'bg-blue-700' : 'bg-gray-300'
        }`}>
        <span
          className={`absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition transform duration-100 ease-linear cursor-pointer ${
            isActive ? 'translate-x-full' : 'translate-x-0'
          }`}></span>
      </div>
      <span
        className={`text-sm font-medium ${
          isActive ? 'text-blue-700' : 'text-gray-400'
        }`}>
        {rightLabel}
      </span>
    </div>
  )
}

function BrandSettings({ profile }) {
  const [brand, setBrand] = useState({
    name: '',
    logo: '',
    icon: '',
    inputMessage: '',
    helperText: '',
    webhookUrl: ''
  })
  const [questions, setQuestions] = useState([])
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [selectedQuestion, setSelectedQuestion] = useState(null)
  const [newQuestionType, setNewQuestionType] = useState('regular')

  useEffect(() => {
    const loadBrandSettings = async () => {
      setLoading(true)
      try {
        const response = await http.get('/brain/chatbot-settings')
        const _brand = response.data?.response
        if (!_brand.questions) {
          _brand.questions = []
        }
        setBrand(_brand)
        setQuestions(_brand.questions)
      } catch (error) {
        console.error('Error occurred while fetching data:', error)
        // Add error handling logic
      }

      setLoading(false)
    }

    loadBrandSettings()
  }, [])

  const handleBrandChange = e => {
    const { name, value } = e.target
    setBrand(prev => ({ ...prev, [name]: value }))
  }

  const handleSave = () => {
    const updatedBrand = { ...brand, questions }
    showUserNotification({
      title: 'Saving',
      message: 'Saving data...',
      type: 'info'
    })

    http
      .post('/brain/chatbot-settings', updatedBrand)
      .then(response => {
        showUserNotification({
          title: 'Success',
          message: 'Data saved successfully.',
          type: 'success'
        })
        // Add any further handling if required
      })
      .catch(error => {
        console.error('Error occurred while saving data:', error)
        // Add error handling logic
      })
  }

  const addQuestion = () => {
    if (questions.length >= 4) {
      showUserNotification({
        title: 'Error',
        message: 'You can only add up to 4 questions.',
        type: 'error'
      })
      return
    }

    const newQuestion = {
      id: questions.length + 1,
      type: newQuestionType,
      title: '',
      description: '',
      items: []
    }
    setQuestions([...questions, newQuestion])
    openModal(newQuestion)
  }

  const removeQuestion = index => {
    const filteredQuestions = questions.filter((_, i) => i !== index)
    setQuestions(filteredQuestions)

    closeModal()
  }

  const addItem = () => {
    const updatedSelectedQuestion = {
      ...selectedQuestion,
      items: [...selectedQuestion.items, '']
    }

    setSelectedQuestion(updatedSelectedQuestion)

    const updatedQuestions = questions.map(question => {
      if (question.id === selectedQuestion.id) {
        return updatedSelectedQuestion
      }
      return question
    })

    setQuestions(updatedQuestions)
  }

  const removeItem = itemIndex => {
    selectedQuestion.items.splice(itemIndex, 1)
    setSelectedQuestion({
      ...selectedQuestion,
      items: selectedQuestion.items
    })

    const updatedQuestions = questions.map(question => {
      if (question.id === selectedQuestion.id) {
        return selectedQuestion
      }
      return question
    })

    setQuestions(updatedQuestions)
  }

  const openModal = question => {
    setSelectedQuestion(question)
    setShowModal(true)
  }

  const closeModal = () => {
    setSelectedQuestion(null)
    setShowModal(false)
  }

  const handleSaveQuestion = () => {
    const updatedQuestions = questions.map(question => {
      if (question.id === selectedQuestion.id) {
        return selectedQuestion
      }
      return question
    })
    setQuestions(updatedQuestions)
    closeModal()
  }

  if (loading) {
    return (
      <div className="flex gap-2">
        <NLLoader size={50} />
        <span className="text-sm font-semibold text-gray-400">Loading...</span>
      </div>
    )
  }

  return (
    <div className="flex flex-row w-full">
      <div className="flex flex-col gap-6 w-1/3">
        <h2 className="text-xl font-semibold text-[#2B007B]">Brand</h2>

        {/* Brand settings input fields */}
        <div className="flex flex-col gap-1">
          <label
            htmlFor="grid-text-1"
            className="uppercase text-gray-700 text-xs font-bold">
            name
          </label>
          <div className="flex relative">
            <NLInput
              type="text"
              name="name"
              value={brand.name}
              onChange={handleBrandChange}
              className="w-full"
            />
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <label
            htmlFor="grid-text-1"
            className="uppercase text-gray-700 text-xs font-bold">
            Logo
          </label>
          <NLInput
            type="text"
            name="logo"
            value={brand.logo}
            onChange={handleBrandChange}
            className="w-full"
          />
        </div>

        <div className="flex flex-col gap-2">
          <label
            htmlFor="grid-text-1"
            className="uppercase text-gray-700 text-xs font-bold">
            Icon
          </label>
          <NLInput
            type="text"
            name="icon"
            value={brand.icon}
            onChange={handleBrandChange}
            className="w-full"
          />
        </div>

        <div className="flex flex-col gap-2">
          <label
            htmlFor="grid-text-1"
            className="uppercase text-gray-700 text-xs font-bold">
            Input Message
          </label>
          <NLInput
            type="text"
            name="inputMessage"
            value={brand.inputMessage}
            onChange={handleBrandChange}
            className="w-full"
          />
        </div>

        <div className="flex flex-col gap-2">
          <label
            htmlFor="grid-text-1"
            className="uppercase text-gray-700 text-xs font-bold">
            Helper Text
          </label>
          <NLInput
            type="text"
            name="helperText"
            value={brand.helperText}
            onChange={handleBrandChange}
            className="w-full"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label
            htmlFor="grid-text-1"
            className="uppercase text-gray-700 text-xs font-bold">
            Webhook URL
          </label>
          <NLInput
            type="text"
            name="webhookUrl"
            value={brand.webhookUrl}
            onChange={handleBrandChange}
            className="w-full"
          />
        </div>

        <div className="flex max-w-[100px]">
          <NLButton onClick={handleSave} className="w-full">
            Save
          </NLButton>
        </div>
      </div>
      <div className="flex flex-col gap-6 w-2/3 items-center">
        <h2 className="text-xl font-semibold text-[#2B007B]">Questions</h2>
        {/* add question btn */}
        <div className="flex flex-col gap-2">
          <div className="flex items-center">
            <ToggleSwitch
              value={newQuestionType}
              leftLabel="Regular"
              rightLabel="List"
              onChange={newVal => setNewQuestionType(newVal)}
            />
            <NLButton
              className="bg-purple-500 text-white active:bg-purple-600 ml-4"
              onClick={addQuestion}>
              Add a new Question
            </NLButton>
          </div>
          <span className="text-xs text-gray-400 font-mono text-end">
            (You can add up to 4 questions)
          </span>
        </div>
        {/* show list of questions in small cards */}
        <div className="grid grid-cols-2 gap-4">
          {questions.map((question, index) => (
            <div
              key={index}
              className="bg-white shadow-md rounded-md p-4 cursor-pointer"
              onClick={() => openModal(question)}>
              <h3 className="text-lg font-semibold">
                {question.title || 'Title'}
              </h3>
              <p className="text-gray-600 line-clamp-3 max-w-[250px]">
                {question.description || 'Click to edit the question details'}
              </p>
            </div>
          ))}
        </div>
        {/* once clicked on each card, show the question details in modal */}
        {showModal && (
          <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white rounded-lg p-8 max-w-xl w-full max-h-[80vh] overflow-auto">
              <h2 className="text-xl font-semibold mb-4">
                {selectedQuestion.title}
              </h2>
              <div className="mb-4">
                <label
                  htmlFor="title"
                  className="block text-gray-700 font-semibold mb-2">
                  Title
                </label>
                <input
                  type="text"
                  id="title"
                  value={selectedQuestion.title}
                  placeholder="Title"
                  onChange={e =>
                    setSelectedQuestion({
                      ...selectedQuestion,
                      title: e.target.value
                    })
                  }
                  className="border border-gray-300 rounded-md p-2 w-full"
                />
              </div>
              {selectedQuestion.type === 'regular' && (
                <div className="mb-4">
                  <label
                    htmlFor="description"
                    className="block text-gray-700 font-semibold mb-2">
                    Question
                  </label>
                  <textarea
                    id="description"
                    placeholder="Prompt"
                    value={selectedQuestion.description}
                    onChange={e =>
                      setSelectedQuestion({
                        ...selectedQuestion,
                        description: e.target.value
                      })
                    }
                    className="border border-gray-300 rounded-md p-2 w-full"></textarea>
                </div>
              )}
              {selectedQuestion.type === 'list' && (
                <div>
                  <h3 className="text-lg font-semibold mb-2">Items</h3>
                  {selectedQuestion.items.map((item, itemIndex) => (
                    <div key={itemIndex} className="flex items-center mb-2">
                      <input
                        type="text"
                        value={item}
                        placeholder="Enter a new question"
                        onChange={e =>
                          setSelectedQuestion({
                            ...selectedQuestion,
                            items: selectedQuestion.items.map((i, j) =>
                              j === itemIndex ? e.target.value : i
                            )
                          })
                        }
                        className="border border-gray-300 rounded-md p-2 w-full"
                      />
                      <NLButton
                        onClick={() => removeItem(itemIndex)}
                        className="ml-2 bg-red-300">
                        Remove
                      </NLButton>
                    </div>
                  ))}
                  <NLButton
                    onClick={() => addItem(selectedQuestion.id)}
                    className="bg-blue-500 text-white">
                    Add Item
                  </NLButton>
                </div>
              )}
              <div className="flex justify-between mt-6">
                <NLButton
                  onClick={closeModal}
                  className="bg-gray-300 text-gray-700">
                  Cancel
                </NLButton>
                <div className="flex items-center">
                  <NLButton
                    onClick={() => {
                      if (!window.confirm('Are you sure you want to delete?')) {
                        return
                      }

                      removeQuestion(questions.indexOf(selectedQuestion))
                    }}
                    className="bg-red-500">
                    Delete
                  </NLButton>
                  <NLButton
                    onClick={handleSaveQuestion}
                    className="bg-purple-500 text-white active:bg-purple-600 ml-2">
                    Save
                  </NLButton>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

function UploadFile() {
  const [uploading, setUploading] = useState(false)

  const inputRef = useRef(null)

  const getPresignedUrl = async () => {
    try {
      const response = await http.get('/tools/upload-file')

      return response.data?.response
    } catch (error) {
      console.error('Error occurred while fetching presigned URL:', error)
      // Add error handling logic
    }
  }

  const handleFileChange = e => {
    if (e.target.files.length > 0 && !uploading) {
      handleUpload(e.target.files[0])
    }
  }

  const handleUpload = async file => {
    const infoAWS = await getPresignedUrl()

    if (!infoAWS) {
      return
    }

    setUploading(true)

    const formData = new FormData()
    formData.append('key', infoAWS.fields.key)
    formData.append('acl', 'private')
    formData.append('success_action_redirect', '/')
    formData.append('content-type', 'text/')
    formData.append('x-amz-meta-uuid', '14365123651274')
    formData.append('x-amz-server-side-encryption', 'AES256')
    formData.append('x-amz-credential', infoAWS.fields['x-amz-credential'])
    formData.append('x-amz-algorithm', infoAWS.fields['x-amz-algorithm'])
    formData.append('x-amz-date', infoAWS.fields['x-amz-date'])
    formData.append('x-amz-meta-tag', '')
    formData.append('policy', infoAWS.fields.policy)
    formData.append('x-amz-signature', infoAWS.fields['x-amz-signature'])
    const filename = file.name.replace(/[^a-zA-Z0-9.]/g, '_')
    formData.append('file', file, filename)
    await http.post('https://agraba-uploads.s3.amazonaws.com/', formData, {
      headers: { 'content-type': 'multipart/form-data' }
    })

    // add document
    await http.post('/brain/get_url_data', {
      title: `${file.name} - ${new Date().toLocaleDateString()}`,
      file_url:
        'https://agraba-uploads.s3.amazonaws.com/' +
        // eslint-disable-next-line no-template-curly-in-string
        infoAWS.fields.key?.replace('${filename}', filename)
    })

    showUserNotification({
      title: 'Import Done',
      message: 'File uploaded successfully.',
      type: 'success'
    })

    setUploading(false)
  }

  return (
    <div className="flex flex-col gap-2">
      <input
        // accept documents, text files
        accept=".doc,.docx,.txt,.pdf,.csv,.xls,.xlsx"
        type="file"
        ref={inputRef}
        onChange={handleFileChange}
        className="invisible"
      />
      <div className="flex flex-col">
        <label
          htmlFor="grid-text-2"
          className="uppercase text-gray-700 text-xs font-bold">
          Upload file for chatbot
        </label>
        <span className="text-xs text-gray-400 font-bold font-mono lowercase">
          (As soon as you select a file, it will be uploaded.)
        </span>
      </div>
      <div className="flex flex-row gap-2">
        <NLButton
          onClick={() => inputRef.current.click()}
          isDisabled={uploading}>
          Browse File to Upload
        </NLButton>
        {uploading && (
          <div className="flex items-center">
            <span className="text-xs text-gray-400 font-bold font-mono">
              Uploading...
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

function IframeWidget() {
  const { user } = useAuthContext()

  const iframeCodeHtml = `
  <iframe
    title="Heybrain Chatbot"
    src="${ChatbotUrl}?api_key=${user.api_key}"
    style={{
      width: '100%',
      height: '100vh',
      border: 'none'
    }}
  />
  `

  return (
    <div className="max-w-full">
      <label
        htmlFor="grid-text-1"
        className="uppercase text-gray-700 text-xs font-bold flex flex-col">
        Widget Code
      </label>
      <span className="text-xs text-gray-400 font-bold font-mono lowercase">
        (Copy and paste this code in your website to embed the chatbot widget)
      </span>

      <div className="text-xs text-gray-500 font-mono whitespace-pre-line border border-gray-200 p-2 relative">
        <div
          className="absolute top-2 right-2 flex gap-2 bg-green-100 p-2 rounded-md cursor-pointer hover:bg-green-200 rounded-lg"
          onClick={() => copyToClipboard(iframeCodeHtml, 'Widget code copied')}>
          <span className="text-xs font-bold">Click to Copy</span>
          <FaCopy className="w-4 h-4" />
        </div>
        <code>
          <pre className="whitespace-pre-wrap">{iframeCodeHtml}</pre>
        </code>
      </div>
    </div>
  )
}

export default function ChatbotSettings({ profile }) {
  const [activeTab, setActiveTab] = useState('brand') // brand, upload
  const renderTab = () => {
    switch (activeTab) {
      case 'brand':
        return <BrandSettings profile={profile} />
      case 'upload':
        return (
          <div className="flex flex-col p-2 w-full">
            <UploadFile />
            <div className="border-b border-gray-300 my-4"></div>
            <IframeWidget />
          </div>
        )
      case 'default':
        return <BrandSettings profile={profile} />
    }
  }

  return (
    <div className="flex flex-col bg-white h-full w-full min-w-max px-3">
      <div className="flex flex-col gap-4 mb-5">
        <h1 className="text-2xl font-semibold text-[#2B007B] text-center">
          Chatbot Settings
        </h1>
        <div className="flex flex-row gap-4">
          <NLButton
            onClick={() => setActiveTab('brand')}
            className={clsx('bg-purple-700 text-white', {
              'bg-gray-700': activeTab !== 'brand'
            })}>
            Brand
          </NLButton>
          <NLButton
            onClick={() => setActiveTab('upload')}
            className={clsx('bg-purple-700 text-white', {
              'bg-gray-700': activeTab !== 'upload'
            })}>
            Upload
          </NLButton>
        </div>
      </div>

      <div className="w-full border-b border-gray-200 mb-5 flex flex-row items-center gap-4">
        {renderTab()}
      </div>
    </div>
  )
}
