import classNames from 'classnames'
import { FaTags } from 'react-icons/fa'
import { GiArchiveResearch } from 'react-icons/gi'

import { RiCloseCircleLine } from 'react-icons/ri'
import { ReactSVG } from 'react-svg'

function NLChip(props) {
  const item = props.item
  const className = props.className !== undefined ? props.className : ''
  const isPurgable = !!(props.onPurge !== undefined && props.onPurge)

  return (
    <div
      className={classNames(
        'flex gap-1 px-3 py-1 rounded-full items-center text-xs',
        className,
        {
          'bg-yellow-300': item.type === 'free' && !isPurgable,
          'bg-emerald-300': item.type === 'keyword' && !isPurgable,
          'bg-cyan-300': item.type === 'tag' && !isPurgable,
          'bg-cyan-200': item.type === 'suggested' && !isPurgable,
          'bg-gray-200':
            item.type !== 'keyword' ||
            item.type !== 'tag' ||
            item.type !== 'suggested' ||
            item.type !== 'free',
          'bg-purple-200': isPurgable,
          'cursor-pointer': props.onClick
        }
      )}
      onClick={event => {
        if (
          event.target.tagName.toLowerCase() === 'svg' ||
          event.target.tagName.toLowerCase() === 'path'
        ) {
          return
        }
        if (props.onClick) {
          props.onClick(item)
        }
      }}>
      {item.type === 'keyword' && (
        <ReactSVG
          src="/assets/search-suggestion-item-keyword.svg"
          className="shrink-0"
          beforeInjection={svg => {
            svg.setAttribute('style', 'width: 16px')
            svg.setAttribute('style', 'height: 16px')
          }}
        />
      )}
      {item.type === 'tag' && (
        <div className="shrink-0">
          <FaTags className="shrink-0" width="16px" height="16px" />
        </div>
      )}
      {item.type === 'free' && (
        <div className="shrink-0">
          <GiArchiveResearch width={16} height={160} />
        </div>
      )}
      <div className="flex whitespace-nowrap">{item.id ? item.id : item}</div>
      {isPurgable && (
        <div
          className=" hover:text-purple-500 shrink-0"
          onClick={() => {
            props.onPurge(item)
          }}>
          <RiCloseCircleLine />
        </div>
      )}
    </div>
  )
}

export default NLChip
