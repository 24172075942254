import classNames from 'classnames'
import React, { useState, useEffect } from 'react'

function UserNotification() {
  const [notification, setNotification] = useState(null)

  const handleNotification = event => {
    setNotification(event.detail)
    if (event.detail.duration > 0) {
      setTimeout(() => {
        setNotification(null)
      }, event.detail.duration)
    }
  }

  useEffect(() => {
    document.addEventListener('user-notification', handleNotification)
    return () => {
      document.removeEventListener('user-notification', handleNotification)
    }
  }, [])

  const bodyClassName = classNames(
    'absolute w-full text-white -ml-6 px-4 py-2 transition-all duration-300 ease-in-out',
    {
      'h-16': notification,
      'h-0': !notification,
      'bg-green-600': notification && notification.type === 'success',
      'bg-red-600': notification && notification.type === 'error',
      'bg-amber-500': notification && notification.type === 'warning',
      'bg-blue-600': notification && notification.type === 'info'
    }
  )

  const dismissButtonClassName = classNames(
    'rounded-md px-4 py-2 font-semibold cursor-pointer',
    {
      'bg-green-800 hover:bg-green-700':
        notification && notification.type === 'success',
      'bg-red-800 hover:bg-red-700':
        notification && notification.type === 'error',
      'bg-amber-700 hover:bg-amber-600':
        notification && notification.type === 'warning',
      'bg-blue-800 hover:bg-blue-700':
        notification && notification.type === 'info'
    }
  )
  return (
    <div
      className={bodyClassName}
      style={{
        zIndex: notification ? 20002 : 0
      }}>
      {notification && (
        <div className="flex flex-row space-x-2 items-center h-full text-sm">
          <div className="flex flex-col grow h-full justify-center">
            {notification.title.length > 0 && (
              <p className="font-semibold">{notification.title}</p>
            )}
            {notification.message.length > 0 && <p>{notification.message}</p>}
          </div>
          {notification.dismissible && (
            <div
              className={dismissButtonClassName}
              onClick={() => {
                setNotification(null)
              }}>
              {notification.dismissString}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default UserNotification
