import { useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import NLButton from '../common/NLButton'
import NLCheckBox from '../common/NLCheckBox'
import NLAlert from '../common/NLAlert'
import NLInput from '../common/NLInput'
import OnBoardBody from './OnBoardBody'
import { UserAuthTokenKey } from '../../services/helpers'
import { AuthContext } from './../../context/AuthContext'
import http from '../../services/http'
import Cookies from 'js-cookie'

function Register() {
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastName] = useState('')
  const [username, setUsername] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [pwd, setPwd] = useState('')
  const [pwdVerify, setPwdVerify] = useState('')
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [registerError, setRegisterError] = useState('')
  const auth = useContext(AuthContext)
  const navigate = useNavigate()

  const PasswordRequirements = () => {
    return (
      <pre>
        {`  Check if your password matches to
 the requirements:
  - Length is not less than 8.
  - Contains at least 1 digit.
  - Does not contain a comma or
    space.
  - Contains at least one capital
    letter.
  - Contains at least one 
  lowercase letter.`}
      </pre>
    )
  }

  const handleRegister = async () => {
    if (
      !firstname ||
      !lastname ||
      !email ||
      !pwd ||
      pwd !== pwdVerify ||
      !acceptTerms
    ) {
      return
    }
    setIsLoading(true)
    const data = {
      username,
      email,
      firstname,
      lastname,
      password: pwd
    }
    http
      .post('/auth/register', data)
      .then(response => {
        if (response.data.status === false) {
          setIsLoading(false)

          if (response.data.response.password !== undefined) {
            setRegisterError(PasswordRequirements)
          } else if (response.data.response.username !== undefined) {
            setRegisterError('username must be at least 3 characters')
          } else if (response.data.response.email !== undefined) {
            setRegisterError('check your email address')
          }
        } else {
          Cookies.set(UserAuthTokenKey, response.data.data.user.api_key)
          auth.signIn(response.data.response.user, () => {
            setIsLoading(true)
            navigate('/onboarding', { replace: true, state: 'firstLogin' })
          })
        }
      })
      .catch(error => {
        setIsLoading(false)
        setRegisterError(error.response.data.response)
      })
  }

  const checkPasswordMatch = () => {
    if (!pwd) return
    pwd !== pwdVerify
      ? setRegisterError('password does not match')
      : setRegisterError('')
  }

  return (
    <OnBoardBody  title="Create Account">
      <div className="flex flex-col w-full md:w-2/3 space-y-2 md:space-y-4">
        <NLInput
          disabled={isLoading}
          assistiveText="First Name"
          type="text"
          className="w-full"
          onChange={e => {
            setFirstname(e.target.value)
          }}
        />
        <NLInput
          disabled={isLoading}
          assistiveText="Last Name"
          type="text"
          className="w-full"
          onChange={e => {
            setLastName(e.target.value)
          }}
        />
        <NLInput
          disabled={isLoading}
          assistiveText="Username"
          type="text"
          className="w-full"
          onChange={e => {
            setUsername(e.target.value)
          }}
        />
        <NLInput
          disabled={isLoading}
          assistiveText="E-mail"
          type="email"
          className="w-full"
          onChange={e => {
            setEmail(e.target.value)
          }}
        />
        <NLInput
          disabled={isLoading}
          assistiveText="Password"
          type="password"
          className="w-full"
          onChange={e => {
            setPwd(e.target.value)
          }}
        />
        <NLInput
          disabled={isLoading}
          assistiveText="Repeat Password"
          type="password"
          className="w-full"
          onChange={e => {
            setPwdVerify(e.target.value)
          }}
          onBlur={checkPasswordMatch}
        />
        <NLCheckBox
          type="square"
          isChecked={acceptTerms}
          className="text-xs"
          onClick={() => {
            setAcceptTerms(!acceptTerms)
          }}>
          I agree to all the{' '}
          <span className="text-purple-lighter cursor-pointer hover:text-purple">
            Terms
          </span>{' '}
          and{' '}
          <span className="text-purple-lighter cursor-pointer hover:text-purple">
            Privacy Policy
          </span>
        </NLCheckBox>
        {registerError && (
          <NLAlert
            type="error"
            className="text-xs"
            style={{ whiteSpace: 'pre-wrap', minWidth: '20em' }}>
            {registerError}
          </NLAlert>
        )}
        <NLButton
          title="CREATE ACCOUNT"
          onClick={handleRegister}
          isDisabled={pwd !== pwdVerify || isLoading || !acceptTerms}
        />
      </div>
      <div className="text-sm mt-6">
        Already have an account?{' '}
        <Link to={'/login'} className="text-purple-lighter">
          Sign in
        </Link>
      </div>
    </OnBoardBody>
  )
}

export default Register
