import { createContext, useContext, useEffect, useState } from 'react'
import { AiFillSetting } from 'react-icons/ai'
import { GiRingedPlanet } from 'react-icons/gi'
import { ImDrawer } from 'react-icons/im'
import { IoCloudy, IoSearch } from 'react-icons/io5'
import { useLocation } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import { AuthContext } from '../context/AuthContext'
import { getPathPart } from '../services/helpers'

let list

function useUsername() {
  const auth = useContext(AuthContext)
  const username = auth.user.username
  return username
}

export function NavigationProvider({ children }) {
  const username = useUsername()

  list = [
    {
      id: 3,
      name: 'Search',
      route: '/',
      icon: <IoSearch />,
      visibleInSidebar: true
    },
    {
      id: 1,
      name: 'Cloud',
      route: '/cloud',
      icon: <IoCloudy />,
      visibleInSidebar: true
    },
    {
      id: 2,
      name: 'Repositories',
      route: '/repositories',
      icon: <ImDrawer />,
      visibleInSidebar: true
    },
    {
      id: 5,
      name: 'Spaces',
      route: `/${username}/spaces`,
      icon: <GiRingedPlanet />,
      visibleInSidebar: true
    },
    {
      id: 0,
      name: 'Settings',
      route: `/profile/${username}`,
      icon: <AiFillSetting />,
      visibleInSidebar: true
    },
    {
      id: 6,
      name: 'Chat',
      route: '/chat',
      icon: (
        <ReactSVG
          src="/assets/brainbot.svg"
          alt="brain bot"
          className="self-center w-6 aspect-[1.16]"
          color=""
        />
      ),
      visibleInSidebar: true
    }
  ]

  const [current, setCurrent] = useState({})
  const [sidebarOpened, setSidebarOpened] = useState(false)
  const location = useLocation()

  const handleSideBar = sidebarOpened => {
    setSidebarOpened(!sidebarOpened)
  }

  useEffect(() => {
    const i = list.find(
      // (i) => i.route.substring(1) === location.pathname.split("/")[1] //old logic
      i => i.route.substring(1) === getPathPart(location.pathname)
    )

    if (i) {
      setCurrent(i) // current route, for example: /profile
    }
  }, [location])

  const sidebarList = list.filter(i => i.visibleInSidebar)

  const value = { list, current, sidebarList, sidebarOpened, handleSideBar }
  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  )
}

export const NavigationContext = createContext({
  list,
  current: {}
})
