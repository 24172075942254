import React, { useState } from 'react'
import NLLoader from '../../../common/NLLoader'
import { ReactSVG } from 'react-svg'
import classNames from 'classnames'
import { RiCheckboxBlankCircleLine, RiCheckboxCircleFill } from 'react-icons/ri'

function RepositorySelectorItem(props) {
  const [isLoading, setIsLoading] = useState(false)

  const onToggleUse = () => {
    if (isLoading) return
    setIsLoading(true)
    props.auth.toggleRepository(props.item.name, () => {
      setIsLoading(false)
    })
  }

  const isInUse = props.auth.user.repositories.indexOf(props.item.name) > -1
  const className = classNames(
    'flex flex-row px-1 py-2 hover:bg-gray-100 rounded',
    {
      'cursor-pointer': !isLoading
    }
  )
  return (
    <div className={className} onClick={onToggleUse}>
      <ReactSVG
        src={props.repo.icon}
        beforeInjection={svg => {
          svg.setAttribute('style', 'width: 16px')
          svg.setAttribute('style', 'height: 16px')
        }}
        className="pt-0.5"
      />
      <div className="grow text-sm mr-1 ml-5">
        {props.item.display_name ? props.item.display_name : props.item.name}
      </div>
      <div>
        {isLoading ? (
          <NLLoader size="lg" />
        ) : (
          <>
            {isInUse ? (
              <RiCheckboxCircleFill className="text-lg text-green-600" />
            ) : (
              <RiCheckboxBlankCircleLine className="text-gray-800 text-lg" />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default RepositorySelectorItem
