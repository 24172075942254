import React, { useEffect, useState } from "react";
import { MdContentCopy } from "react-icons/md";
import { useAuthContext } from "../../context/AuthContext";
import clsx from "clsx";

function Application() {

  const [api_key, setApi_key] = useState(null);
  const { user } = useAuthContext();
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    setApi_key(user.api_key)
  }, [user.api_key])

  return (
    <>
      <div
        className="h-screen flex flex-col items-center pt-24  font-bold text-white 
       bg-gradient-to-t from-violet-500 to-violet-400">

          <div className="flex flex-col gap-8 ">
              <h1 className="text-center text-5xl font-['Fredoka'] font-bold" >Hey Human Twin!</h1>
              <p>Now you can search and discover the hidden treasures in your mind.</p>
              <p>Let`s start.</p>
            
              <div className="flex flex-col gap-2">
                  <p>1. Click on brain extension to register or login if you already have an account</p>
                  <p>2. Click on the sync history button so I can get to know you better</p>
                  <div className="flex gap-1">
                      <p> 3. Let’s Talk! This is my telegram </p>
                      <a className="underline font-medium text-cyan-300" href="https://web.telegram.org/k/#@heybrain_bot" target="_blank" rel="noreferrer" >@heybrain</a> 
                  </div> 
              </div>
              
              <p> If you are asked for secret key, here it is:</p>
              <div className="flex relative w-full mx-auto">          
                  <div className="flex justify-between p-3 items-center rounded bg-white w-full overflow-hidden " >
                      <p className="text-violet-500" >
                        {api_key || 'Please register or login to get api key.'}
                      </p>
                    { api_key && (
                      <div>
                        <div className="flex justify-end w-full ">
                        <MdContentCopy className='text-violet-500  text-2xl cursor-pointer' 
                                      onClick={()=> {
                                            navigator.clipboard.writeText(api_key);
                                            setCopied(true);
                                            const timer = setTimeout(() => {
                                              setCopied(false);
                                              clearTimeout(timer);
                                            }, 1000); }}/>
                        </div>
                  
                        <p className={clsx("absolute text-[#6FE8F9] -top-8 right-2  ", {
                                      "visible" : copied,
                                      "invisible" : !copied
                        })}>
                          copied to clipboard
                        </p>
                    </div>
                    )}
                  </div>
              </div>
        </div>
      </div>
    </>
  )
}

export default Application
