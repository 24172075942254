import React, { useState, useEffect, useContext } from 'react'
import '../common/style/Animate.css'
import NLCloud from '../common/NLCloud'
import NLLoader from '../common/NLLoader'
import http from '../../services/http'
import axios from 'axios'
import { AuthContext } from '../../context/AuthContext'
import { isLocalhost } from '../../services/helpers'

function Home() {
  const [isLoading, setIsLoading] = useState(true)
  const [list, setList] = useState([])
  const auth = useContext(AuthContext)

  useEffect(() => {
    document.title = 'Cloud | HeyBrain'
  }, [])

  useEffect(() => {
    const ct = axios.CancelToken.source()
    async function loadList() {
      if (list.length === 0) {
        setIsLoading(true)
        const mockup = isLocalhost() ? '?mockup=1' : ''
        const result = await http.post(
          '/magic/search' + mockup,
          {
            sorting: 'recency'
          },
          {
            cancelToken: ct.token
          }
        )
        setIsLoading(false)
        if (result && result.data && result.data.response) {
          let d = []
          let k = []
          if (
            result.data.response.documents &&
            result.data.response.documents.length > 0
          ) {
            d = result.data.response.documents.map(o => ({
              ...o,
              type: 'document'
            }))
          }
          if (
            result.data.response.keywords &&
            result.data.response.keywords.length > 0
          ) {
            k = result.data.response.keywords.map(o => ({
              ...o,
              type: 'keyword'
            }))
          }
          setList([...d, ...k])
        }
      }
    }
    loadList()
    return () => {
      ct.cancel()
      setIsLoading(false)
    }
  }, [list])

  useEffect(() => {
    setList([])
  }, [auth.user])

  if (isLoading) {
    return <NLLoader type="brain" />
  }

  return (
    <NLCloud list={list} totalElements={list.length < 50 ? list.length : 50} />
  )
}

export default Home
