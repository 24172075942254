import JoditEditor from 'jodit-react'
import { useEffect, useRef, useState } from 'react'
import { FaCheck, FaPenAlt } from 'react-icons/fa'
import { useAuthContext } from '../../context/AuthContext'
import { closeUserOverlay } from '../../services/helpers'
import http from '../../services/http'

const UserOverlay = props => {
  const { user } = useAuthContext()
  const { id, api_key, email, firstname, lastname, username } = props.user.user
  const [userMessages, setUserMessages] = useState([])
  const [content, setContent] = useState('')
  const [editing, setEditing] = useState(false)
  const nodeRef = useRef(null)
  const editor = useRef(null)

  const headers = {
    Accept: 'application/json',
    Authorization: user.api_key,
    'Content-Type': 'application/json',
    'user-agent': 'vscode-restclient'
  }

  async function loadUserMessages(id) {
    const res = await http.get(
      `/admin/telegram/auto-message?user_id=${id}`,
      headers,
    )

    setUserMessages(res.data.response)
  }

  useEffect(() => {
    loadUserMessages(id)
  }, [])

  const onSaveNote = async (id, username) => {
    if (!content) return

    const formatted = content
    .replace(/<p>/g, '').replace(/<\/p>/g, '\n')

    http.post(
      '/admin/telegram/auto-message',
      { message: formatted, user_id: id, username: username },
      headers
    )
    loadUserMessages(id)
    setContent('')
    setEditing(false)
  }

  function renderUserTable(arr) {
    const messages = arr.map((item, index) => {
      const { id, message, status } = item

      return (
        <tr
          key={index}
          className={
            'border-b transition duration-300 ease-in-out hover:bg-neutral-100 cursor-pointer'
          }>
          <td className="whitespace-nowrap px-6 py-2">{id}</td>
          <td className="whitespace-wrap px-6 py-2">{message}</td>
          <td className="whitespace-nowrap px-6 py-2">{status}</td>
        </tr>
      )
    })

    return (
      <table className="min-w-full text-left text-sm font-light border shadow-lg shadow-gray-200">
        <thead className="border-b font-medium dark:border-neutral-500">
          <tr>
            <th scope="col" className="px-6 py-2">
              id
            </th>
            <th scope="col" className="px-6 py-2">
              text
            </th>
            <th scope="col" className="px-6 py-2">
              status
            </th>
          </tr>
        </thead>
        <tbody>{messages}</tbody>
      </table>
    )
  }

  return (
    <>
      <div
        className="absolute flex p-3 justify-end bg-black/50 right-0 top-0 w-screen h-screen"
        style={{ zIndex: '10010' }}>
        <div
          ref={nodeRef}
          className="flex flex-col gap-2 bg-white p-2 m-auto rounded left-0 right-0 w-2/3 min-w-[100] h-3/4 shadow-sm shadow-gray-200 overflow-hidden">
          <div
            className="cursor-pointer hover:text-gray-500 mt-1 text-xs self-end"
            onClick={() => {
              closeUserOverlay()
            }}>
            close
          </div>
          <div className="flex w-full gap-3 h-2/5 overflow-y-auto">
            <div className="flex flex-col py-3 gap-5 w-2/5 px-5  border shadow-lg shadow-gray-200 text-sm break-all	">
              <p>username: {username}</p>
              <p>first name: {firstname}</p>
              <p>last name: {lastname}</p>
              <p>api key: {api_key}</p>
              <p>id: {id}</p>
              <p>e-mail: {email}</p>
            </div>
            {userMessages.length > 0 && (
              <div className="w-full  ">{renderUserTable(userMessages)}</div>
            )}
          </div>
          <div className="flex justify-end">
            {editing && (
              <button
                className="text-green-700 px-2 "
                onClick={() => {
                  onSaveNote(id, username)
                }}>
                <FaCheck />
              </button>
            )}
            {!editing && (
              <button
                className="text-purple-500 px-2 "
                onClick={() => {
                  setEditing(true)
                }}>
                <FaPenAlt />
              </button>
            )}
          </div>
          <div className="flex-grow h-2/5 overflow-y-auto border-t">
            <JoditEditor
              ref={editor}
              value={content}
              onBlur={newContent => setContent(newContent)}
              config={{
                language: 'en',
                readonly: !editing,
                showCharsCounter: false,
                showWordsCounter: false,
                showXPathInStatusbar: false,
                height: 'auto',
                buttons: ['bold', 'italic', 'underline', '|', 'undo', 'redo'],
                toolbarAdaptive: false
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default UserOverlay
