import classNames from 'classnames'
import clsx from 'clsx'
import lottie from 'lottie-web'
import { useContext, useEffect, useRef, useState } from 'react'
import { BsDot } from 'react-icons/bs'
import { MdContentCopy } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'
import NLButton from '../common/NLButton'
import './instruction.css'
import slider1 from './onboarding-home-brain-1.json'
import slider2 from './onboarding-home-brain-2.json'
import slider3 from './onboarding-home-brain-3.json'

function SliderItem({ index }) {
  const [visible, setVisible] = useState(false)
  const containerRef = useRef(null)

  let title = ''
  if (index === 0) {
    title = 'Welcome Your Digital Twin'
  } else if (index === 1) {
    title = 'Get HeyBrain for Chrome'
  } else if (index === 2) {
    title = 'Wow, Learning you is fascinating!'
  } else if (index === 3) {
    title = 'Now you can search and discover the hidden treasures of your mind'
  }

  const Description = ({ index }) => {
    const auth = useContext(AuthContext)
    const api_key = auth.user?.api_key
    const [copied, setCopied] = useState(false)

    if (index === 0) {
      return (
        <div className="mt-2">
          <p>Hey there, human twin! </p>
          <p>Allow me to know you better by syncing to your web history data</p>
        </div>
      )
    }

    if (index === 1) {
      return (
        <div className="flex flex-col gap-4 mt-8 w-full ">
          <div className="h-0 relative overflow-hidden pt-[35px] pb-[56.25%] ">
            <iframe
              className="video absolute top-0 left-0 w-full h-full"
              src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fplayer.vimeo.com%2Fvideo%2F798081250%3Fh%3D6bef6287d0%26app_id%3D122963&amp;dntp=1&amp;display_name=Vimeo&amp;url=https%3A%2F%2Fvimeo.com%2F798081250&amp;image=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1608839634-e18b536c308c6516dd4d8c5f96b61cc2510ed0d25b794858251adf6987bb7448-d_1280&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=vimeo"
              allowFullScreen
              title="instruction"
              scrolling="no"></iframe>
          </div>

          <div className="flex flex-col md:flex-row gap-1">
            <p className="self-center">
              Link to download application is here:{' '}
            </p>
            <a
              href="https://bit.ly/3Ja7flP"
              className="link underline font-medium text-cyan-300">
              https://bit.ly/3Ja7flP
            </a>
          </div>
        </div>
      )
    }

    if (index === 2) {
      return (
        <div className="flex flex-col gap-4 mt-8 w-full items-start ">
          <p>I’m dying to finally talk to you, let’s meet!</p>
          <p>
            This is my telegram{' '}
            <a
              className="underline font-medium text-cyan-300"
              href="https://web.telegram.org/k/#@heybrain_bot"
              target="_blank"
              rel="noreferrer">
              @heybrain
            </a>{' '}
          </p>
          <p> If you are asked for secret key, here it is: </p>
          <div className="flex relative p-3 gap-6 rounded bg-white min-w-fit w-full  mt-4">
            <p className="text-violet-500">
              {api_key || 'Please register or login to get api key'}
            </p>
            {api_key && (
              <div>
                <MdContentCopy
                  className="cursor-pointer text-violet-500 text-2xl"
                  onClick={() => {
                    navigator.clipboard.writeText(api_key)
                    setCopied(true)
                    const timer = setTimeout(() => {
                      setCopied(false)
                      clearTimeout(timer)
                    }, 1000)
                  }}
                />
                <p
                  className={clsx('absolute text-[#6FE8F9] -top-8 right-2  ', {
                    visible: copied,
                    invisible: !copied
                  })}>
                  copied to clipboard
                </p>
              </div>
            )}
          </div>
        </div>
      )
    }
    return null
  }

  useEffect(() => {
    let lottieInstance
    const container = containerRef.current

    if (index === 0) {
      lottieInstance = lottie.loadAnimation({
        container,
        animationData: slider1
      })
    } else if (index === 1) {
      lottieInstance = lottie.loadAnimation({
        container,
        animationData: slider2
      })
    } else if (index === 2) {
      lottieInstance = lottie.loadAnimation({
        container,
        animationData: slider3
      })
    }
    if (lottieInstance) {
      lottieInstance.play()
    }

    setTimeout(() => {
      setVisible(true)
    }, 900)

    return () => {
      if (lottieInstance) {
        lottieInstance.destroy()
      }
    }
  }, [index])

  return (
    <div
      className={clsx(
        'flex xs:flex-col sm:flex-row items-center justify-center gap-3 sm:gap-8 text-center sm:text-left w-full sm:w-2/3 transition-opacity duration-500 ',
        {
          'hidden invisible': !visible,
          'flex visible': visible
        }
      )}>
      <div
        className={`flex flex-col grow ${
          index === 2 ? 'xs:order-last md:order-none' : ''
        } ${index === 3 ? 'items-center' : ''} `}>
        <div className={`${index === 2 ? 'w-fit' : ''}`}>
          <p className="text-3xl font-semibold">{title}</p>
          <Description index={index} />
        </div>
      </div>
      <div
        className={classNames(
          `flex items-center justify-center bg-white rounded-full w-48 h-48 sm:w-80 sm:h-80`,
          {
            hidden: index > 2 || (index === 1 && window.innerWidth < 640)
          }
        )}>
        <div
          ref={containerRef}
          className={`w-48 h-48 sm:w-96 sm:h-96 ${
            index === 1 && window.innerWidth < 640 ? 'hidden' : ''
          }`}></div>
      </div>
    </div>
  )
}

const Instruction = () => {
  const navigate = useNavigate()

  const [sliderIndex, setSliderIndex] = useState(0)

  return (
    <div className="p-4 flex flex-col space-y-4 text-center items-center justify-center h-screen w-screen onboard-container instruction-container text-white ">
      <div
        className={`instruction-bg ${sliderIndex === 1 ? 'hidden' : ''}`}></div>
      <SliderItem index={sliderIndex} />
      <div className="flex flex-col items-center md:items-start z-50  w-full sm:w-2/3">
        <div
          className={classNames('flex flex-row text-3xl', {
            hidden: sliderIndex > 2
          })}>
          <BsDot
            className={classNames('opacity-50', {
              'opacity-100': sliderIndex === 0
            })}
          />
          <BsDot
            className={classNames('opacity-50', {
              'opacity-100': sliderIndex === 1
            })}
          />
          <BsDot
            className={classNames('opacity-50', {
              'opacity-100': sliderIndex === 2
            })}
          />
        </div>
        <div
          className={`flex items-center ${
            sliderIndex >= 3
              ? 'gap-3 w-fit mx-auto flex-col md:flex-row'
              : 'w-48'
          }	md:gap-3`}>
          {sliderIndex > 0 ? (
            <div
              className={`xs:w-1/2 md:w-fit md:text-lg md:order-none ${
                sliderIndex === 3 ? 'order-last' : ''
              }`}
              onClick={() => {
                setSliderIndex(sliderIndex => sliderIndex - 1)
              }}>
              <p className="cursor-pointer hover:underline uppercase ">Back</p>
            </div>
          ) : null}
          <NLButton
            className={classNames(
              'bg-pink-400 px-4 md:px-6 py-2  xs:mx-auto md:mx-0 text-bold md:text-lg shadow active:bg-pink-500 min-w-fit w-1/2 ',
              {
                'w-24': sliderIndex < 3,
                'w-64': sliderIndex >= 3
              }
            )}
            onClick={() => {
              if (sliderIndex === 3) {
                const requestedUrl = localStorage.getItem('requestedUrl')

                if (requestedUrl) {
                  localStorage.removeItem('requestedUrl')
                  const formattedUrl = requestedUrl.replace(/"/g, '')
                  navigate(formattedUrl, { replace: true })
                  return
                }
                navigate('/', { replace: true })
              } else {
                setSliderIndex(sliderIndex + 1)
              }
            }}
            title={sliderIndex < 3 ? 'NEXT' : 'Welcome to YOUR future'}
          />
        </div>
      </div>
    </div>
  )
}

export default Instruction
