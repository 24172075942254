import classNames from 'classnames'
import React from 'react'

function NLButton({
  title,
  className: customClasses = '',
  isDisabled = false,
  ...props
}) {
  const className = classNames(
    'px-5 py-2 rounded-full outline-none border-0 text-sm font-semibold',
    customClasses,
    {
      'bg-gray-200 text-slate-500 cursor-default': isDisabled,
      'bg-purple hover:shadow-md active:bg-purple-darker active:shadow-lg text-white':
        !isDisabled
    }
  )
  return (
    <button {...props} className={className} disabled={isDisabled}>
      {title}
      {props.children}
    </button>
  )
}

export default NLButton
