import classNames from 'classnames'

function NLAlert({ type = 'info', className: customClasses = '', ...props }) {
  const className = classNames(
    'p-2 rounded-md outline-none border',
    customClasses,
    {
      'bg-red-200 border-red-300 text-red-900': type === 'error',
      'bg-orange-100 border-orange-400 text-orange-900': type === 'warning',
      'bg-green-200 border-green-400 text-green-900': type === 'success',
      'bg-blue-200 border-blue-300 text-blue-900':
        type !== 'error' && type !== 'warning' && type !== 'success'
    }
  )

  return (
    <div {...props} className={className}>
      {props.children}
    </div>
  )
}

export default NLAlert
