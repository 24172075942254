import React, { useContext, useState, useRef, useEffect } from 'react'
import { RiDatabase2Fill } from 'react-icons/ri'
import { AuthContext } from '../../../context/AuthContext'
import { CSSTransition } from 'react-transition-group'
import './../../common/style/Animate.css'
import RepositorySelectorContent from './RepositorySelector/RepositorySelectorContent'
import http from '../../../services/http'
import axios from 'axios'
import { FaTags } from 'react-icons/fa'
import { IoDocumentTextOutline } from 'react-icons/io5'
import { ReactSVG } from 'react-svg'

function RepositorySelector() {
  const [isOpen, setIsOpen] = useState(false)
  const nodeRef = useRef(null) // This helps silencing strictMode error. https://github.com/reactjs/react-transition-group/issues/668
  const repoSelectorRef = useRef(null)

  const auth = useContext(AuthContext)
  const activeRepoCount = !auth.user.repositories
    ? 0
    : auth.user.repositories.length
  const [repoNeedsRefresh, setRepoNeedsRefresh] = useState(true)
  const [stats, setStats] = useState(null)

  useEffect(() => {
    function handleOutsideClick(event) {
      if (
        repoSelectorRef.current &&
        !repoSelectorRef.current.contains(event.target) &&
        isOpen
      ) {
        setIsOpen(false)
      }
    }
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [repoSelectorRef, isOpen])

  useEffect(() => {
    setStats(null)
    const ct = axios.CancelToken.source()
    async function loadStats() {
      const result = await http.get('/repository/statistics/v2', {
        cancelToken: ct.token
      })
      if (result.data.response) {
        let docs = 0
        let tags = 0
        let keywords = 0
        result.data.response.forEach(element => {
          docs += element.documents
          tags += element.tags
          keywords += element.keywords
        })
        setStats({
          docs,
          tags,
          keywords
        })
      }
    }
    loadStats()
    return () => {
      ct.cancel()
    }
  }, [auth.user])

  return (
    <div
      className="flex bg-purple w-10 h-10 rounded-l-xl rounded-br-xl shadow-md text-white items-center justify-center cursor-pointer hover:bg-purple-lighter relative select-none"
      style={{ zIndex: 51 }}
      onClick={() => {
        if (!isOpen) setIsOpen(true)
      }}
      ref={repoSelectorRef}>
      <CSSTransition
        in={isOpen}
        timeout={300}
        classNames="animFade"
        unmountOnExit
        nodeRef={nodeRef}>
        <div
          className="absolute bg-purple rounded-l-xl rounded-br-xl shadow-md text-white right-0 top-0 cursor-default"
          style={{ zIndex: 52, width: '320px', height: '480px' }}
          ref={nodeRef}>
          <div
            className="absolute text-xs flex flex-row"
            style={{
              width: '220px',
              marginLeft: '18px',
              marginTop: '9px',
              opacity: '0.75'
            }}>
            {stats ? (
              <React.Fragment>
                <div className="flex flex-row space-x-1 mr-4 items-center">
                  <IoDocumentTextOutline className="text-md" />
                  <p>{stats.docs}</p>
                </div>
                <div className="flex flex-row space-x-1 mr-4 items-center">
                  <FaTags className="text-md" />
                  <p>{stats.tags}</p>
                </div>
                <div className="flex flex-row space-x-1 mr-4 items-center">
                  <ReactSVG
                    src="/assets/search-suggestion-item-keyword.svg"
                    className="text-white"
                    beforeInjection={svg => {
                      svg.setAttribute('style', 'width: 16px')
                      svg.setAttribute('style', 'height: 16px')
                      svg.setAttribute('fill', 'white')
                    }}
                  />
                  <p>{stats.keywords}</p>
                </div>
              </React.Fragment>
            ) : (
              ''
            )}
          </div>
          <RepositorySelectorContent
            auth={auth}
            onClose={() => {
              setIsOpen(false)
            }}
            repoNeedsRefresh={repoNeedsRefresh}
            onRefreshDone={() => {
              setRepoNeedsRefresh(false)
            }}
          />
        </div>
      </CSSTransition>
      <RiDatabase2Fill
        className="text-xl -mt-0.5"
        style={{ zIndex: 55 }}
        onClick={() => {
          if (isOpen) setIsOpen(false)
        }}
      />
      <div
        className="absolute right-0 top-0 mt-1 mr-1 rounded-full w-4 h-4 bg-red-500 text-white text-center flex items-center justify-center cursor-pointer"
        style={{ fontSize: '9px', zIndex: 56 }}
        onClick={() => {
          if (isOpen) setIsOpen(false)
        }}>
        {activeRepoCount}
      </div>
    </div>
  )
}

export default RepositorySelector
