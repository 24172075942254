import axios from 'axios'
import Cookies from 'js-cookie'
import { UserAuthTokenKey } from './helpers'

const http = axios.create({
  baseURL: '//api.nlpgraph.com/stage/api',
  headers: {
    'Content-Type': 'application/json'
  }
})

http.interceptors.request.use(
  function (config) {
    if (config.url.indexOf('s3.amazonaws.com') === -1) {
      const token = Cookies.get(UserAuthTokenKey)
      config.headers['api-key'] = token
    }
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

export default http
