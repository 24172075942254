import clsx from 'clsx'
import { useState } from 'react'
import { MdCreate } from 'react-icons/md'
import NLButton from '../../common/NLButton'
import NLInput from '../../common/NLInput'

function Profile(props) {
  const [isEditable, setIsEditable] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const { email, firstname, lastname, username } = props.profile

  const handleEditClick = isEditable => {
    setIsEditable(!isEditable)
  }

  const handleHover = isHovered => {
    setIsHovered(!isHovered)
  }

  return (
    <div
      className="flex flex-col bg-white h-full w-full min-w-max px-3"
      onMouseEnter={() => handleHover(isHovered)}
      onMouseLeave={() => handleHover(isHovered)}>
      <div className="flex flex-col gap-6 rounded p-3 w-full">
        <div className="flex justify-end">
          <MdCreate
            onClick={() => handleEditClick(isEditable)}
            className={clsx('cursor-pointer text-2xl', {
              'text-gray-400': isEditable,
              'text-[#8962F4]': !isEditable,
              invisible: !isEditable && !isHovered,
              visible: isHovered || isEditable
            })}
          />
        </div>

        <div className="flex flex-row gap-3">
          <div className="flex flex-col gap-3  w-1/2 min-w-fit">
            <label
              htmlFor=""
              className="block uppercase tracking-wide text-gray-700 text-xs  font-bold ">
              first name
            </label>

            <NLInput
              type="text"
              className={clsx(`w-full`, {
                'border border-[#DADADA] rounded-md': isEditable,
                'border-none': !isEditable
              })}
              defaultValue={firstname}
              disabled={!isEditable}
            />
          </div>

          <div className="flex flex-col gap-3 w-1/2 min-w-fit">
            <label
              htmlFor=""
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold">
              last name
            </label>

            <NLInput
              type="text"
              defaultValue={lastname}
              disabled={!isEditable}
              className={clsx(`w-full`, {
                'border border-[#DADADA] rounded-md': isEditable,
                'border-none': !isEditable
              })}
            />
          </div>
        </div>

        <div className="flex flex-row gap-3">
          <div className="flex flex-col gap-3 w-1/2 min-w-fit">
            <label
              htmlFor="grid-text-1"
              className="uppercase text-gray-700 text-xs  font-bold">
              email address
            </label>

            <NLInput
              type="text"
              defaultValue={email}
              disabled={!isEditable}
              className={clsx(`w-full`, {
                'border border-[#DADADA] rounded-md': isEditable,
                'border-none': !isEditable
              })}
            />
          </div>

          <div className="flex flex-col gap-3  w-1/2 min-w-fit">
            <label
              htmlFor="grid-text-1"
              className="uppercase text-gray-700 text-xs   font-bold">
              user name
            </label>

            <NLInput
              type="text"
              defaultValue={username}
              disabled={!isEditable}
              className={clsx(`w-full`, {
                'border border-[#DADADA] rounded-md': isEditable,
                'border-none': !isEditable
              })}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-3 bg-white p-3 w-full min-w-fit">
        <label
          htmlFor="grid-text-1"
          className="uppercase text-gray-700 text-xs font-bold">
          about
        </label>

        <textarea
          disabled={!isEditable}
          className={clsx(
            `w-full h-24 mb-10 bg-white text-gray-700 border border-gray-400 rounded-md p-3 resize-y`,
            {
              'border border-[#DADADA]': isEditable,
              'border-none': !isEditable
            }
          )}
        />

        <NLButton
          onClick={() => {
            handleEditClick(isEditable)
          }}
          className={clsx('px-3 py-2 w-fit self-end uppercase', {
            visible: isEditable,
            invisible: !isEditable
          })}>
          save changes
        </NLButton>
      </div>
    </div>
  )
}

export default Profile
