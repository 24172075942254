const Planet = () => {
  return (
    <>
      <svg
        width="62"
        height="62"
        viewBox="0 0 62 62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle cx="31" cy="31" r="31" fill="#8962F4" />
        <path
          d="M43.3139 25.802C43.5238 26.2945 43.705 26.8001 43.8541 27.32C45.1384 27.8196 45.8884 28.398 45.8884 29.0181C45.8884 29.7383 44.8951 30.4025 43.203 30.9487C43.1768 24.2315 37.7213 18.7939 30.9994 18.7939C24.2751 18.7939 18.822 24.2315 18.7958 30.9498C17.1025 30.4025 16.1092 29.7371 16.1092 29.0181C16.1092 28.3992 16.8605 27.8185 18.1435 27.32C18.2938 26.8001 18.4762 26.2945 18.6849 25.8008C14.5972 27.0541 12 28.9179 12 30.9999C12 31.2706 12.0429 31.5365 12.1276 31.7977C13.2258 35.1962 21.257 37.8315 30.9994 37.8315C40.7394 37.8315 48.773 35.1962 49.8736 31.7977C49.9559 31.5341 50 31.2706 50 30.9999C49.9976 28.9179 47.4016 27.0553 43.3139 25.802ZM21.5945 38.7819C23.8339 41.4816 27.2169 43.2047 30.9994 43.2047C34.7819 43.2047 38.1637 41.4816 40.4019 38.7819C37.6307 39.3757 34.417 39.7132 30.9994 39.7132C27.5771 39.7132 24.3682 39.3757 21.5945 38.7819ZM31.6786 31.6784H35.75V30.3213H31.6786V26.2498H30.3214V30.3213H26.25V31.6784H30.3214V35.7498H31.6786V31.6784Z"
          fill="white"
        />
      </svg>
    </>
  )
}

export default Planet
